/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import $ from 'jquery';
export default Component.extend({
  componentPath: '',
  model: null,
  precision: false,
  orientation: 'vertical',
  enabled: true,
  isCompany: false,
  onOpen: null,
  onClose: null,
  handleWarnOnNavigation: null,

  click() {
    if (this.enabled) {
      let options = {};
      if (this.precision) {
        options.$element = $(this.element);
        options.orientation = this.orientation;
        options.step = this.step;
        options.isCompany = this.isCompany;
        options.handleWarnOnNavigation = this.handleWarnOnNavigation;
      }

      let controllerProps = {};
      let callbacks = {
        onOpen: this.onOpen,
        onClose: this.onClose,
      };

      this.action?.(this.componentPath, this.model, options, controllerProps, callbacks);
    }
  },
});
