/* import __COLOCATED_TEMPLATE__ from './install-verification-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import MessengerVerification, {
  VerificationKeys,
} from 'embercom/lib/messenger/messenger-verification';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import moment from 'moment-timezone';
import { AUDIENCE } from 'embercom/lib/messenger/installation-constants';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class InstallVerificationComponent extends Component {
  @service appService;
  @service intercomEventService;

  @tracked _verificationSuccessful = false;
  @tracked _errorMessage = null;
  @tracked sourceKey = null;

  messengerVerification = null;

  constructor() {
    super(...arguments);
    this.messengerVerification = MessengerVerification.create({ appId: this.app.id });
    this.sourceKey = this.args.sourceKey;
  }

  get app() {
    return this.appService.app;
  }

  get selectedLoggedOutAudience() {
    return this.args.selectedAudience === AUDIENCE.LOGGED_OUT;
  }

  get selectedLoggedInAudience() {
    return this.args.selectedAudience === AUDIENCE.LOGGED_IN;
  }

  get loggedOutInstallTimestamp() {
    return this.app.first_anonymous_request_at || this.app.verifiedLoggedOutInstallAt;
  }

  get loggedInInstallTimestamp() {
    return this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt;
  }

  get showLoggedOutText() {
    return this.selectedLoggedInAudience && !this.loggedOutInstallTimestamp;
  }

  get showLoggedInText() {
    return this.selectedLoggedOutAudience && !this.loggedInInstallTimestamp;
  }

  get codeInstallAudience() {
    return this.selectedLoggedOutAudience ? 'visitors' : 'users';
  }

  get verificationSuccessful() {
    return this._verificationSuccessful && this.sourceKey === this.args.sourceKey;
  }

  get errorMessage() {
    if (this.sourceKey === this.args.sourceKey) {
      return this._errorMessage;
    }

    return null;
  }

  @action
  sendAnalyticsEvent(analyticsMetadata) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.baseAnalyticsMetadata,
      ...analyticsMetadata,
    });
  }

  @action
  performInstallVerification() {
    this.verifyInstallation.perform();
    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'check_connection',
    });
  }

  @dropTask
  *verifyInstallation() {
    let verificationTask = this.messengerVerification.get('checkInstall');
    let result = yield verificationTask.perform(this.args.verificationKey, this.args.sourceKey);
    this.sourceKey = this.args.sourceKey;

    if (result.verified) {
      this._verificationSuccessful = true;
      this.app.set(
        this.args.verificationKey === VerificationKeys.LOGGED_IN
          ? 'verifiedLoggedInInstallAt'
          : 'verifiedLoggedOutInstallAt',
        moment.utc().format(),
      );

      this.sendAnalyticsEvent({ action: 'viewed', object: 'installation_verified' });
      if (this.args.completeAfterVerification) {
        this.args.completionCallback();
      }
    } else {
      this._errorMessage =
        'We havenʼt received any data from your installation. Please check the instructions and try again.';
      this.sendAnalyticsEvent({ action: 'viewed', object: 'installation_failed' });
    }
  }
}
