/* import __COLOCATED_TEMPLATE__ from './chrome.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isPresent } from '@ember/utils';
import { A } from '@ember/array';
import { computed, get } from '@ember/object';
import { notEmpty, readOnly, and, gt, lt, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import {
  sanitizeHtml as computedSanitizeHtml,
  slice,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import DeviceType from 'embercom/components/mixins/device-type';
import intermoji from '@intercom/intermoji';
import generateSummary from 'embercom/lib/messages/generate-summary';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default Component.extend(DeviceType, {
  classNames: ['flex-none', 'flex', 'flex-col', 'test__message__editor__web__chrome'],
  appService: service(),
  app: readOnly('appService.app'),

  variation: null,
  message: readOnly('variation.message'),
  deliveryPreviewEnabled: false,
  dataPreviewEnabled: false,

  deviceType: 'web',
  currentDevice: readOnly('deviceType'),
  messageStyleName: readOnly('variation.messageStyleName'),
  messageType: readOnly('message.type'),
  lastActiveString: 'Active',

  appName: readOnly('app.name'),
  appBaseColor: readOnly('app.base_color'),

  from: readOnly('variation.from'),
  adminName: readOnly('from.firstNameOrNameOrEmail'),
  adminAvatarUrl: readOnly('from.avatar.image_urls.square_50'),
  adminFirstName: computed('adminName', function () {
    return (this.adminName || 'Unknown').split(' ')[0];
  }),
  isFromTeam: readOnly('from.isTeam'),
  displayName: ternaryToProperty('isFromTeam', 'adminName', 'adminFirstName'),
  adminFirstInitial: slice('adminFirstName', 0, 1),
  team: computed('isFromTeam', 'from.member_ids', 'app.admins.[]', function () {
    let team = A();
    if (this.isFromTeam) {
      let messageAdmins = this.get('from.member_ids').slice(0, 3);
      team = messageAdmins.map((messageAdmin) => {
        let admin = this.get('app.admins').findBy('id', messageAdmin.toString());
        if (!admin) {
          return null;
        }

        let url = admin.get('avatar.image_urls.square_50') || '';

        return {
          url: url.includes('default-avatars') ? '' : url,
          first_name: admin.get('first_name'),
        };
      });
    }
    return team.compact();
  }),

  isChat: readOnly('variation.isChat'),
  isNote: readOnly('variation.isNote'),
  isPointer: readOnly('variation.isPointer'),
  isPost: readOnly('variation.isPost'),
  isVideo: readOnly('variation.isVideo'),
  isMaximized: false,
  badgePreview: readOnly('variation.isBadge'),
  snippetPreview: readOnly('variation.isSummary'),
  fullPreview: readOnly('variation.isFull'),
  previewBadge: and('deliveryPreviewEnabled', 'badgePreview'),
  previewSnippet: and('deliveryPreviewEnabled', 'snippetPreview'),
  previewFull: and('deliveryPreviewEnabled', 'fullPreview'),
  isReactionsReply: readOnly('variation.isReactionsReply'),
  isTextReply: readOnly('variation.isTextReply'),
  isAttributeCollectorReply: readOnly('variation.isAttributeCollectorReply'),
  reactions: readOnly('variation.reactions'),
  reactionsWithDisplay: computed('reactions.@each.unicodeEmoticon', function () {
    return this.reactions.map((reaction) => ({
      index: get(reaction, 'index'),
      unicodeEmoticon: get(reaction, 'unicodeEmoticon'),
      display: sanitizeHtml(
        intermoji.wrapUnicodeEmojiInTitledSpans(
          32,
          get(reaction, 'unicodeEmoticon'),
          'reaction-picker-popover__image o__large',
        ),
      ),
    }));
  }),
  nextReactionIndex: readOnly('reactions.length'),
  canHaveLessReactions: gt('reactions.length', 2),
  canHaveAdditionalReactions: lt('reactions.length', 5),
  enableReactionPicker: not('deliveryPreviewEnabled'),
  shouldShowReactionAdder: and('enableReactionPicker', 'canHaveAdditionalReactions'),

  hasVideoBlock: computed('variation.blocks.[]', function () {
    let blockCount = this.get('variation.blocks.length');
    if (blockCount === 1) {
      let videoFileBlock = this.get('variation.blocks.firstObject');
      return videoFileBlock.get('type') === 'videoFile' && isPresent(videoFileBlock.get('url'));
    }
    return false;
  }),
  hasVideoContent: and('isVideo', 'hasVideoBlock'),
  videoUrl: ternaryToProperty('hasVideoContent', 'variation.blocks.firstObject.url', ''),

  combinedText: computed('variation.blocks.[]', 'previewContext', function () {
    let text = generateSummary(this.get('variation.blocks'));
    return renderPreview(text, this.previewContext);
  }),
  combinedTextHtml: computedSanitizeHtml('combinedText'),
  replyOptions: readOnly('variation.replyOptions'),
  hasReplyOptions: notEmpty('replyOptions'),
});
