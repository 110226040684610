/* import __COLOCATED_TEMPLATE__ from './predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';

export default class PredicatesEditor extends Component {
  @service appService;
  @service attributeService;

  get app() {
    return this.appService.app;
  }

  get activeEvents() {
    return this.attributeService.attributes.filter((attr) => attr.isUserEvent);
  }

  get rulesetTrigger() {
    return this.args.rulesetTrigger;
  }

  get rulesetTriggerPredicates() {
    return this.rulesetTrigger.eventPredicateGroup.predicates;
  }

  get isDisabled() {
    return !!this.args.isDisabled;
  }

  get eventMetadata() {
    let selectedEvent = this.activeEvents.find(
      (event) => event.event_id === this.rulesetTrigger.eventId,
    );

    if (!selectedEvent) {
      return [];
    }

    return selectedEvent.metadata.map((metadata) => {
      return {
        name: metadata.humanFriendlyName,
        identifier: metadata.identifier,
        type: metadata.type,
      };
    });
  }

  get attributeGroupList() {
    return [
      {
        attributes: this.eventMetadata,
      },
    ];
  }

  @action onUpdate(predicates) {
    this.rulesetTrigger.eventPredicateGroup.predicates = copy(predicates);
  }
}
