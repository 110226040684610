/* import __COLOCATED_TEMPLATE__ from './subscribe-to-subscription-type.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';
import ajax from 'embercom/lib/ajax';
import {
  stateOptions,
  consentType,
} from 'embercom/models/outbound-subscriptions/subscription-type';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';

export default class SubscribeToSubscriptionType extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service intl;

  @tracked subscriptionTypes = [];
  @tracked subscriptionTypeId;
  @tracked consentReceived;

  constructor() {
    super(...arguments);
    this.loadSubscriptionTypes.perform();
  }

  get app() {
    return this.appService.app;
  }

  get count() {
    return this.args.selected.length || this.args.totalCount;
  }

  get filteredPredicates() {
    return this.args.selected.length > 0 ? [] : this.args.predicates;
  }

  get items() {
    return this.subscriptionTypes
      .filter((subscriptionType) => subscriptionType.state === stateOptions.live)
      .map((subscriptionType) => {
        return {
          text: subscriptionType.translationForTeammate.name,
          value: subscriptionType.id,
          icon: objectIcons[subscriptionType.permittedContentTypes[0]],
        };
      });
  }

  get selectedSubscription() {
    return this.subscriptionTypes.filter(
      (subscriptionType) => subscriptionType.id === this.subscriptionTypeId,
    )[0];
  }

  get isOptInSubscription() {
    if (!this.subscriptionTypeId) {
      return false;
    }

    return this.selectedSubscription.consentType === consentType.optIn;
  }

  get isDisabled() {
    return !this.subscriptionTypeId || (this.isOptInSubscription && !this.consentReceived);
  }

  @task({ drop: true }) *bulkOptIn() {
    let selectedEntities = this.args.selected;

    try {
      yield ajax({
        url: `/ember/users/bulk_opt_in.json`,
        type: 'POST',
        data: JSON.stringify({
          included_ids: selectedEntities.map((entity) => entity.get('id')),
          excluded_ids: [],
          app_id: this.app.id,
          predicates: this.filteredPredicates,
          subscription_type_id: this.subscriptionTypeId,
          count: this.count,
        }),
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.modal.opt-in-to-subscription-type.notify-confirmation', {
          personTypeName: pluralize(this.count, this.args.personTypeName),
        }),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.modal.opt-in-to-subscription-type.notify-error', {
          this: pluralize(this.count, 'this', { withoutCount: true }),
          personTypeName: pluralize(this.count, this.args.personTypeName, { withoutCount: true }),
        }),
      );
    } finally {
      this.args.closeModal();
    }
  }

  @task *loadSubscriptionTypes() {
    this.subscriptionTypes = yield this.store.findAll('outbound-subscriptions/subscription-type');
  }
}
