/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { computed } from '@ember/object';
import { assert } from '@ember/debug';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
let KeyboardSelectableDropdown = Mixin.create({
  // use {{bind-attr class="<item in each>.isHighlighted:keyboard_selected"}} for item selected class
  setup: on('init', function () {
    // we are using this.selectableItems is on purposes - it tests that it's defined without evaluating it's value
    assert('Must define selectableItems on component using this mixin.', this.selectableItems);
    this.set(
      'actions',
      Object.assign(
        {},
        {
          onEnter() {
            if (this.isOpen) {
              let currentHighlightedItem = this.highlightedItem;
              if (currentHighlightedItem !== undefined) {
                this.resetHighlightedItem();
                this.send(
                  'selectItem',
                  currentHighlightedItem,
                  $('.keyboard_selected', this.element),
                );
                this.send('close');
              }
            }
          },
          onDown() {
            if (this.isOpen) {
              this.highlightItem('next');
            } else {
              $(this.element).trigger('click');
            }
          },
          onUp() {
            if (this.isOpen) {
              this.highlightItem('previous');
            }
          },
          onEsc() {
            this.send('close');
          },
          selectItem() {
            throw new Error(
              'Assertion Failed: Must define selectItem action on component using this mixin.',
            );
          },
        },
        this.actions,
      ),
    );
  }),
  resetHighlightedItem() {
    let highlightedItem = this.highlightedItem;
    if (highlightedItem) {
      if (highlightedItem.set) {
        highlightedItem.set('isHighlighted', false);
      } else {
        highlightedItem.isHighlighted = false;
      }
    }
  },
  highlightedItem: computed('selectableItems.[]', 'currentHighlightedIndex', function () {
    return this.selectableItems.objectAt(this.currentHighlightedIndex);
  }),
  currentHighlightedIndex: computed('selectableItems.@each.isHighlighted', function () {
    return this.selectableItems.indexOf(
      this.selectableItems.find(function (item) {
        return item.isHighlighted === true;
      }),
    );
  }),
  highlightItem(direction) {
    let newSelectedIndex;
    let numberOfItems = this.get('selectableItems.length');
    let currentSelectedIndex = this.currentHighlightedIndex;
    if (currentSelectedIndex === -1) {
      this.set('selectableItems.firstObject.isHighlighted', true);
      return;
    }
    if (
      (direction === 'next' && currentSelectedIndex + 1 === numberOfItems) ||
      (direction === 'previous' && currentSelectedIndex === 0)
    ) {
      return;
    }
    newSelectedIndex = direction === 'next' ? currentSelectedIndex + 1 : currentSelectedIndex - 1;
    let currentSelectedItem = this.selectableItems.objectAt(currentSelectedIndex);
    let newSelectedItem = this.selectableItems.objectAt(newSelectedIndex);
    if (currentSelectedItem.set && newSelectedItem.set) {
      currentSelectedItem.set('isHighlighted', false);
      newSelectedItem.set('isHighlighted', true);
    } else {
      currentSelectedItem.isHighlighted = false;
      newSelectedItem.isHighlighted = true;
    }
    schedule('afterRender', this, this.scrollToHighlightedItem);
  },
  scrollToHighlightedItem() {
    let selectedItem = $(this.element).find('.keyboard_selected');
    if (selectedItem.length === 1) {
      let scrollingContainer = selectedItem.scrollParent();
      let scrollPosition = scrollingContainer.scrollTop();
      let selectedItemPosition = selectedItem.position();
      let itemHeight = selectedItem.outerHeight();
      if (selectedItemPosition.top - itemHeight > scrollingContainer.height()) {
        scrollingContainer.scrollTop(scrollPosition + itemHeight);
        return;
      }
      if (selectedItemPosition.top < itemHeight * 2) {
        scrollingContainer.scrollTop(scrollPosition - itemHeight);
      }
    }
  },
  keyDown(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    switch (keyCode) {
      case 40:
        this.send('onDown');
        if (!this.keydownCanPropagate(40)) {
          e.preventDefault();
        }
        break;
      case 38:
        this.send('onUp');
        if (!this.keydownCanPropagate(38)) {
          e.preventDefault();
        }
        break;
      case 13:
        this.send('onEnter');
        if (!this.keydownCanPropagate(13)) {
          e.preventDefault();
        }
        break;
      case 27:
        this.send('onEsc');
        break;
      default:
        this._super(e);
    }
  },
  keydownCanPropagate(keycode) {
    return this.keycodesThatCanPropagate?.includes(keycode);
  },
});

export default KeyboardSelectableDropdown;
