/* import __COLOCATED_TEMPLATE__ from './tasks-slot.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TasksSlotComponent extends Component {
  @service MessengerSettingsService;
  @service intl;

  get isMobilePreview() {
    return this.MessengerSettingsService.isMobilePreview;
  }

  get showBanner() {
    return !this.args.spaces.hasTasks || (this.args.slotExists && this.isMobilePreview);
  }

  get bannerText() {
    let bannerText = '';
    if (!this.args.spaces.hasTasks) {
      bannerText = this.intl.t('messenger.add-apps.tasks.banner-text');
    } else if (this.isMobilePreview) {
      bannerText = this.intl.t('messenger.add-apps.mobile-preview-text');
    }
    return bannerText;
  }

  @action
  enableTasks() {
    this.args.spaces.enableTasks();
  }
}
