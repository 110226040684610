/* import __COLOCATED_TEMPLATE__ from './brand.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

type Args = {
  activeBrand: $TSFixMe;
  lookAndFeelSettings: $TSFixMe;
  configuration: $TSFixMe;
  isMobilePlatform: boolean;
};

export default class BrandColors extends Component<Args> {
  @service declare MessengerSettingsService: MessengerSettingsService;
  colorOptions = DEFAULT_MESSENGER_COLORS;

  @action
  setBackgroundSolidColorDefault() {
    if (!this.args.activeBrand.backgroundSolidColor) {
      this.args.activeBrand.backgroundSolidColor = this.args.activeBrand.messengerActionColor;
      this.debouncedTrackColorChange(
        'background_solid_color',
        this.args.activeBrand.backgroundSolidColor,
      );
    }
  }

  @action
  setPrimaryColor(color: string) {
    this.args.activeBrand.messengerActionColor = color;
    if (this.args.activeBrand.isDefault) {
      this.args.lookAndFeelSettings.primaryColor = color;
    }
    this.debouncedTrackColorChange('action_color', color);
  }

  @action
  setBackgroundSolidColor(color: string) {
    this.args.activeBrand.backgroundSolidColor = color;
    this.debouncedTrackColorChange('background_solid_color', color);
  }

  debouncedTrackColorChange(object: string, color: string) {
    debounce(
      this.MessengerSettingsService,
      this.MessengerSettingsService.trackAnalyticsEvent,
      {
        action: 'changed',
        object,
        color,
      },
      500,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Content::Brand': typeof BrandColors;
    'messenger-settings/conversational/content/brand': typeof BrandColors;
  }
}
