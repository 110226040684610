/* import __COLOCATED_TEMPLATE__ from './help-space.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

type Args = {
  spaces: $TSFixMe;
  isMobilePlatform: boolean;
};

export default class HelpSpace extends Component<Args> {
  @service declare MessengerSettingsService: MessengerSettingsService;
  @service declare helpCenterService: $TSFixMe;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;

  get isHelpCenterEnabled() {
    return !!this.helpCenterService.site?.turnedOn;
  }

  @action
  toggleVisitorHelpSpace() {
    this.args.spaces.visitorHelpSpace.enabled = !this.args.spaces.visitorHelpSpace.enabled;
    this.messengerSettingsTargetUserProvider.setVisitorTarget();
  }

  @action
  toggleUserHelpSpace() {
    this.args.spaces.userHelpSpace.enabled = !this.args.spaces.userHelpSpace.enabled;
    this.messengerSettingsTargetUserProvider.setUserTarget();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Content::HelpSpace': typeof HelpSpace;
    'messenger-settings/conversational/content/help-space': typeof HelpSpace;
  }
}
