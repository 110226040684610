/* import __COLOCATED_TEMPLATE__ from './home-screen.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class HomeScreen extends Component {
  @tracked headerScrollOffset;

  @action
  handleScroll(e) {
    this.headerScrollOffset = e.scrollTop;
  }
}
