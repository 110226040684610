/* import __COLOCATED_TEMPLATE__ from './icon-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class IconSelectorComponent extends Component {
  @tracked selectedIcon = this.args.selectedIcon || null;

  get icons() {
    return [
      'app',
      'activity',
      'bounced',
      'button-pill',
      'click-element',
      'company',
      'contact-book',
      'coupon',
      'creditcard',
      'email-filled',
      'event',
      'large-right-arrow',
      'insights',
      'locked',
      'phone',
      'person',
      'wrench',
      'slipping-away',
      'stars',
    ];
  }

  @action
  async selectIcon(icon) {
    this.selectedIcon = icon;
    if (this.args.onIconSelect) {
      this.args.onIconSelect(icon);
    }
  }
}
