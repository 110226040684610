/* import __COLOCATED_TEMPLATE__ from './slot-predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';

export default class SlotPredicatesEditor extends Component {
  @action
  onUpdate(predicates) {
    this.args.predicateGroup.predicates = copy(predicates);
  }
}
