/* import __COLOCATED_TEMPLATE__ from './cta-footer.hbs'; */
/* RESPONSIBLE TEAM: team-web */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  appstoreService: service(),
  actions: {
    trackClick(object) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object,
        location: 'cta_footer',
      });
    },
    redirectToSignIn() {
      this.send('trackClick', 'log_in_link');
      this.appstoreService.redirectToWorkspaceAppstore();
    },
  },
});
