/* import __COLOCATED_TEMPLATE__ from './greeting-prosemirror-composer.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import {
  BaseConfig,
  BlocksDocument,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { type TextDirection } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { type Block } from 'embercom/models/common/blocks/block';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import type IntercomAttribute from 'embercom/models/attribute';
import { next } from '@ember/runloop';

class ComposerConfig extends BaseConfig {
  autoFocus = true;
  textDirection: TextDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor({ resolver, placeholder }: { resolver: AttributeInfoResolver; placeholder: string }) {
    super();
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.placeholder = placeholder;
  }
}

interface Args {
  locale: $TSFixMe;
  isConversational: boolean;
  isMobilePlatform: boolean;
}

export default class GreetingProsemirrorComposer extends Component<Args> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked blocksDoc: BlocksDocument;
  @tracked composerApi?: ComposerPublicAPI;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.serializedBlocks);
  }

  get app() {
    return this.appService.app;
  }

  get isMobilePlatform() {
    return this.args.isMobilePlatform;
  }

  get allowedAttributes() {
    return this.attributeService.attributes.filter(
      (attr: IntercomAttribute) => attr.isUser && !attr.isUserEvent,
    );
  }

  get composerConfig() {
    let config = new ComposerConfig({
      resolver: new AttributeInfoResolver({
        attributes: this.attributeService.greetingComposerAttributes,
        attributeAllowlist: this.attributeService.attributesToGroupList(this.allowedAttributes),
        includeAppAttributes: false,
      }),
      placeholder: this.placeholder,
    });

    return config;
  }

  get placeholder() {
    return this.intl.t('messenger.greeting.greeting-placeholder');
  }

  get locale() {
    return this.args.locale;
  }

  get isConversationalMessenger() {
    return this.args.isConversational;
  }

  get serializedBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.isConversationalMessenger
          ? this.locale.conversationalGreeting
          : (this.isMobilePlatform ? this.locale.mobileGreeting : this.locale.greeting) || '',
      },
    ];
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );

    let blockText = blockFragments.firstObject.text || '';
    let textContent = htmlToTextContent(blockText);
    blockText = textContent.trim();
    if (this.isConversationalMessenger) {
      this.locale.conversationalGreeting = blockText;
    } else if (this.isMobilePlatform) {
      this.locale.mobileGreeting = blockText;
    } else {
      this.locale.greeting = blockText;
    }
  }

  @action onReady(composerApi: ComposerPublicAPI) {
    this.composerApi = composerApi;

    next(this, () => {
      this.composerApi?.composer.commands.focus();
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Language::GreetingProsemirrorComposer': typeof GreetingProsemirrorComposer;
    'messenger-settings/language/greeting-prosemirror-composer': typeof GreetingProsemirrorComposer;
  }
}
