/* import __COLOCATED_TEMPLATE__ from './image-cropper-modal.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { task } from 'ember-concurrency-decorators';

export default class ImageCropperModal extends Component {
  @service imageCropperService;

  constructor() {
    super(...arguments);
    this.imageCropperService.maybeLoadCropperAssets.perform();
  }

  attachImageCropper = modifier((element, _, { aspectRatio }) => {
    this.imageCropperService.initCropper(element, {
      viewMode: 1,
      aspectRatio: aspectRatio || 1,
      checkOrientation: false,
      checkCrossOrigin: false,
      guides: false,
      center: false,
      autoCropArea: 1,
    });
    return () => {
      this.imageCropperService.destroyCropper();
    };
  });

  get uncroppedImageUrl() {
    return URL.createObjectURL(this.args.originalFile);
  }

  @action
  onCrop() {
    this.handleCrop.perform();
  }

  @task({ drop: true })
  *handleCrop() {
    let croppedFile = yield this.imageCropperService.toFile(this.args.originalFile.name, {
      type: this.args.originalFile.type,
    });
    this.args.onCrop(croppedFile);
  }
}
