/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Main extends Component {
  @service messengerSettingsTargetUserProvider;
  @service intl;
  @tracked showDisableSpaceModal = false;
  @tracked activeSpace;
  @service MessengerSettingsService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get model() {
    return this.args.model;
  }

  get spaces() {
    return this.model.spaces.selectedSpaces;
  }

  get homeSettings() {
    return this.model.home;
  }

  get learnMoreLink() {
    if (this.model.form.selectedForm === 'default') {
      return 'https://www.intercom.com/help/en/collections/2094771-getting-started';
    }
    return 'https://www.intercom.com/help/en/articles/6785769-use-the-compact-messenger';
  }

  get reorderableSpaces() {
    let filteredSpaces = this.spaces.filter((space) => space.isReorderable);
    if (this.args.isMobilePlatform) {
      filteredSpaces = filteredSpaces.filter((space) => space.isAvailableForMobile);
    }
    return filteredSpaces;
  }

  get hub() {
    return this.spaces.find((space) => space.isHub);
  }

  get paywalledSpaces() {
    let filteredSpaces = this.spaces.filter((space) => space.isPaywalled);
    if (this.args.isMobilePlatform) {
      filteredSpaces = filteredSpaces.filter((space) => space.isAvailableForMobile);
    }
    return filteredSpaces;
  }

  get compactEnabled() {
    return this.model.form.selectedForm === 'compact';
  }

  @action
  selectForm(selection) {
    // ensure home is active for users and visitor when compact is selected
    if (selection === 'compact') {
      if (this.messengerSettingsTargetUserProvider.isUserType) {
        this.turnOnHomeSpace();
        this.messengerSettingsTargetUserProvider.setVisitorTarget();
        this.turnOnHomeSpace();
        this.messengerSettingsTargetUserProvider.setUserTarget();
      } else {
        this.turnOnHomeSpace();
        this.messengerSettingsTargetUserProvider.setUserTarget();
        this.turnOnHomeSpace();
        this.messengerSettingsTargetUserProvider.setVisitorTarget();
      }
    }

    this.model.form.selectedForm = selection;
  }

  turnOnHomeSpace() {
    if (!this.hub.enabled) {
      this.onSpaceToggle(this.hub);
    }
  }

  @action
  onSpacesReordered(reorderedSpaces, _movedSpace) {
    this.model.spaces.selectedSpaces = this._reconstructAllSpaces(reorderedSpaces);
  }

  @action
  onSpaceToggle(space) {
    if ((space.isNews || space.isHelp || space.isTasks || space.isTickets) && space.enabled) {
      this.activeSpace = space;
      if (
        !this.compactEnabled &&
        ((this.hub && this.hub.enabled) || space.isTasks || space.isTickets)
      ) {
        if (space.isHelp && this.hasHelpCenterSlot() && !this.app.canSearchBrowseWithoutHelpSpace) {
          this.showDisableSpaceModal = true;
        } else if (space.isNews && this.hasNewsSlot()) {
          this.showDisableSpaceModal = true;
        } else if (space.isTasks && this.hasTasksSlot()) {
          this.showDisableSpaceModal = true;
        } else if (space.isTickets) {
          this.showDisableSpaceModal = true;
        }
      }
      if (!this.showDisableSpaceModal) {
        this.removeActiveSpace();
      }
    } else {
      space.enabled = !space.enabled;
      if (space.isHub && !space.enabled) {
        this.homeSettings.disableRequireSearchBrowse();
      }
      if (space.isHelp && space.enabled) {
        this.homeSettings.slotGroup.addHelpCenterSlot();
      }

      if (space.isNews && space.enabled) {
        this.homeSettings.slotGroup.addNewsSlot();
      }

      if (space.isTasks && space.enabled) {
        this.homeSettings.slotGroup.addTasksSlot();
      }

      if (space.isHub && space.enabled && !this.homeSettings.slotGroup.hasNewConversationCardSlot) {
        this.homeSettings.slotGroup.addNewConversationCard();
      }
      this.trackSpaceToggle(space.enabled, space.name);
      if (space.isHub && space.enabled) {
        this.model.spaces.selectedSpaces = this._reconstructAllSpaces(this.reorderableSpaces);
      }
    }
  }

  trackSpaceToggle(enabled, spaceName) {
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'space',
      name: spaceName,
      toggled_to: enabled,
    });
  }

  hasHelpCenterSlot() {
    return this.homeSettings.slotGroup.hasSlot('help_center');
  }

  hasNewsSlot() {
    return this.homeSettings.slotGroup.hasSlot('news_items_card');
  }

  hasTasksSlot() {
    return this.homeSettings.slotGroup.hasSlot('checklists_card');
  }

  @action
  removeActiveSpace() {
    if (this.activeSpace.isNews) {
      this.homeSettings.slotGroup.removeNewsSlot();
    } else if (this.activeSpace.isHelp && !this.app.canSearchBrowseWithoutHelpSpace) {
      this.homeSettings.slotGroup.removeHelpCenterSlot();
      this.homeSettings.disableRequireSearchBrowse();
    } else if (this.activeSpace.isTasks) {
      this.homeSettings.slotGroup.removeTasksSlot();
    }
    this.activeSpace.enabled = false;
    this.showDisableSpaceModal = false;
    this.trackSpaceToggle(false, this.activeSpace.name);
  }

  _reconstructAllSpaces(reorderedSpaces) {
    return [this.hub, ...reorderedSpaces, ...this.paywalledSpaces];
  }
}
