/* import __COLOCATED_TEMPLATE__ from './team-avatar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, gt } from '@ember/object/computed';
import { A } from '@ember/array';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { notImplemented, ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  appColor: notImplemented(),
  team: notImplemented(),
  attributeBindings: ['data-test-team-avatars'],
  'data-test-team-avatars': true,
  avatarList: computed('team.[]', function () {
    let list = A();
    this.team.forEach((admin, index) => {
      if (admin.first_name) {
        let avatarInfo = {};
        avatarInfo.class = `${this.avatarClass} ${this.multiAvatarClass(index)}`;
        avatarInfo.url = admin.url;
        avatarInfo.urlPresent = admin.url && admin.url.length > 0;
        avatarInfo.initial = admin.first_name.charAt(0).toUpperCase();

        list.push(avatarInfo);
      }
    });

    return list;
  }),
  totalCount: readOnly('team.length'),
  totalCountString: computed('totalCount', function () {
    switch (this.totalCount) {
      case 2:
        return 'two';
      case 3:
        return 'three';
      default:
        return '';
    }
  }),
  moreThanOneAdmin: gt('totalCount', 1),
  avatarClass: ternary(
    'moreThanOneAdmin',
    'intercom-team-avatar-small',
    'intercom-team-avatar-large',
  ),
  multiAvatarClass(index) {
    if (!this.moreThanOneAdmin) {
      return '';
    }

    switch (index) {
      case 0:
        return `intercom-first-of-${this.totalCountString}-admins`;
      case 1:
        return `intercom-second-of-${this.totalCountString}-admins`;
      case 2:
        return `intercom-third-of-${this.totalCountString}-admins`;
    }
  },
});
