/* import __COLOCATED_TEMPLATE__ from './predicate.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import PageTargetPredicateComparisonOptions from 'predicates/models/data/page-target-predicate-comparison-options';

export default Component.extend({
  tagName: 'span',

  comparisonText: computed('predicate.comparison', function () {
    let comparator = PageTargetPredicateComparisonOptions.string.comparison.find(
      (comparator) => comparator.value === this.predicate.comparison,
    );
    return comparator.label;
  }),

  init() {
    this._super(...arguments);
    this.set('justAdded', this.predicate.comparison === 'eq' && this.predicate.value === '');
  },

  onClose: action(function () {
    this.set('justAdded', false);
  }),
});
