/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';

export default Mixin.create({
  notificationsService: service(),
  handleServerError(response, message) {
    let xhr = response.jqXHR;
    if (xhr && xhr.responseJSON && xhr.responseJSON.message) {
      let errorMessage = xhr.responseJSON.message;
      let displayedErrorMessage = `${message}: ${errorMessage}.`;

      if (
        xhr.statusText === 'Conflict' &&
        xhr.responseJSON.meta &&
        xhr.responseJSON.meta.conflict_resource
      ) {
        console.error(
          'Articles existing resource conflict',
          xhr.responseJSON.meta.conflict_resource,
        );
      }

      this.notificationsService.notifyResponseError(
        response,
        { default: displayedErrorMessage },
        { duration: ENV.APP.educateErrorNotificationTimeout },
      );
    } else {
      this.notificationsService.notifyResponseError(response, { default: `${message}.` });
    }
  },
});
