/* import __COLOCATED_TEMPLATE__ from './identity-verification-key-rotator.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::IdentityVerificationKey': typeof IdentityVerificationKeyRotator;
    'messenger-settings/security/identity-verification-key': typeof IdentityVerificationKeyRotator;
  }
}

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

export type Props = {
  realm: 'ios' | 'android' | 'web';
  onKeyChanged: ((key: string) => void) | null;
};
export default class IdentityVerificationKeyRotator extends Component<Props> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @tracked showOldSecret = false;
  @tracked showNewSecret = false;
  @tracked isKeyRemovalInProgress = false;
  @tracked isKeyCreationInProgress = false;
  @tracked secrets = this.getSecrets();

  get app() {
    return this.appService.app;
  }

  @action
  async doMaybeRemoveKey() {
    this.isKeyRemovalInProgress = true;
    let options = {
      title: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.oldest-secret',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.sure-to-delete-secret',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.delete',
      ),
    };
    let isConfirmed = await this.intercomConfirmService.confirm(options);
    if (isConfirmed) {
      await taskFor(this.reallyRemoveKey).perform();
    }
    this.isKeyRemovalInProgress = false;
  }

  @action
  async doMaybeCreateKey() {
    this.isKeyCreationInProgress = true;
    let options = {
      title: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.secret-title',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.only-two-secrets',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.confirm-secret',
      ),
    };

    let isConfirmed = await this.intercomConfirmService.confirm(options);
    if (isConfirmed) {
      await taskFor(this.reallyCreateKey).perform();
    }
    this.isKeyCreationInProgress = false;
  }

  private masked<StringOrNull extends string | null>(s: StringOrNull): StringOrNull {
    if (s === null || s === undefined) {
      return null as StringOrNull;
    }
    return s.replace(/.{4}(?=.)/g, '•') as StringOrNull;
  }

  @task({ enqueue: true })
  private *reallyRemoveKey(): TaskGenerator<boolean> {
    try {
      yield ajax({
        url: `/ember/apps/${this.app.id}/delete_oldest_secret`,
        type: 'POST',
      });
      yield this.app.fetchAPISecret.perform();
      this.secrets = this.getSecrets();
      return true;
    } catch (e) {
      return false;
    }
  }

  @task({ enqueue: true })
  private *reallyCreateKey(): TaskGenerator<boolean> {
    try {
      yield ajax({
        url: `/ember/apps/${this.app.id}/create_new_secret`,
        type: 'POST',
      });
      yield this.app.fetchAPISecret.perform();
      this.secrets = this.getSecrets();
      if (this.args.onKeyChanged) {
        this.args.onKeyChanged(this.secrets!.new);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  private getSecrets(): {
    old: string | null;
    new: string;
    oldMasked: string | null;
    newMasked: string;
  } | null {
    switch (this.args.realm) {
      case 'ios':
        return null;
      case 'android':
        return null;
      case 'web':
        if (this.app.api_secrets.length === 0) {
          return null;
        }
        return {
          old: this.app.api_secrets[1],
          new: this.app.api_secrets[0],
          oldMasked: this.masked(this.app.api_secrets[1]),
          newMasked: this.masked(this.app.api_secrets[0]),
        };
      default:
        throw new Error("Realm Must be 'ios', 'android' or 'web'");
    }
  }
}
