/* import __COLOCATED_TEMPLATE__ from './inbound-volume.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type IntlService from 'ember-intl/services/intl';
import { type Task, type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

interface Signature {
  Args: {
    visibilitySettings: any;
    homeSettings: any;
    operatorArticleAutoReaction: any;
  };
}

export default class InboundVolume extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare messengerSettingsService: MessengerSettingsService;

  helpCenterSite: unknown = null;
  fetchHelpCenter: Task<void, any> = taskFor(this.fetchHelpCenterTask);

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.fetchHelpCenter.perform();
  }

  app() {
    return this.appService.app;
  }

  @task({ drop: true })
  *fetchHelpCenterTask(): TaskGenerator<void> {
    let helpCenterSites = yield this.store.findAll('help-center-site');
    this.helpCenterSite = helpCenterSites.firstObject;
  }

  @action
  toggleProperty(property: any) {
    this.args.visibilitySettings[property] = !this.args.visibilitySettings[property];
    if (
      property === 'inboundConversationsEnabled' &&
      !this.args.visibilitySettings.inboundConversationsEnabled
    ) {
      this.args.homeSettings.disableRequireSearchBrowse();
    }
    this.trackToggleProperty(property);
  }

  trackToggleProperty(property: any) {
    let enabled = this.args.visibilitySettings[property];
    let snakeCasedProperty = property.replace(
      /[A-Z]/g,
      (letter: string) => `_${letter.toLowerCase()}`,
    );

    this.messengerSettingsService.trackAnalyticsEvent({
      action: 'toggled',
      object: snakeCasedProperty,
      toggled_to: enabled,
      messenger_type: 'conversational',
    });
  }

  @action
  toggleOperatorArticleAutoReaction(property: any) {
    this.args.operatorArticleAutoReaction[property] =
      !this.args.operatorArticleAutoReaction[property];
    this.trackToggleProperty(property);
  }

  @action
  changeTargetUserType(value: string) {
    this.messengerSettingsService.trackAnalyticsEvent({
      action: 'clicked',
      object: `messenger_settings_preview_visibility_${value}`,
      context: 'from_panel',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::General::InboundVolume': typeof InboundVolume;
    'messenger-settings/conversational/general/inbound-volume': typeof InboundVolume;
  }
}
