/* import __COLOCATED_TEMPLATE__ from './archive-user.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { inject as service } from '@ember/service';
import BaseContentComponent from 'embercom/components/settings/content-components/base';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

export default BaseContentComponent.extend({
  notificationsService: service(),
  router: service(),
  intl: service(),
  archiveUser: task(function* (user) {
    try {
      yield ajax({
        url: '/ember/users/delete.json',
        type: 'POST',
        data: JSON.stringify({
          included_ids: [user.get('id')],
          app_id: this.get('app.id'),
          count: 1,
        }),
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.modal.user-deletion.archive-user.archived-1-person'),
      );
      this.closeModal();
      let routeParam = user.get('role') === 'contact_role' ? 'all-leads' : 'all-users';
      this.router.transitionTo('apps.app.users.segments.segment', routeParam);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.modal.user-deletion.archive-user.not-archived'),
      );
      this.closeModal();
    }
  }).drop(),

  actions: {
    navigateToDelete(user) {
      this.closeModal();
      let queryParams = {
        searchValue: user.get('user_id') || user.get('email'),
        tab: 'delete-data',
      };
      this.router.transitionTo('apps.app.settings.data.people', { queryParams });
    },
  },
});
