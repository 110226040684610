/* import __COLOCATED_TEMPLATE__ from './set-expectations.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnlyComponent from '@ember/component/template-only';

type Args = {
  lookAndFeelSettings: $TSFixMe;
};

const SetExpectations = templateOnlyComponent<Args>();
export default SetExpectations;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Greeting::SetExpectations': typeof SetExpectations;
    'messenger-settings/conversational/content/set-expectations': typeof SetExpectations;
  }
}
