/* import __COLOCATED_TEMPLATE__ from './create-lead.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { computed, action } from '@ember/object';

export default Component.extend({
  notificationsService: service(),
  router: service(),
  store: service(),
  intercomEventService: service(),
  leadName: '',
  leadEmail: '',
  role: 'contact_role',

  createNewLead: task(function* () {
    let lead = this.store.createRecord('user', {
      name: this.leadName,
      email: this.leadEmail,
      role: this.role,
    });

    try {
      yield lead.save().then(() => {
        this.router.transitionTo('apps.app.users.user', lead.id);
      });
      this.intercomEventService.trackAnalyticsEvent({
        object: 'new_lead',
        place: 'user_list',
        action: 'created',
        context: {
          email: !!this.leadEmail,
          name: !!this.leadName,
        },
      });
      this.cleanAndHideCreateLeadModal();
    } catch (e) {
      this.notificationsService.notifyError(
        `An error occurred while creating the lead: ${e.jqXHR.responseJSON.errors}`,
      );
      this.cleanAndHideCreateLeadModal();
    }
  }),

  disableCreateButton: computed('leadName', 'leadEmail', function () {
    let emailValueEmpty = !this.leadEmail;
    let emailIsInvalid = !document.querySelector('[data-create-email]').validity.valid;
    return emailValueEmpty || emailIsInvalid;
  }),

  disableCreateTooltip: 'Please make sure to fill the form correctly',

  cleanAndHideCreateLeadModal() {
    this.set('leadEmail', '');
    this.set('leadName', '');
    this.set('showLeadCreateModal', false);
  },

  cancelCreateLead: action(function () {
    this.cleanAndHideCreateLeadModal();
  }),
});
