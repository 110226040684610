/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { A } from '@ember/array';
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import storage from 'embercom/vendor/intercom/storage';

export default Mixin.create({
  favouriteEmojiStorageKey: 'userEmoji',
  maximumFavourites: 10,

  favouriteEmoji: computed('favouriteEmojiStorageKey', function () {
    return A(storage.get(this.favouriteEmojiStorageKey) || []);
  }),

  recordEmojiSelection(emojiIdentifier) {
    if (this.defaultEmoji.includes(emojiIdentifier)) {
      return;
    }

    let favouriteEmoji = this.favouriteEmoji;
    if (favouriteEmoji.length > this.maximumFavourites) {
      favouriteEmoji.popObject();
    }
    if (favouriteEmoji.includes(emojiIdentifier)) {
      favouriteEmoji.removeObject(emojiIdentifier);
    }
    favouriteEmoji.unshiftObject(emojiIdentifier);
    storage.set(this.favouriteEmojiStorageKey, favouriteEmoji);
  },
});
