/* import __COLOCATED_TEMPLATE__ from './switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { matchBehaviors } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  ruleset: $TSFixMe;
}

export default class Switcher extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get matchBehaviors() {
    return matchBehaviors;
  }

  get triggerExists() {
    return (
      this.ruleset.hasRulesetTriggers ||
      this.ruleset.hasRecurringSchedule ||
      this.ruleset.hasMatchingTimetable
    );
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get dynamicMatchBehavior() {
    if (this.ruleset.isMatchBehaviorMulti) {
      return this.matchBehaviors.multi;
    }

    return this.matchBehaviors.single;
  }

  get dynamicOptionHintText() {
    let text;
    if (this.ruleset.app.canUseScheduleBasedResending) {
      return text;
    }

    if (this.ruleset.matchBehavior === this.dynamicMatchBehavior) {
      text = this.intl.t(
        'matching-system.audience-selector.audience-rule-editor.match-behavior-switcher.now-and-in-the-future',
      );
    }

    return text;
  }

  get fixedOptionHintText() {
    let text;
    if (this.ruleset.app.canUseScheduleBasedResending) {
      return text;
    }

    if (this.ruleset.isMatchBehaviorStatic) {
      text = this.intl.t(
        'matching-system.audience-selector.audience-rule-editor.match-behavior-switcher.now',
      );
    }

    return text;
  }

  get fixedOptionDisabledTooltipText() {
    let text = this.intl.t(
      'matching-system.audience-selector.audience-rule-editor.fixed-audience.unavailable-with-when',
    );

    if (
      (this.ruleset.hasRecurringSchedule && this.ruleset.recurringSchedule.calendarBased) ||
      this.ruleset.hasMatchingTimetable
    ) {
      text = this.intl.t(
        'matching-system.audience-selector.audience-rule-editor.fixed-audience.unavailable-with-schedule',
      );
    }

    if (this.ruleset.hasMatchingTimetable) {
      text = this.intl.t(
        'matching-system.audience-selector.audience-rule-editor.fixed-audience.unavailable-with-timetable',
      );
    }

    return text;
  }

  @action enableDynamicMatchBehavior() {
    this.ruleset.matchBehavior = this.dynamicMatchBehavior;
  }

  @action enableFixedMatchBehavior() {
    this.ruleset.matchBehavior = this.matchBehaviors.static;
    this.ruleset.matchSpacingPeriod = -1;
    this.ruleset.maxMatchCount = -1;
    this.ruleset.matchingTimetable = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::AudienceSelector::MatchBehavior::Switcher': typeof Switcher;
    'matching-system/audience-selector/match-behavior/switcher': typeof Switcher;
  }
}
