/* import __COLOCATED_TEMPLATE__ from './match-records-and-checkpoints.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type MatchCheck } from 'embercom/objects/matching-system/match-check';

interface Args {
  matchCheck: MatchCheck;
}

export default class MatchRecordsComponent extends Component<Args> {
  @tracked openSectionId = 0;

  @action openSection(id: number) {
    this.openSectionId = id;
  }

  get showMatchRecordsInfoBanner() {
    return this.args.matchCheck.matchRecords.length > 0 || !this.args.matchCheck.isSeriesRuleset;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::MatchChecks::MatchRecordsAndCheckpoints': typeof MatchRecordsComponent;
  }
}
