/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import emoji from 'embercom/lib/emoji';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class Item extends Component {
  get usFlag() {
    return sanitizeHtml(emoji.emojify('&#x1F1FA;&#x1F1F8;'));
  }

  get placeholder() {
    let attr = this.args.attribute;
    if (['integer', 'float'].includes(attr.get('type'))) {
      return 'Type a number…';
    } else {
      return '';
    }
  }
}
