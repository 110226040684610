/* import __COLOCATED_TEMPLATE__ from './live-preview.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnlyComponent from '@ember/component/template-only';

const LivePreview = templateOnlyComponent();
export default LivePreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::LivePreview': typeof LivePreview;
  }
}
