/* import __COLOCATED_TEMPLATE__ from './matching-result-predicates.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';
import { type MatchingResult } from 'embercom/objects/matching-system/match-check';

interface Args {
  matchingResults: Array<MatchingResult>;
  join: 'and' | 'or';
}

export default class MatchingResultPredicates extends Component<Args> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::MatchChecks::MatchingResultPredicates': typeof MatchingResultPredicates;
  }
}
