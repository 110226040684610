/* import __COLOCATED_TEMPLATE__ from './editor-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import { ref } from 'ember-ref-bucket';
import { hasMobileSdkInstalled, hasOldMobileSdk } from 'embercom/lib/mobile/sdk-version-check';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';

export default class EditorSidebar extends Component {
  @tracked hasToggledSetCustomURI = false;
  @service appService;
  @service contentEditorService;
  @service notificationsService;
  @service intl;
  @ref('inputElement') inputElement;
  @tracked isUploading = false;

  allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  maxFileUploadSize = 10 * 1024 * 1024;
  preferredDevicesToHide = [preferredDevices.web];

  get app() {
    return this.appService.app;
  }

  get hasCustomURI() {
    return (
      this.hasToggledSetCustomURI ||
      isPresent(this.args.currentPushContent.get('uris.ios')) ||
      isPresent(this.args.currentPushContent.get('uris.android'))
    );
  }

  get fileName() {
    let url = this.args.currentPushContent?.contentImageUrl;
    let filename = url.substring(url.lastIndexOf('/') + 1);
    return filename;
  }

  get hasOldMobileSdk() {
    return hasOldMobileSdk(this.app, '12.3.0');
  }

  get hasMobileSdkInstalled() {
    return hasMobileSdkInstalled(this.app);
  }

  async uploadImage(file) {
    try {
      this.isUploading = true;
      let uploader = new EmbercomFileUploader(file, {
        policyUrl: `/apps/${this.appService.app.id}/uploads`,
      });
      await uploader.upload();
      this.handleUploadSuccess(uploader.policy);
    } catch (error) {
      this.handleUploadError();
    }
  }

  handleUploadSuccess(policy) {
    this.isUploading = false;
    let url = policy.public_url;
    this.args.currentPushContent.contentImageUrl = url;
  }

  handleUploadError(error) {
    this.isUploading = false;
    this.clearFileInput();
    this.notificationsService.notifyResponseError(error, {
      default: "Couldn't upload this file. Try again in a minute.",
    });
  }

  clearFileInput() {
    this.inputElement.value = '';
  }

  pickFile() {
    if (typeof this.args.pickFile === 'function') {
      // this is here for testing 🤮
      return this.args.pickFile.call(this);
    }
    this.clearFileInput();
    this.inputElement.click();
  }

  @action toggleHasCustomURI(value) {
    this.hasToggledSetCustomURI = value;
    this.args.currentPushContent.set('uris.ios', null);
    this.args.currentPushContent.set('uris.android', null);
  }

  @action showFilePicker() {
    if (this.args.disabled) {
      return;
    }
    this.pickFile();
  }

  @action
  change(e) {
    if (e.target?.files) {
      let file = e.target.files[0];
      if (file.size < this.maxFileUploadSize) {
        this.uploadImage(file);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('outbound.mobile-push.rich-push.image-file-too-large'),
        );
      }
    }
  }

  @action
  removeImage() {
    this.args.currentPushContent.contentImageUrl = null;
  }
}
