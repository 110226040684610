/* import __COLOCATED_TEMPLATE__ from './page-target.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { filterBy, not, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { findBy, ternary } from '@intercom/pulse/lib/computed-properties';
import Predicate from 'predicates/models/predicate';
import PredicateGroup from 'predicates/models/predicate-group';

const PAGE_TARGETING_PREDICATE = {
  type: 'string',
  attribute: 'client_attributes.last_visited_url',
  comparison: 'eq',
  value: '',
};

export default Component.extend({
  store: service(),
  pageTargetPredicates: filterBy('clientPredicateGroup.predicates', 'isPageTarget'),
  hasPageTargetPredicates: notEmpty('pageTargetPredicates'),
  hasNoPageTargetPredicates: not('hasPageTargetPredicates'),
  clientPredicatesOrPredicate: findBy('clientPredicateGroup.predicates', 'isOrPredicate'),
  isClientPredicatesOrMode: notEmpty('clientPredicatesOrPredicate'),
  clientPredicatesPageTargetJoinType: ternary('isClientPredicatesOrMode', 'or', 'and'),

  init() {
    this._super(...arguments);
    this.initPredicates();
  },

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.havePredicatesChanged) {
      this.initPredicates();
    }
  },

  initPredicates() {
    let serializedPredicates = this.predicates.serialize();
    this.setClientPredicateGroup(serializedPredicates);
    this.set('lastPredicates', serializedPredicates);
  },

  havePredicatesChanged: computed('lastPredicates.[]', 'predicates.[]', function () {
    return !_.isEqual(this.lastPredicates, this.predicates.serialize());
  }),

  setClientPredicateGroup(serializedPredicates) {
    this.set(
      'clientPredicateGroup',
      this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(this.store, serializedPredicates),
      }),
    );
  },

  _addEmptyPageTargetingPredicate() {
    this.clientPredicateGroup.predicates.pushObject(
      Predicate.createPredicateWithGenericType(this.store, PAGE_TARGETING_PREDICATE),
    );
    this.onUpdateClientPredicates(this.clientPredicateGroup.predicates);
  },

  _pageTargetPredicateAtIndex(index, predicateGroup) {
    let pageTargetPredicates = predicateGroup.predicates.filter(
      (predicate) => predicate.isPageTarget,
    );
    return pageTargetPredicates.objectAt(index);
  },

  _removePageTargetingPredicate(index) {
    let predicateToRemove = this._pageTargetPredicateAtIndex(index, this.clientPredicateGroup);
    this.clientPredicateGroup.predicates.removeObject(predicateToRemove);
    this.onUpdateClientPredicates(this.clientPredicateGroup.predicates);
  },

  _updatePredicate(index, field, newValue) {
    let predicate = this._pageTargetPredicateAtIndex(index, this.clientPredicateGroup);
    if (predicate.get(field) !== newValue) {
      predicate.set(field, newValue);
      this.onUpdateClientPredicates(this.clientPredicateGroup.predicates);
    }
  },

  updatePageTargetPredicate: action(function (index, input) {
    this._updatePredicate(index, 'comparison', input.comparison);
    this._updatePredicate(index, 'value', input.value);
  }),

  removePageTargetPredicate: action(function (index) {
    this._removePageTargetingPredicate(index);
  }),

  actions: {
    addPageTargetPredicate(e) {
      this._addEmptyPageTargetingPredicate();
      e.stopPropagation();
    },
  },
});
