/* import __COLOCATED_TEMPLATE__ from './snooze-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { SNOOZED_UNTIL_MAP } from 'embercom/lib/inbox/constants';

export default class SnoozeConversation extends Component {
  get snoozeOptions() {
    return SNOOZED_UNTIL_MAP;
  }

  get actionData() {
    return this.args.action.actionData;
  }

  get snoozeString() {
    if (this.actionData.snoozed_until) {
      if (this.actionData.snoozed_until === 'hours') {
        return `For ${this.actionData.selected_hours} hours`;
      }

      return this.snoozeOptions.find((snooze) => snooze.key === this.actionData.snoozed_until)
        .value;
    }
  }
}
