/* import __COLOCATED_TEMPLATE__ from './modal-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import { bind } from '@ember/runloop';
import Component from '@ember/component';
export default Component.extend({
  didInsertElement() {
    this._super(...arguments);
    this.listenForBackgroundClick();
  },

  //We only want to close the modal when both the mousedown and mouseup events
  //This prevents us from closing the modal when a click and drag occurs from inside to outside the modal.
  //A standard click event does not expose where the click started.
  listenForBackgroundClick() {
    $('.modal__overlay', this.element).on(
      'mousedown',
      bind(this, (e) => {
        let target = $(e.target);
        if (target.hasClass('modal__overlay') || target.hasClass('modal__overlay__inner')) {
          this.closeOnMouseUp();
        }
      }),
    );
  },

  closeOnMouseUp() {
    $('.modal__overlay', this.element).on(
      'mouseup',
      bind(this, (e) => {
        let target = $(e.target);
        if (target.hasClass('modal__overlay') || target.hasClass('modal__overlay__inner')) {
          this.closeModal?.();
        }
        $('.modal__overlay').off('mouseup');
      }),
    );
  },

  willDestroyElement() {
    $('.modal__overlay', this.element).off('mousedown');
    $('.modal__overlay', this.element).off('mouseup');
    this._super(...arguments);
  },
});
