/* import __COLOCATED_TEMPLATE__ from './import-modal.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ImportModal extends Component {
  @service modalService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  @action
  closeModal() {
    this.modalService.closeModal();
  }
}
