/* import __COLOCATED_TEMPLATE__ from './brand-setting-picker.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import type Brand from 'embercom/models/brand';
import type BrandSettings from 'embercom/models/brand-settings';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

type Args = {
  brands: Brand[];
  brandSettings: BrandSettings[];
  activeBrandSetting: BrandSettings;
  setBrandSetting: (brandSetting: BrandSettings) => void;
  isMobilePlatform: boolean;
};

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class BrandSettingPicker extends Component<Signature> {
  @service store: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service appService: $TSFixMe;
  @service declare router: RouterService;
  @service intl!: IntlService;
  @tracked showModal = false;
  @tracked modalMode: 'create' | 'edit' = 'create';
  @tracked showAllDomains = false;

  DEFAULT_BRAND_ID = -1;

  previousActiveBrandSetting = this.args.activeBrandSetting;
  defaultBrandSetting: BrandSettings = this.args.brandSettings.findBy('isDefault') as BrandSettings;
  defaultMobileBrandSetting: BrandSettings = this.args.brandSettings.findBy(
    'defaultMobileBrand',
  ) as BrandSettings;

  get app() {
    return this.appService.app;
  }

  get hasCustomBrandSettings() {
    return this.args.brandSettings.length > 1;
  }

  get isCreating() {
    return !!this.args.activeBrandSetting.isNew;
  }

  get brandSettingsForDropdown() {
    let isPickingForMobile = this.args.isMobilePlatform;
    let component = 'messenger-settings/look-and-feel/multibrand/brand-picker-item';
    let customBrandSettings = this.args.brandSettings.reject(
      (brandSetting) => brandSetting.isDefault || brandSetting.defaultMobileBrand,
    );
    let platformBrands = isPickingForMobile
      ? customBrandSettings.filter((brandSetting) => brandSetting.isMobile)
      : customBrandSettings.filter((brandSetting) => !brandSetting.isMobile);

    let defaultBrandSettingListItem = {
      text: isPickingForMobile
        ? this.defaultMobileBrandSetting.brandName
        : this.defaultBrandSetting.brandName,
      value: isPickingForMobile ? this.defaultMobileBrandSetting : this.defaultBrandSetting,
      component,
    };
    let customBrandSettingListItems = platformBrands
      .map((brandSetting) => {
        return {
          text: brandSetting.brandName,
          value: brandSetting,
          component,
        };
      })
      .sortBy('value.brandName');

    let dropdownList = [defaultBrandSettingListItem, ...customBrandSettingListItems];
    return [{ items: dropdownList }];
  }

  get saveButtonTooltip() {
    if (!this.args.activeBrandSetting.isValid) {
      if (this.args.activeBrandSetting.brandNameError) {
        return this.args.isMobilePlatform
          ? this.intl.t(
              'messenger.look-and-feel-settings.brand-setting-picker.form.errors.style-name-error-mobile',
            )
          : this.intl.t(
              'messenger.look-and-feel-settings.brand-setting-picker.form.errors.style-name-error-web',
            );
      } else if (this.args.activeBrandSetting.mobileBundleIdError) {
        return this.args.activeBrandSetting.mobileBundleIdError;
      }

      return this.intl.t(
        'messenger.look-and-feel-settings.brand-setting-picker.form.errors.domain-error-web',
      );
    }
  }

  get isActiveBrandSettingDefault() {
    return (
      this.args.activeBrandSetting.isDefault || this.args.activeBrandSetting.defaultMobileBrand
    );
  }

  get canAddDomains() {
    return !this.args.isMobilePlatform && !this.args.activeBrandSetting.isDefault;
  }

  get mobilePlatformOptions() {
    return [
      {
        text: 'iOS',
        value: 'ios',
      },
      {
        text: 'Android',
        value: 'android',
      },
    ];
  }

  get brandOptions() {
    let defaultBrand = {
      text: this.app.name,
      value: this.DEFAULT_BRAND_ID,
    };
    return [
      defaultBrand,
      ...this.args.brands.map((brand) => {
        return {
          text: brand.name,
          value: parseInt(brand.id, 10),
        };
      }),
    ];
  }

  get selectedBrandValue() {
    return parseInt(this.args.activeBrandSetting.brandId || this.DEFAULT_BRAND_ID, 10);
  }

  @action
  addNewBrandSetting() {
    this.modalMode = 'create';
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked_add_new',
      object: 'messenger_brand',
      place: 'messenger_settings',
      section: 'messenger',
    });
    this.previousActiveBrandSetting = this.args.activeBrandSetting;
    let newBrandSetting = this.args.brandSettings.pushObject(
      this.store.createRecord('brand-settings', {
        brandId: this.args.activeBrandSetting.brandId,
        brandName: '',
        brandDomains: [],
        brandIdentityLogoUrl: this.defaultBrandSetting.brandIdentityLogoUrl,
        messengerBackgroundColor: this.defaultBrandSetting.messengerBackgroundColor,
        messengerActionColor: this.defaultBrandSetting.messengerActionColor,
        messengerWallpaper: this.defaultBrandSetting.messengerWallpaper,
        launcherAlignment: this.defaultBrandSetting.launcherAlignment,
        launcherHorizontalPadding: this.defaultBrandSetting.launcherHorizontalPadding,
        launcherVerticalPadding: this.defaultBrandSetting.launcherVerticalPadding,
        platform: this.args.isMobilePlatform ? 'mobile' : 'web',
        helpCenterId: null,
        mobileBundleId: '',
        mobilePlatform: this.args.isMobilePlatform ? 'ios' : undefined,
      }),
    );
    newBrandSetting.addDomain('');
    this.args.setBrandSetting(newBrandSetting);
    this.showModal = true;
  }

  @action
  setActiveBrandSetting(brandSetting: BrandSettings) {
    this.args.setBrandSetting(brandSetting);
    this.showAllDomains = false;
  }

  @action
  undoAndCloseModal() {
    let isCreating = this.isCreating;

    this.args.activeBrandSetting.rollbackAttributes();

    if (isCreating) {
      this.args.setBrandSetting(this.previousActiveBrandSetting);
    }
    this.showModal = false;
  }

  @dropTask
  *updateBrandSettingAndCloseModal() {
    try {
      yield this.args.activeBrandSetting.save();

      let notificationKey =
        this.modalMode === 'create'
          ? 'messenger.look-and-feel-settings.brand-setting-picker.notification.created'
          : 'messenger.look-and-feel-settings.brand-setting-picker.notification.edited';

      let notificationText = this.intl.t(notificationKey, {
        styleName: this.args.activeBrandSetting.brandName,
      });

      this.notificationsService.notifyConfirmation(notificationText);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'save_and_set_live',
        object: 'messenger_brand',
        place: 'messenger_settings',
        section: 'messenger',
        metadata: {
          from_modal: true,
          is_new: this.isCreating,
          brand_setting_id: this.args.activeBrandSetting.id,
          brand_id: this.args.activeBrandSetting.brandId,
        },
      });
      this.showModal = false;
    } catch (error) {
      let message = this.intl.t(
        'messenger.look-and-feel-settings.brand-setting-picker.default-error-message',
        {
          styleName: this.args.activeBrandSetting.brandName,
        },
      );
      if (error.jqXHR.responseJSON.errors && error.jqXHR.responseJSON.errors[0]) {
        message = error.jqXHR.responseJSON.errors[0];
      }
      this.notificationsService.notifyError(message);
    }
  }

  @action
  openModalForEdit() {
    this.showModal = true;
    this.modalMode = 'edit';
  }

  @action
  addDomain() {
    this.args.activeBrandSetting.addDomain('');
  }

  @action
  manageBrands() {
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  @action
  removeDomain(domain: string) {
    this.args.activeBrandSetting.removeDomain(domain);
  }

  @action
  setMobilePlatform(platform: string) {
    this.args.activeBrandSetting.mobilePlatform = platform;
  }

  @action
  setBrand(id: number) {
    this.args.activeBrandSetting.brandId = id !== this.DEFAULT_BRAND_ID ? id : null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::LookAndFeel::MultiBrand::BrandSettingPicker': typeof BrandSettingPicker;
    'messenger-settings/look-and-feel/multi-brand/brand-setting-picker': typeof BrandSettingPicker;
  }
}
