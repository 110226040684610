/* import __COLOCATED_TEMPLATE__ from './privacy-policy-notice.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

interface Signature {
  Args: {
    configuration: $TSFixMe;
  };
}

export default class PrivacyPolicyNotice extends Component<Signature> {
  get defaultPrivacyPolicyNoticeContent() {
    return this.args.configuration.defaultPrivacyPolicyNoticeContent;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::Web::Conversation::PrivacyPolicyNotice': typeof PrivacyPolicyNotice;
    'messenger-preview/web/conversation/privacy-policy-notice': typeof PrivacyPolicyNotice;
  }
}
