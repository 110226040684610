/* import __COLOCATED_TEMPLATE__ from './default-predicate-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DefaultPredicateSelector extends Component {
  @service appService;
  @tracked defaultPredicateGroup = this.args.defaultPredicateGroup;

  @tracked showDefaultPredicateWarningModal = false;
  currentDefaultPredicates = copy(this.args.defaultPredicateGroup);

  get currentAdmin() {
    return this.appService.app.currentAdmin;
  }

  get adminCanModifyDefaultPredicates() {
    let role = this.currentAdmin.currentAppPermissions.role;

    if (!role) {
      return true;
    } else {
      return role.defaultPredicatesModifiable;
    }
  }

  @action
  onUpdateDefaultPredicates(defaultPredicates, meta) {
    if (meta.action === 'removed') {
      this.showDefaultPredicateWarningModal = true;
    } else {
      this.args.onUpdateDefaultPredicates(defaultPredicates);
      this.currentDefaultPredicates = copy(this.args.defaultPredicateGroup);
    }
  }

  @action
  removeDefaultPredicate() {
    this.showDefaultPredicateWarningModal = false;
    this.currentDefaultPredicates = this.defaultPredicateGroup;
    this.args.onUpdateDefaultPredicates(this.defaultPredicateGroup.predicates);
  }

  @action
  resetDefaultPredicates() {
    this.args.onUpdateDefaultPredicates(this.currentDefaultPredicates.predicates);
    this.showDefaultPredicateWarningModal = false;
  }
}
