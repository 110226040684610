/* import __COLOCATED_TEMPLATE__ from './max-match-limit.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';
import { defaultRulesetMaxMatchCount } from 'embercom/models/data/matching-system/matching-constants';

interface Args {
  maxMatchCount: number;
  setMaxMatchCount: (maxMatchCount: number) => void;
}

interface Signature {
  Args: Args;
}

export default class MaxMatchLimit extends Component<Signature> {
  @tracked matchLimit: number | string = this.args.maxMatchCount;

  get selectedControlText() {
    return this.limitIsDisabled
      ? 'the message is stopped'
      : // @ts-ignore - The type definition provided by ember-inflector is wrong here. This is a valid invocation of `pluralize`.
        `they receive the message ${pluralize(this.matchLimit, 'time')}`;
  }

  get limitIsDisabled() {
    return this.matchLimit === -1;
  }

  get limitIsEnabled() {
    return !this.limitIsDisabled;
  }

  @action disableLimit() {
    this.matchLimit = -1;
    this.setMaxMatchCount();
  }

  @action enableLimit() {
    if (this.limitIsDisabled) {
      this.matchLimit = defaultRulesetMaxMatchCount;
      this.setMaxMatchCount();
    }
  }

  @action setMaxMatchCount() {
    // @ts-ignore - parseInt will accept a number even though the operation is a no-op.
    this.args.setMaxMatchCount(parseInt(this.matchLimit, 10));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::AudienceSelector::OverMessaging::MaxMatchLimit': typeof MaxMatchLimit;
  }
}
