/* import __COLOCATED_TEMPLATE__ from './connect-messenger-hc.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';

type BrandPartial = {
  brandName: string;
  helpCenterId: number;
  platform: 'web' | 'mobile';
  isDefault: boolean;
};

interface Args {
  brands: BrandPartial[];
}

export default class ConnectMessengerHc extends Component<Args> {
  @service declare helpCenterService: any;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get helpCenterDropdownItems() {
    return this.helpCenterService.sites.map((site: HelpCenterSite) => ({
      text: site.name,
      value: Number(site.id),
    }));
  }

  get defaultBrand() {
    return this.brandsToShow.find((brand) => brand.isDefault);
  }

  get brandsToShow() {
    return this.args.brands.reject((b) => b.platform === 'mobile');
  }

  get hasMultipleBrands() {
    return this.brandsToShow.length > 1;
  }

  get paywallFeatureKey() {
    if (this.app.showMultiHelpCenterPaywalling) {
      return 'multi_help_center';
    }

    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::ConnectMessengerHc': typeof ConnectMessengerHc;
    'messenger-settings/content/connect-messenger-hc': typeof ConnectMessengerHc;
  }
}
