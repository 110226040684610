/* import __COLOCATED_TEMPLATE__ from './web.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PreviewWeb extends Component {
  @service intl;

  constructor() {
    super(...arguments);

    this.intl.maybeLoadMessengerTranslations();
  }

  get shouldDisplayCompact() {
    return this.args.isCompact && this.isHomeCurrentlyBeingShown;
  }

  get hideBorders() {
    return this.args.hideBorders;
  }

  get messengerAlignedToLeft() {
    return this.args.configuration.alignment === 'left';
  }

  get showMessengerLauncher() {
    switch (this.args.targetUserType) {
      case 'users':
        return this.args.configuration.launcherVisibleForUsers;
      case 'leads_and_visitors':
        return this.args.configuration.launcherVisibleForVisitors;
      default:
        return false;
    }
  }

  get zoomInLauncher() {
    return this.args.zoomInLauncherWeb || this.zoomInLauncherMobile;
  }

  get zoomInLauncherMobile() {
    return this.args.zoomInLauncherAndroid || this.args.zoomInLauncherIOS;
  }
  get webLauncher() {
    return !this.zoomInLauncherMobile;
  }

  get inboundConversationsDisabled() {
    if (this.args.targetUserType === 'users') {
      return !this.args.configuration.inboundConversationsEnabledForUsers;
    } else {
      return !this.args.configuration.inboundConversationsEnabledForVisitors;
    }
  }

  get preventMultipleInboundConversationsEnabled() {
    if (this.args.targetUserType === 'users') {
      return this.args.configuration.preventMultipleInboundConversationEnabledForUsers;
    } else {
      return this.args.configuration.preventMultipleInboundConversationEnabledForVisitors;
    }
  }

  get requireSearchBrowse() {
    if (this.args.targetUserType === 'users') {
      return this.args.configuration.requireSearchBrowseForUsers;
    } else {
      return this.args.configuration.requireSearchBrowseForVisitors;
    }
  }

  get activeSpace() {
    return this.args.configuration.spaces.activePreviewSpace;
  }

  get activeSpaceType() {
    if (this.showMessagesScreen) {
      return 'messages';
    } else if (this.showNewsScreen) {
      return 'news';
    } else if (this.showHelpScreen) {
      return 'help';
    } else if (this.showTasksScreen) {
      return 'tasks';
    } else if (this.showTicketsScreen) {
      return 'tickets';
    }
    return this.activeSpace.type;
  }

  get showHomeScreen() {
    return this.activeSpace.isHub;
  }

  get isHomeCurrentlyBeingShown() {
    let nonHomeScreenShown =
      this.showMessagesScreen ||
      this.showNewsScreen ||
      this.showHelpScreen ||
      this.showTasksScreen ||
      this.showConversationScreen ||
      this.showTicketsScreen;

    return this.showHomeScreen && !nonHomeScreenShown;
  }

  get showMessagesScreen() {
    return this.activeSpace.isMessages || this.args.showMessagesScreen;
  }

  get showNewsScreen() {
    return this.args.showNewsScreen;
  }

  get showHelpScreen() {
    return this.args.showHelpScreen;
  }

  get showTasksScreen() {
    return this.args.showTasksScreen;
  }

  get showTicketsScreen() {
    return this.args.showTicketsScreen;
  }

  get showConversationScreen() {
    return this.args.showConversationScreen;
  }

  get showSpacesTabBar() {
    if (this.args.isCompact) {
      return false;
    }

    return this.args.configuration.spaces.showSpacesPreview && !this.showConversationScreen;
  }

  get showIntercomLink() {
    return !this.showConversationScreen;
  }
}
