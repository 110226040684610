/* import __COLOCATED_TEMPLATE__ from './drilldown-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';

export default class DrilldownConversations extends Component {
  @service appService;
  @service store;

  @tracked conversations = [];
  @tracked didFetchConversations = false;

  @tracked conversationIds = this.args.conversationIds || [];

  @tracked page = 1;
  pageSize = 10;

  get app() {
    return this.appService.app;
  }

  get isEmpty() {
    return (
      this.conversationIds.length === 0 ||
      (this.didFetchConversations && this.conversations.length === 0)
    );
  }

  get initialPageLoading() {
    return this.page === 1;
  }

  @action
  requestNextPage() {
    this.fetchConversations.perform();
  }

  @task({ enqueue: true })
  *fetchConversations() {
    let start = (this.page - 1) * this.pageSize;
    let end = start + this.pageSize;
    if (start >= this.conversationIds.length) {
      return;
    }

    let fetchUrl = '/ember/monitoring/conversations/content';

    if (this.args.forReporting) {
      fetchUrl = '/ember/reporting/conversations/content';
    }

    let response = yield post(fetchUrl, {
      app_id: this.app.id,
      conversation_ids: this.conversationIds.slice(start, end),
    });

    this.store.pushPayload({
      conversations: response,
    });

    this.conversations.pushObjects(
      response.map((c) => this.store.peekRecord('conversation', c.id)),
    );
    this.page += 1;
    this.didFetchConversations = true;
  }
}
