/* import __COLOCATED_TEMPLATE__ from './user-role-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // All the options supported by the user role predicate (lib/predicates/addon/models/role)
  // Override this list with the options that are relevant to your feature when using this component
  optionsToDisplay: [
    'user',
    'lead',
    'visitor',
    'non_user', // This targets visitors & leads
  ],

  dropdownItems: computed('optionsToDisplay', function () {
    return this.allDropdownItems.filter((item) => this.optionsToDisplay.includes(item.value));
  }),

  allDropdownItems: [
    {
      text: 'Visitors',
      value: 'visitor',
    },
    {
      text: 'Leads',
      value: 'lead',
    },
    {
      text: 'Visitors & Leads',
      value: 'non_user',
    },
    {
      text: 'Users',
      value: 'user',
    },
  ],
});
