/* import __COLOCATED_TEMPLATE__ from './confirmation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface ConfirmationModalArgs {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  cancelLabel: string;
  confirmLabel: string;
}

const ConfirmationModal = templateOnlyComponent<ConfirmationModalArgs>();
export default ConfirmationModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::ConfirmationModal': typeof ConfirmationModal;
    'modal/confirmation-modal': typeof ConfirmationModal;
  }
}
