/* import __COLOCATED_TEMPLATE__ from './link-to.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { LinkComponent } from '@ember/legacy-built-in-components';

export default LinkComponent.extend({
  attributeBindings: [
    'data-content',
    'data-component',
    'data-track-analytics-event-object',
    'data-track-analytics-event-data-property-name',
    'data-preferred-position-name-order',
    'data-test-inbox-nav-link',
    'data-test-inbox-is-all-inbox',
    'data-intercom-target',
  ],
});
