/* import __COLOCATED_TEMPLATE__ from './assign-conversation-to-owner.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AssignConversation extends Component {
  @service appService;

  get assignee() {
    return this.appService.app.owner;
  }
}
