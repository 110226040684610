/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
let AttributeInputInserter = Mixin.create({
  formElement: 'input',
  onClick: on('click', function () {
    this.storeCaretPosition();
  }),
  onInput: on('input', function () {
    this.storeCaretPosition();
  }),
  onKeyUp: on('keyUp', function () {
    this.storeCaretPosition();
  }),
  storeCaretPosition() {
    //TODO: GJ: coalesce calls to this
    if (this.canInsertAttributes) {
      schedule('afterRender', this, function () {
        let $firstInput = $(`${this.formElement}:first`, this.element);
        if ($firstInput.length) {
          this.set('caretPosition', $firstInput[0].selectionStart);
        }
      });
    }
  },
  createAttributeTemplate(attribute, placeholder) {
    let template;
    if (attribute.get('noFallback')) {
      template = `{{ ${attribute.get('templatable_identifier')} }}`;
    } else {
      template = `{{ ${attribute.get('templatable_identifier')} | fallback:"${placeholder}" }}`;
    }
    return template;
  },
  addAttributeToInput(property, template) {
    let inputValue = this.get(property);
    let position = this.caretPosition;
    inputValue = inputValue.substr(0, position) + template + inputValue.substr(position);
    this.set(property, inputValue);
  },
  focusAttribute(placeholder) {
    let input = $(`${this.formElement}:first`, this.element)[0];
    let selectionStart = input.value.indexOf(placeholder);
    if (selectionStart >= 0) {
      input.setSelectionRange(selectionStart, selectionStart + placeholder.length);
    }
    input.focus();
  },
  addAttributeAndFocus(attributeData, property) {
    let placeholder = '_REPLACE_THIS_';
    let template = this.createAttributeTemplate(attributeData, placeholder);
    this.addAttributeToInput(property, template);
    schedule('afterRender', this, function () {
      this.focusAttribute(placeholder);
    });
  },
});

export default AttributeInputInserter;
