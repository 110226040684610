/* import __COLOCATED_TEMPLATE__ from './translation-loader-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: any;
  Blocks: {
    default: [];
  };
}
interface Args {}

export default class TranslationLoaderWrapper extends Component<Signature> {
  @service declare intl: IntlService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.intl.maybeLoadMessengerTranslations();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::TranslationLoaderWrapper': typeof TranslationLoaderWrapper;
    'messenger-preview/translation-loader-wrapper': typeof TranslationLoaderWrapper;
  }
}
