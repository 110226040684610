/* import __COLOCATED_TEMPLATE__ from './company-prioritizer-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { USER_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
const UNSUPPORTED_ATTRIBUTES = ['company.tag_ids', 'company.manual_tag_ids'];
const COMPANY_COUNT_PREDICATES = [
  { attribute: 'company_ids', comparison: 'gt', value: 1, type: 'array_size' },
];
export default class MatchingSystemCompanyPrioritizerEditorComponent extends Component {
  @service store;
  @service appService;
  @service attributeService;

  @tracked userCount;

  constructor() {
    super(...arguments);
    this.searchForUsersInMultipleCompanies.perform();
  }

  get userListLinkId() {
    let encodedPredicates = B64FilterParamEncoder.b64Encode(
      JSON.stringify({ predicates: [USER_ROLE_PREDICATE, ...COMPANY_COUNT_PREDICATES] }),
    );
    return `all:${encodedPredicates}`;
  }

  @task *searchForUsersInMultipleCompanies() {
    let result = yield post(`/ember/users/count.json`, {
      predicates: COMPANY_COUNT_PREDICATES,
      app_id: this.appService.app.id,
    });
    this.userCount = result?.count;
  }

  get isEnabled() {
    return isPresent(this.args.entity.companyPrioritizer);
  }

  set isEnabled(_) {} // NOOP as the radio button tries to set this

  get companyAttributes() {
    return this.attributeService.allCompanyAttributes
      .mapBy('attribute')
      .reject((attribute) => UNSUPPORTED_ATTRIBUTES.includes(attribute.identifier))
      .map((attribute) => ({
        text: attribute.humanFriendlyName,
        value: attribute.identifier,
      }));
  }

  @action addPrioritization() {
    let prioritization = this.store.createFragment('matching-system/prioritization', {
      attribute: this.attributeService.allCompanyAttributes.firstObject.identifier,
      direction: 'ascending',
    });
    this.args.entity.companyPrioritizer.prioritizations.pushObject(prioritization);
  }

  @action removePrioritization(prioritization) {
    this.args.entity.companyPrioritizer.prioritizations.removeObject(prioritization);
  }

  @action reorderPrioritizations(reorderedPrioritizations) {
    this.args.entity.companyPrioritizer.set('prioritizations', reorderedPrioritizations);
  }

  @action disablePrioritization() {
    this.args.entity.set('companyPrioritizer', null);
  }

  @action enablePrioritization() {
    let companyPrioritizer = this.store.createFragment('matching-system/company-prioritizer', {
      appId: this.appService.app.id,
      entityId: this.args.entity.id,
      entityType: this.args.entity.entityType,
      prioritizations: [],
    });
    this.args.entity.set('companyPrioritizer', companyPrioritizer);
  }
}
