/* import __COLOCATED_TEMPLATE__ from './external-links-slot.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ExternalLinksSlotComponent extends Component {
  @service messengerSettingsTargetUserProvider;
  @service intl;

  get showBanner() {
    if (!this.args.slotExists) {
      return false;
    } else {
      return (
        this.externalLinks.length === 0 ||
        (this.externalLinks.length === 1 && this.externalLinks.firstObject.validations.isInvalid)
      );
    }
  }

  get externalLinks() {
    return this.args.slot?.externalLinks?.toArray() || [];
  }

  get userTypeLabel() {
    return this.messengerSettingsTargetUserProvider.isUserType
      ? this.intl.t('messenger.target-types.users-label')
      : this.intl.t('messenger.target-types.visitors-label');
  }
}
