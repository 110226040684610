/* import __COLOCATED_TEMPLATE__ from './platforms-radio-group.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class PlatformsRadioGroup extends Component {
  @service('messenger-settings-service') settingsService;
  @service messengerSettingsTargetUserProvider;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get selectedValue() {
    if (this.args.disabled) {
      return this.settingsService.preview.defaultPlatform;
    }
    return this.settingsService.preview.selectedPlatform;
  }

  get platformOptions() {
    let platforms = this.settingsService.preview.platforms;
    if (this.args.hideWebButton) {
      platforms = platforms.filter(({ value }) => value !== 'web');
    }
    return platforms;
  }

  @action
  setPreviewPlatform(platform) {
    let { settings } = this.messengerSettingsTargetUserProvider;
    if (platform === 'web') {
      this.messengerSettingsTargetUserProvider.setUserTarget();
      if (settings?.lookAndFeel) {
        settings.lookAndFeel.activeBrand = settings.lookAndFeel.defaultBrand;
      }
    } else if (platform === 'ios' || platform === 'android') {
      this.messengerSettingsTargetUserProvider.setMobileTarget();
    } else {
      this.messengerSettingsTargetUserProvider.setUserTarget();
    }
    this.settingsService.changePlatform(platform);
    this.trackPlatformToggle(platform);
  }

  trackPlatformToggle(enabled) {
    this.settingsService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'preview_platform',
      toggled_to: enabled,
    });
  }
}
