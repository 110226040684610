/* import __COLOCATED_TEMPLATE__ from './workflow-connector-action.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class WorkflowConnectorAction extends Component {
  @service store;

  get workflowConnectorAction() {
    let workflowConnectorActionId = this.args.action.actionData.workflow_connector_action_id;

    return (
      this.store.peekRecord('workflow-connector/insertable-action', workflowConnectorActionId) ||
      this.store.findRecord('workflow-connector/insertable-action', workflowConnectorActionId)
    );
  }
}
