/* import __COLOCATED_TEMPLATE__ from './mailchimp-list-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { observer } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  apiKey: null,
  lists: null,
  selectedList: null,
  listToLookup: null,

  selectedListIdDidChange: observer({
    dependentKeys: ['selectedListID'],

    fn() {
      if (this.selectedListID) {
        this.set('selectedList', this.lists.findBy('id', this.selectedListID));
        this.onSelectedListChanged(this.selectedListID);
      }
    },

    sync: true,
  }),
});
