/* import __COLOCATED_TEMPLATE__ from './cards.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Cards extends Component {
  @service appService;

  get slotGroup() {
    return this.args.configuration.slotGroups.findBy('targetUserType', this.args.targetUserType);
  }

  get sortedSlots() {
    return this.slotGroup?.slots.sortBy('displayOrder:asc');
  }

  get searchBrowseRequired() {
    let { requireSearchBrowseForVisitors, requireSearchBrowseForUsers } =
      this.args.configuration.home;
    return this.args.targetUserType === 'leads_and_visitors'
      ? requireSearchBrowseForVisitors
      : requireSearchBrowseForUsers;
  }

  get slotsForDisplay() {
    let slots = this.slotGroup?.slots;
    if (!slots) {
      return [];
    }
    return slots;
  }

  get showContinueConversationCard() {
    return (
      this.args.inboundConversationsDisabled || this.args.preventMultipleInboundConversationsEnabled
    );
  }

  get showNewConversationCardInSlot() {
    if (!this.canShowNewConversationCard) {
      return false;
    }
    return !(this.slotGroup.hasHelpCenterSlot && this.searchBrowseRequired);
  }

  get canShowNewConversationCard() {
    let inboundConversationsEnabled = !this.args.inboundConversationsDisabled;
    let preventMultipleInboundConversationsDisabled =
      !this.args.preventMultipleInboundConversationsEnabled;

    return inboundConversationsEnabled && preventMultipleInboundConversationsDisabled;
  }
}
