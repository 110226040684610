/* import __COLOCATED_TEMPLATE__ from './legacy-message-viewer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/*
 * Nolaneo 24 Nov 2022 – We have some old direct messages that customers still view.
 * Rather than keeping the entire old message editor for this rare use case, let's just
 * have a low fidelity read only component to show what the content was, and who received it.
 */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class LegacyMessageViewer extends Component {
  @service notificationsService;
  @service intl;
  @service router;

  @action async delete() {
    try {
      await this.args.message.destroyRecord();
      this.notificationsService.notifyConfirmation(this.intl.t('legacy-messages.delete-success'));
      this.router.transitionTo('apps.app.outbound');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('legacy-messages.delete-error'),
      });
    }
  }
}
