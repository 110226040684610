/* import __COLOCATED_TEMPLATE__ from './mobile-installation-banner.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class MessengerSettingsMobileInstallationBanner extends Component {
  @service intl;
  @service router;

  get platformName() {
    return this.intl.t(`messenger.preview.${this.args.platform}`);
  }

  @action
  openSDKInstallationSettings(e) {
    e.preventDefault();
    let url = this.router.urlFor(`apps.app.settings.${this.args.platform}`);
    return safeWindowOpen(url, '_self');
  }
}
