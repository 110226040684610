/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed, set, action } from '@ember/object';
import { notEmpty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { copy } from 'ember-copy';
import EditorBase from 'embercom/components/message-editor/content/editor-base';
import DeviceType from 'embercom/components/mixins/device-type';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

const INTERCOM_TOURS_PACKAGE_ID = 'intercom-tours';

export default EditorBase.extend(DeviceType, {
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),
  attributeService: service(),
  'data-test-message-editor': readOnly('currentDevice'),
  currentDevice: 'web',
  classNames: ['flex-auto'],
  classNameBindings: ['noPadding::u__pad__50'],
  deliveryPreviewEnabled: false,
  dataPreviewEnabled: false,
  messengerApps: [],
  hideDeviceSwitcher: false,

  toursMessengerApp: computed('messengerApps', function () {
    return this.messengerApps.find((app) => app.app_package_id === INTERCOM_TOURS_PACKAGE_ID);
  }),

  tourInserterAppInstalled: notEmpty('toursMessengerApp'),
  shouldShowTourInserter: readOnly('tourInserterAppInstalled'),

  tourInserterContext: computed('variation', function () {
    return {
      message_id: this.get('variation.message.id'),
      message_variation_id: this.get('variation.id'),
      location: 'message',
    };
  }),

  init() {
    this._super(...arguments);
    this.fetchMessengerApps.perform();

    this.set(
      'previewConfiguration',
      this.store.createFragment('messenger-preview/configuration', {
        locale: 'en',
        backgroundPatternNumber: 1,
        logoURL: assetUrl('/assets/images/error/intercom-logo.png'),
        appPrimaryColor: '#808080',
        appSecondaryColor: '#e3e3e3',
        greetingText: 'Hi there 👋',
        aboutUsText: `We're always happy to help!`,
        messengerResponseTimeKey: 'a_few_minutes',
        showWeRunOnIntercom: true,
        inboundConversationsEnabledForUsers: false,
        inboundConversationsEnabledForVisitors: false,
        launcherVisibleForUsers: true,
        launcherVisibleForVisitors: true,
      }),
    );

    if (this.get('variation.isPointer') || this.get('variation.message.isTargetingLeads')) {
      this.set('currentDevice', 'web');
    } else if (this.get('variation.message.isAndroidFirst')) {
      this.set('currentDevice', 'android');
    } else if (this.get('variation.message.isIOSFirst')) {
      this.set('currentDevice', 'ios');
    }
  },

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.get('variation.isPointer') || this.get('variation.message.isTargetingLeads')) {
      this.set('currentDevice', 'web');
    }
  },

  _getReactionsAfterUpdate(newReaction) {
    let updatedReactions = copy(this.get('variation.reactions')).map((reaction, index) => {
      return index === newReaction.index ? newReaction : reaction;
    });
    if (newReaction.index === undefined) {
      newReaction.index = updatedReactions.get('length');
      updatedReactions.pushObject(newReaction);
    }
    return updatedReactions;
  },

  _getReactionsAfterDelete(reactionIndex) {
    let copiedReactions = copy(this.get('variation.reactions'));
    let previousReaction = copiedReactions.findBy('index', reactionIndex);
    copiedReactions.removeObject(previousReaction);
    copiedReactions.forEach((reaction, index) => set(reaction, 'index', index));
    return copiedReactions;
  },

  fetchMessengerApps: task(function* () {
    let messengerApps = yield this.store.query('messenger-app', { restrict_to: 'messages' });
    this.set('messengerApps', messengerApps);
  }),

  updateReaction: action(function (_newReaction) {
    // deprecated noop
  }),

  deleteReaction: action(function (_reactionIndex) {
    // deprecated noop
  }),
});
