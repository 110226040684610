/* import __COLOCATED_TEMPLATE__ from './exclude-articles-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { useResource, Resource } from 'ember-resources';
import { task } from 'ember-concurrency-decorators';
import { registerDestructor } from '@ember/destroyable';
import { debounceTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';

class ArticlesResource extends Resource {
  @service store;
  @tracked articles = [];
  @tracked lastPageHit = false;
  @tracked currentPage = 1;

  constructor(owner, args, previous) {
    super(owner, args, previous);

    this.articles = previous?.articles || [];
    this.lastPageHit = previous?.lastPageHit || false;

    let { currentPage, pageSize, searchTerm } = this.args.named;

    this.currentPage = currentPage;

    this.fetchArticles.perform(pageSize, searchTerm);

    registerDestructor(this, () => {
      this.fetchArticles.cancelAll();
    });
  }

  get firstPageLoaded() {
    return this.currentPage !== 1;
  }

  get isLoading() {
    return !this.firstPageLoaded && this.fetchArticles.isRunning;
  }

  get isLoadingMore() {
    return this.firstPageLoaded && this.fetchArticles.isRunning;
  }

  @task
  *fetchArticles(pageSize, searchTerm) {
    // Reqiured to stop infinite looping - see https://github.com/NullVoxPopuli/ember-resources/issues/340
    yield Promise.resolve();
    let response = yield this.store.query('article-multilingual', {
      state: this.stateFromSearchTerm(searchTerm),
      locale: null,
      page: this.currentPage,
      phrase: searchTerm,
      url_partials: [],
      per_page: pageSize,
    });

    this.setLastPageHit(response);
    this.setArticles(response);
  }

  setLastPageHit(response) {
    let nextPage = response.get('meta.next_page');
    this.lastPageHit = nextPage === null || nextPage === this.currentPage;
  }

  setArticles(response) {
    if (this.currentPage === 1) {
      this.articles = response.toArray();
    } else {
      this.articles = this.articles.concat(response.toArray());
    }
  }

  stateFromSearchTerm(searchTerm) {
    return searchTerm?.length > 0 ? 'all' : '';
  }
}

export default class ExcludeArticlesSideDrawerComponent extends Component {
  @service store;
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service intl;
  @tracked showSideDrawer = false;
  @tracked searchTerm;
  @tracked searchTermForResource;
  @tracked currentPage = 1;
  pageSize = 20;

  articlesResource = useResource(this, ArticlesResource, () => ({
    currentPage: this.currentPage,
    pageSize: this.pageSize,
    searchTerm: this.searchTermForResource,
  }));

  get articlesToSave() {
    return this.articlesResource.articles.filter((article) => article.hasDirtyAttributes);
  }

  get app() {
    return this.appService.app;
  }

  @action
  openSideDrawer() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: `excluded_articles`,
      place: 'messenger_settings_search_browse_exclude_articles_side_drawer',
    });
    this.showSideDrawer = true;
  }

  @action
  save() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: `excluded_articles`,
      place: 'messenger_settings_search_browse_exclude_articles_side_drawer',
    });
    if (this.canSaveArticles) {
      this.saveArticles();
    }
    this.showSideDrawer = false;
    this.resetSearch();
  }

  get canSaveArticles() {
    return this.articlesToSave.length > 0;
  }

  @action
  cancel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'canceled',
      object: `excluded_articles`,
      place: 'messenger_settings_search_browse_exclude_articles_side_drawer',
    });
    this.articlesToSave.forEach((article) => article.rollbackAttributes());
    this.showSideDrawer = false;
    this.resetSearch();
  }

  @action
  updateSearchTerm() {
    debounceTask(this, 'searchArticles', ENV.APP._250MS);
  }

  @action
  async searchArticles() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'searched',
      object: `excluded_articles`,
      place: 'messenger_settings_search_browse_exclude_articles_side_drawer',
    });
    this.currentPage = 1;
    this.searchTermForResource = this.searchTerm;
  }

  @action
  loadMoreArticles() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'loaded_more',
      object: `excluded_articles`,
      place: 'messenger_settings_search_browse_exclude_articles_side_drawer',
    });
    this.currentPage += 1;
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchTermForResource = '';
    this.currentPage = 1;
  }

  async saveArticles() {
    let savePromises = [];
    try {
      this.articlesToSave.forEach((article) => {
        savePromises.push(article.save());
      });
      await RSVP.all(savePromises);
      let successMessage = this.intl.t(
        'messenger.search-browse-settings.home-screen-suggestions.excluded-articles.success',
      );
      this.notificationsService.notifyConfirmation(successMessage);
    } catch (err) {
      let errorMessage = this.intl.t(
        'messenger.search-browse-settings.home-screen-suggestions.excluded-articles.error',
      );
      this.notificationsService.notifyError(errorMessage);
    }
  }
}
