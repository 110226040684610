/* import __COLOCATED_TEMPLATE__ from './actions-editor.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  RULE_ACTION_MAP,
  createConversationDataAttributeItems,
} from 'embercom/lib/inbox/constants';
import ApplyableMacroAction from 'embercom/lib/macros/applyable-macro-action';

export default class MacrosActionsEditorComponent extends Component {
  @service ruleActionService;
  @service intercomEventService;
  @service appService;
  @service attributeService;
  @service intl;
  @service store;

  BASE_ALLOWED_IDENTIFIERS = [
    'assign-conversation',
    'add-tag-to-conversation',
    'snooze-conversation',
    'close-conversation',
    'change-conversation-priority',
  ];
  CVDA_IDENTIFIER = 'set-conversation-data-attribute';
  MULTI_ACTION_IDENTIFIERS = ['add-tag-to-conversation'];
  CONFLICTING_ACTION_IDENTIFIER_SETS = [
    ['assign-conversation', 'assign-conversation-to-owner'],
    ['snooze-conversation', 'close-conversation'],
  ];

  // Available modes: management, conversation
  get showAddAction() {
    return (
      (this.args.mode === 'management' || this.args.mode === 'bulk-conversation') &&
      this.availableActionsItems.length > 0
    );
  }

  get showActionsArea() {
    return (
      this.args.mode === 'management' ||
      this.args.mode === 'bulk-conversation' ||
      this.macroActions.length > 0
    );
  }

  get shouldAddExtraMargin() {
    return this.args.mode === 'management' || this.args.mode === 'bulk-conversation';
  }

  get macroActions() {
    return this.args.parentModel.actions;
  }

  get app() {
    return this.appService.app;
  }

  get actionsAndApplyable() {
    if (this.args.mode === 'management') {
      return this.macroActions.map((action) => {
        return new ApplyableMacroAction(action, true);
      });
    } else {
      return this.args.parentModel.actionsAndApplyable;
    }
  }

  get actionEditorContext() {
    let actionEditorContext = {
      canRemoveAssignee: true,
      mode: this.args.mode,
    };

    if (this.app.canUseWorkflowConnectorActionMacros) {
      actionEditorContext['workflowConnectorActions'] = this.workflowConnectorActions;
    }

    return actionEditorContext;
  }

  get workflowConnectorActions() {
    return this.store.findAll('workflow-connector/insertable-action');
  }

  get usedIdentifiers() {
    return this.macroActions.map((a) => a.type);
  }

  get availableIdentifiers() {
    if (
      this.app.canUseWorkflowConnectorActionMacros &&
      !this.BASE_ALLOWED_IDENTIFIERS.includes('workflow-connector-action')
    ) {
      this.BASE_ALLOWED_IDENTIFIERS.push('workflow-connector-action');
      this.MULTI_ACTION_IDENTIFIERS.push('workflow-connector-action');
    }

    if (
      this.app.hasConversationalInsightsBillingFeature &&
      !this.BASE_ALLOWED_IDENTIFIERS.includes('add-conversation-topic')
    ) {
      this.BASE_ALLOWED_IDENTIFIERS.push('add-conversation-topic');
      this.MULTI_ACTION_IDENTIFIERS.push('add-conversation-topic');
    }
    // Remove actions we've already used (except those allowing duplicates)
    let availableIdentifiers = this.BASE_ALLOWED_IDENTIFIERS.filter((identifier) => {
      return (
        !this.usedIdentifiers.includes(identifier) ||
        this.MULTI_ACTION_IDENTIFIERS.includes(identifier)
      );
    });

    // Remove any actions that cannot be applied together
    let conflicts = this.CONFLICTING_ACTION_IDENTIFIER_SETS.map((conflictSet) => {
      if (conflictSet.some((c) => this.usedIdentifiers.includes(c))) {
        return conflictSet;
      }
    }).flat();
    availableIdentifiers = availableIdentifiers.filter((i) => !conflicts.includes(i));

    return availableIdentifiers;
  }

  get availableCvdaActionsItems() {
    let cvdaItems = createConversationDataAttributeItems(
      this.attributeService.nonArchivedEditableConversationCustomAttributes,
    );

    // Remove CvDAs that have been used by attribute id
    let usedCvdaAttributeIdentifiers = this.macroActions
      .filter((macroAction) => {
        return macroAction.type === this.CVDA_IDENTIFIER;
      })
      .map((cvdaAction) => cvdaAction.actionData.attribute_identifier);

    cvdaItems = cvdaItems.filter((cvdaItem) => {
      return !usedCvdaAttributeIdentifiers.includes(cvdaItem.attribute_identifier);
    });

    return cvdaItems;
  }

  get availableActionsItems() {
    // Convert unused identifiers to items
    let availableItems = this.availableIdentifiers.map((identifier) => {
      let action = RULE_ACTION_MAP[identifier];
      return {
        ...action,
        text: this.intl.t(`inbox.manage-macros.rule-action-map.${action.intlKey}`),
      };
    });

    if (this.availableCvdaActionsItems && this.availableCvdaActionsItems.length > 0) {
      availableItems = [...availableItems, ...this.availableCvdaActionsItems];
    }

    return availableItems;
  }

  get availableActionsGroupList() {
    return [{ items: this.availableActionsItems.sortBy('order') }];
  }

  _afterActionsUpdate() {
    if (this.args.onChange) {
      this.args.onChange();
    }

    if (this.args.composerComponent) {
      this.args.composerComponent.focus();
    }
  }

  @action addAction(item, event) {
    if (event && event.stopImmediatePropagation) {
      event.stopImmediatePropagation();
    }

    if (item.paywalled) {
      return false;
    }

    this.ruleActionService.addActionToTarget(this.macroActions, item);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      object: 'action',
      place: 'macros',
      location: this.args.location,
      identifier: item.identifier,
    });

    this._afterActionsUpdate();
  }

  @action removeAction(fragment) {
    this.macroActions.removeFragment(fragment);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'removed',
      object: 'action',
      place: 'macros',
      location: this.args.location,
      identifier: fragment.type,
      conversation_id: this.args.conversationId,
    });

    this._afterActionsUpdate();
  }
}
