/* import __COLOCATED_TEMPLATE__ from './header-content.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { htmlToTextContent, htmlUnescape } from 'embercom/lib/html-unescape';

export default class HeaderContent extends Component {
  get hasContent() {
    let logoURL = !!this.args.configuration.logoURL;
    let greetingText = !!this.args.configuration.greetingText;
    let greetingSubtitleText = !!this.args.configuration.greetingSubtitleText;
    let showAvatars = !!this.args.configuration.showAvatars;
    return logoURL || greetingText || greetingSubtitleText || showAvatars;
  }

  get greetingText() {
    return this._renderPreviewText(this.args.configuration.greetingText || '');
  }

  get topHeaderText() {
    return this.args.configuration.greetingSubtitleText ? this.greetingText : '';
  }

  get bottomHeaderText() {
    return this._renderPreviewText(
      this.args.configuration.greetingSubtitleText || this.greetingText,
    );
  }

  get hasLongText() {
    return (
      this.args.configuration.greetingSubtitleText?.length > 16 || this.greetingText?.length > 16
    );
  }

  get greetingTextShadowClass() {
    if (this.args.configuration.changeHeaderColors) {
      switch (this.args.configuration.textColor) {
        case 'dark':
          return 'messenger-preview-mobile__header__top-text__dark-shadow';
        case 'light':
          return 'messenger-preview-mobile__header__top-text__light-shadow';
        default:
          return '';
      }
    }
  }

  _renderPreviewText(text) {
    let previewText = renderPreview(htmlToTextContent(text), {});
    return htmlUnescape(previewText);
  }
}
