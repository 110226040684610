/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';

interface Args {
  afterAcceptAction?: () => void;
}

export default class FinOptInModal extends Component<Args> {
  @service declare finOptInService: FinOptInService;
  @service declare appService: any;

  get adminsWithBillingPermissions() {
    return this.appService.app.adminsWithBillingPermissions;
  }

  get finPricePerResolution(): number {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get canCreateFinFreeUsageWindow(): boolean {
    return this.appService.app.canCreateFinFreeUsageWindow;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::FinOptIn::Modal': typeof FinOptInModal;
    'modal/fin-opt-in/modal': typeof FinOptInModal;
  }
}
