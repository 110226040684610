/* import __COLOCATED_TEMPLATE__ from './connection-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ConnectionSwitcher extends Component {
  @tracked connectionSwitcherHovered = false;
  orFilterLimit = 10;
  logicalSwitcherItems = [
    { text: 'and', value: 'and' },
    { text: 'or', value: 'or' },
  ];
  logicalSwitcherTooltip =
    "You have too many filters to switch to 'or' (the limit is 10). Remove a few first.";
}
