/* import __COLOCATED_TEMPLATE__ from './upgrade-install-banner.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import compareVersions from 'embercom/lib/compare-versions';
import { isEmpty, isPresent } from '@ember/utils';

export default class UpgradeInstallBanner extends Component {
  @service appService;

  get hasNoMobileSdk() {
    let androidSdkVersion = this.app().android_sdk_version;
    let iosSdkVersion = this.app().ios_sdk_version;
    return isEmpty(androidSdkVersion) && isEmpty(iosSdkVersion);
  }

  get hasOldMobileSdk() {
    return !this.hasCurrentAndroidSdk() && !this.hasCurrentIosSdk();
  }

  hasCurrentAndroidSdk() {
    let androidSdkVersion = this.app().android_sdk_version;
    return (
      isPresent(androidSdkVersion) &&
      compareVersions(androidSdkVersion, this.args.minimumVersion) !== -1
    );
  }

  hasCurrentIosSdk() {
    let iosSdkVersion = this.app().ios_sdk_version;
    return (
      isPresent(iosSdkVersion) && compareVersions(iosSdkVersion, this.args.minimumVersion) !== -1
    );
  }

  app() {
    return this.appService.app;
  }
}
