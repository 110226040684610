/* import __COLOCATED_TEMPLATE__ from './identity-verification-installation-instructions.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  INSTALL_TYPE,
  LOGGED_IN_CODE_DROPDOWN_ITEMS,
  THIRD_PARTY_DROPDOWN_ITEMS,
} from 'embercom/lib/messenger/installation-constants';
import { action } from '@ember/object';

export default class IdentityVerificationInstallationInstructions extends Component {
  @service appService;
  @service store;
  @service intercomEventService;

  @tracked selectedInstallMethod = null;
  @tracked selectedInstallType = this.defaultInstallType;

  constructor() {
    super(...arguments);
    if (!this.app.api_secret) {
      // this will only work if the teammate has permissions, otherwise it will set the secret to null
      this.app.fetchAPISecret.perform();
    }
  }

  get app() {
    return this.appService.app;
  }

  get baseAnalyticsMetadata() {
    return {
      ...this.args.step.analyticsMetadata,
      installType: this.selectedInstallMethod,
    };
  }

  get dropdownItems() {
    return this.selectedInstallMethod === INSTALL_TYPE.THIRD_PARTY
      ? THIRD_PARTY_DROPDOWN_ITEMS
      : LOGGED_IN_CODE_DROPDOWN_ITEMS;
  }

  get defaultInstallType() {
    return this.dropdownItems.firstObject.value;
  }

  get installationComponentPath() {
    return `guide/start-screen/tasks/identity-verification/${this.selectedInstallType}-component`;
  }

  @action setSelectedInstallType(installType) {
    this.selectedInstallType = installType;
  }

  @action setSelectedInstallMethod(installMethod) {
    this.selectedInstallMethod = installMethod;
  }

  @action setWithCode() {
    this.setSelectedInstallMethod(INSTALL_TYPE.CODE);
    if (this.selectedInstallType !== this.defaultInstallType) {
      this.setSelectedInstallType(this.defaultInstallType);
    }
  }

  @action setWithThirdParty() {
    this.setSelectedInstallMethod(INSTALL_TYPE.THIRD_PARTY);
    if (this.selectedInstallType !== this.defaultInstallType) {
      this.setSelectedInstallType(this.defaultInstallType);
    }
  }
}
