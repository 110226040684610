/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
// @ts-ignore
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { htmlToTextContent } from 'embercom/lib/html-unescape';

interface Signature {
  Args: {
    configuration: $TSFixMe;
  };
  Blocks: {
    default: [];
  };
}

export default class HeaderContent extends Component<Signature> {
  get greetingText() {
    return this._renderPreviewText(this.args.configuration.conversationalGreetingText || '');
  }

  _renderPreviewText(text: string) {
    return renderPreview(htmlToTextContent(text), {});
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::Conversational::Mobile::Header': typeof HeaderContent;
    'messenger-preview/conversational/moblie/header': typeof HeaderContent;
  }
}
