/* import __COLOCATED_TEMPLATE__ from './positioning.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Positioning extends Component {
  @service intl;

  get alignmentOptions() {
    return [
      {
        value: 'right',
        text: this.intl.t(
          'messenger.look-and-feel-settings.customize.launcher.visibility.position.right',
        ),
      },
      {
        value: 'left',
        text: this.intl.t(
          'messenger.look-and-feel-settings.customize.launcher.visibility.position.left',
        ),
      },
    ];
  }

  @action
  setAlignment(alignment) {
    this.args.activeBrand.launcherAlignment = alignment;
    if (this.args.activeBrand.isDefault) {
      this.args.settings.alignment = alignment;
    }
  }

  @action
  updateMessengerSettingsHorizontalPadding({ target: { value } }) {
    if (this.args.activeBrand.isDefault) {
      this.args.settings.horizontalPadding = value;
    }
  }

  @action
  updateMessengerSettingsVerticalPadding({ target: { value } }) {
    if (this.args.activeBrand.isDefault) {
      this.args.settings.verticalPadding = value;
    }
  }
}
