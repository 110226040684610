/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { gte, not } from '@ember/object/computed';
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import moment from 'moment-timezone';
import HoursWith30MinsInterval from 'embercom/models/data/hours-with-30mins-interval';

const NINE_AM = 9 * 3600;

export default Mixin.create({
  initSnoozeProperties() {
    this.setProperties({
      selectedDayMoment: moment(),
      selectedHours: 24,
      monthOffset: 0,
    });
  },
  hoursWith30MinsInterval: computed('selectedDayMoment', function () {
    let now = moment().startOf('minute');
    if (this.selectedDayMoment.isSame(now, 'day')) {
      let midnight = now.clone().startOf('day');

      let secondsSinceMidnight = now.diff(midnight, 'seconds');
      let utcOffsetDiff = midnight.utcOffset() - now.utcOffset();

      if (utcOffsetDiff !== 0) {
        // We have transitioned from one timezone to another because of daylight savings starting or ending
        // We want to discount or add back the time change to make sure we get an accurate count of hours from midnight -> now
        // Negative means moving forward: we had x fewer minutes during the morning (e.g. 1 hour forward for GMT -> BST)
        // Positive means moving backwards: we had x more minutes during the morning (e.g. 1 hour backward for BST -> GMT)
        // Absolute value of how much we need to change the secondsSinceMidnight by
        let timezoneChangeDiff = Math.abs(utcOffsetDiff);

        // Negative: we moved forward and "lost" minutes => add them back
        // Positive: we moved backward and "gained" minutes => remove them
        if (utcOffsetDiff < 0) {
          secondsSinceMidnight += timezoneChangeDiff * 60;
        } else {
          secondsSinceMidnight -= timezoneChangeDiff * 60;
        }
      }

      return HoursWith30MinsInterval.filter(
        (time) => time.secondsFromMidnight >= secondsSinceMidnight,
      );
    }
    return HoursWith30MinsInterval;
  }),
  selectedTimeInSecondsSinceMidnight: computed('hoursWith30MinsInterval', function () {
    let times = this.hoursWith30MinsInterval;
    if (times.length > 0 && times[0].secondsFromMidnight > NINE_AM) {
      return times[0].secondsFromMidnight;
    }
    return NINE_AM;
  }),
  hasHourOptionsForSelectedDay: gte('hoursWith30MinsInterval.length', 1),
  disableSnoozeButton: not('hasHourOptionsForSelectedDay'),
});
