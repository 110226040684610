/* import __COLOCATED_TEMPLATE__ from './date-offset-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { schedule } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import { pluralize } from 'ember-inflector';

const DAYS_IN = {
  day: 1,
  week: 1 * 7,
};

const OFFSET_UNITS = ['day', 'week', 'month'];

export default class DateOffsetDropdown extends Component {
  @tracked selectedOffset;
  @tracked selectedUnit = 'day';

  constructor() {
    super(...arguments);
    this.selectedOffset = this.recurringSchedule.absoluteOffsetValue;

    if (isPresent(this.recurringSchedule.monthOffset)) {
      this.selectedUnit = 'month';
    } else {
      this.selectedUnit = 'day';
    }
  }

  get recurringSchedule() {
    return this.args.recurringSchedule;
  }

  get dateOffsetLabel() {
    return this.recurringSchedule.dateOffsetLabel;
  }

  get dateOffsetUnits() {
    return OFFSET_UNITS;
  }

  get selectedDateOffsetAndUnitText() {
    let text = "if it's";
    if (this.selectedOffset > 0 || this.selectedOffset < 0) {
      text = `if it's ${pluralize(Math.abs(this.selectedOffset), this.selectedUnit)}`;
    }

    return text;
  }

  get dayOrWeek() {
    return this.selectedUnit === 'day' || this.selectedUnit === 'week';
  }

  @action onSelectUnit(unit) {
    this.selectedUnit = unit;
    this.setDateOffsetUnit();
  }

  @action setDateOffsetUnit() {
    if (this.dayOrWeek) {
      if (this.dateOffsetLabel.includes('before')) {
        this.recurringSchedule.dayOffset = -this.selectedOffset * DAYS_IN[`${this.selectedUnit}`];
      } else if (this.dateOffsetLabel.includes('after')) {
        this.recurringSchedule.dayOffset = +this.selectedOffset * DAYS_IN[`${this.selectedUnit}`];
      }
      this.recurringSchedule.monthOffset = null;
    } else {
      if (this.dateOffsetLabel.includes('before')) {
        this.recurringSchedule.monthOffset = -this.selectedOffset;
      } else if (this.dateOffsetLabel.includes('after')) {
        this.recurringSchedule.monthOffset = +this.selectedOffset;
      }
      this.recurringSchedule.dayOffset = 0;
    }
  }

  @action deleteDateOffset() {
    this.args.recurringSchedule.dayOffset = null;
    this.args.recurringSchedule.monthOffset = null;
  }

  @action openOffsetUnitDropdown() {
    if (this.args.openDropdown && !this.recurringSchedule.isOnDate) {
      schedule('afterRender', () => {
        let offsetUnitDropdownOpener = document.querySelector(
          '[data-date-offset-unit-select-dropdown]',
        );
        if (offsetUnitDropdownOpener) {
          offsetUnitDropdownOpener.click();
        }
      });
    }
  }
}
