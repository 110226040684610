/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { computed } from '@ember/object';
import { bind } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
/*
* The intention of this mixin is to provide a generic to provide keyboard
* actions to a list of varying designs (e.g. multiple categories, mutable list items etc)
* by interacting with the DOM elements themselves.

* Interface:
  When the component has a KB focused item, the class `js__has-kb-focused-item` is added to the component.
  This allows you to override :hover effects that may be present on row items.

* jsHighlightClass = The class applied to the focused element.
  EX: `.js__focused`

* jsKeyboardRowSelector = The class(es) which determines which DOM elements in the list are focusable.
  These items should have {{action ...}} defined on them.
  EX: `.list-row-item, .list-expander-item`

* jsActiveKeyboardRowSelector = The class(es) which determine what element is currently focused.
  EX: `.list-row-item.js__focused, list-expander-item.js__focused`

* jsExpanderRowSelector = The class(es) which determine if a list row causes insertion of more rows when clicked.
  EX `.list-expander-item`
*/

export default Mixin.create({
  _setupKBEventHandlers: on('didInsertElement', function () {
    $(document).on(`click.${this.elementId}`, bind(this, this._removeItemFocusOnClick));
    $('input', this.element).on(`focus.${this.elementId}`, bind(this, this._removeItemFocus));
    $(this.element).on(`mousemove.${this.elementId}`, bind(this, this._removeItemFocus));
  }),

  _teardownKBEventHandlers: on('willDestroyElement', function () {
    $(document).off(`click.${this.elementId}`);
    $('input', this.element).off(`focus.${this.elementId}`);
    $(this.element).off(`mousemove.${this.elementId}`);
  }),

  _plainKBHighlightClass: computed('jsHighlightClass', function () {
    let jsHighlightClass = this.jsHighlightClass;
    if (jsHighlightClass.startsWith('.')) {
      return this.jsHighlightClass.slice(1, jsHighlightClass.length);
    } else {
      console.error(`jsHighlightClass must start with '.'`);
    }
  }),

  _removeItemFocusOnClick(e) {
    if (!this.isDestroying) {
      if ($(this.element).find(e.target).length === 0) {
        this._removeItemFocus();
      }
    }
  },

  _removeItemFocus() {
    if (!this.isDestroying) {
      $(this.element).toggleClass('js__has-kb-focused-item', false);
      $(this.jsActiveKeyboardRowSelector, this.element).removeClass(this._plainKBHighlightClass);
    }
  },

  keyDown(e) {
    let DOWNARROW = 40;
    let UPARROW = 38;
    let ENTER = 13;
    switch (e.keyCode) {
      case DOWNARROW: {
        e.preventDefault();
        return this._focusNextItem();
      }
      case UPARROW: {
        e.preventDefault();
        return this._focusPreviousItem();
      }
      case ENTER: {
        e.preventDefault();
        return this._addSelectedItem();
      }
    }
  },

  _focusNextItem() {
    $(this.element).toggleClass('js__has-kb-focused-item', true);
    let currentItem = $(this.jsHighlightClass, this.element);
    let selectableRows = $(this.jsKeyboardRowSelector, this.element);
    if (currentItem.length && selectableRows.index(currentItem) + 1 < selectableRows.length) {
      let currentSelectedIndex = selectableRows.index(currentItem);
      this._focusItemAtIndex(currentSelectedIndex + 1);
    } else if (currentItem.length === 0) {
      $(this.jsKeyboardRowSelector, this.element).first().addClass(this._plainKBHighlightClass);
    }
  },

  _focusPreviousItem() {
    let currentItem = $(this.jsHighlightClass, this.element);
    let selectableRows = $(this.jsKeyboardRowSelector, this.element);
    if (currentItem.length && selectableRows.index(currentItem) - 1 >= 0) {
      let currentSelectedIndex = selectableRows.index(currentItem);
      this._focusItemAtIndex(currentSelectedIndex - 1);
    } else if (currentItem.length) {
      $(this.element).toggleClass('js__has-kb-focused-item', false);
      currentItem.removeClass(this._plainKBHighlightClass);
    }
  },

  _focusItemAtIndex(index) {
    let currentItem = $(this.jsHighlightClass, this.element);
    let selectableRows = $(this.jsKeyboardRowSelector, this.element);
    currentItem.removeClass(this._plainKBHighlightClass);
    $(selectableRows.get(index)).addClass(this._plainKBHighlightClass);
  },

  _addSelectedItem() {
    let currentItem = $(this.jsHighlightClass, this.element);
    let selectableRows = $(this.jsKeyboardRowSelector, this.element);
    if (currentItem) {
      let moveSelectionAfterSelection = this.jsExpanderRowSelector
        ? currentItem.is(this.jsExpanderRowSelector)
        : false;
      let originalIndex = selectableRows.index(currentItem);
      currentItem.click();
      if (moveSelectionAfterSelection) {
        this._focusItemAtIndex(originalIndex);
      }
    }
  },
});
