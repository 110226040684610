/* import __COLOCATED_TEMPLATE__ from './search-browse-card.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SearchAndBrowseCard extends Component {
  @service messengerSettingsTargetUserProvider;

  get showSuggestions() {
    return this.messengerSettingsTargetUserProvider?.settings?.home?.homeScreenSuggestionsEnabled;
  }
}
