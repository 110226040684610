/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { bind } from '@ember/runloop';
import $ from 'jquery';
import Mixin from '@ember/object/mixin';
let ScrollManager = Mixin.create({
  scrollParentSelector: null,

  bindToScrollParent(calculatePositionCallback) {
    if (calculatePositionCallback) {
      if (this.scrollParentSelector) {
        $(this.scrollParentSelector).on(
          `scroll.${this.elementId}`,
          bind(this, calculatePositionCallback),
        );
      }
      //eslint-disable-next-line @intercom/intercom/no-jquery-window-on
      $(window).on(`resize.${this.elementId}`, bind(this, calculatePositionCallback));
    }
  },

  unbindFromScrollParent() {
    if (this.scrollParentSelector) {
      $(this.scrollParentSelector).off(`scroll.${this.elementId}`);
    }
    $(window).off(`resize.${this.elementId}`);
  },
});

export default ScrollManager;
