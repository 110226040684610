/* import __COLOCATED_TEMPLATE__ from './external-links.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';

export default class ExternalLinks extends Component {
  get exampleExternalLinks() {
    return [
      {
        label: null,
        href: null,
      },
    ];
  }

  get externalLinks() {
    return this.noLinksConfigured ? this.exampleExternalLinks : this.args.externalLinks;
  }

  get noLinksConfigured() {
    let externalLinks = this.args.externalLinks;
    return isBlank(externalLinks) || externalLinks?.length === 0;
  }
}
