/* import __COLOCATED_TEMPLATE__ from './bulk-archive-companies.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BulkArchiveBaseComponent from 'embercom/components/modal/user-or-company-deletion/bulk-archive-base-component';

export default BulkArchiveBaseComponent.extend({
  entityString: 'company',
  pluralizedEntity: 'companies',
});
