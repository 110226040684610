/* import __COLOCATED_TEMPLATE__ from './opt-in-subscription-warning.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';

export default class OptInSubscriptionWarning extends Component {
  get messageType() {
    return humanReadableObjectNames[this.args.subscriptionType.permittedContentTypes[0]];
  }
}
