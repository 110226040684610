/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { on } from '@ember/object/evented';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  renderMetricBufferSize: 5,
  frontendStatsService: service('frontendStatsService'),
  recordRenderTimeOnInsertion: on('didInsertElement', function () {
    let renderMetricName = this.renderMetricName;
    this.frontendStatsService.timeUntilAfterRender(`ember_ux_${renderMetricName}RenderTime`, {
      bufferSize: this.renderMetricBufferSize,
    });
  }),
});
