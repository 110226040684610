/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
export default Component.extend({
  classNameBindings: ['hasAvatar:o__has-avatar'],
  avatarURL: readOnly('teammate.avatar.image_urls.square_128'),
  hasAvatar: readOnly('teammate.has_photo_avatar'),
  attributeBindings: ['data-test-messenger-preview-avatar'],
  'data-test-messenger-preview-avatar': true,

  initial: computed('teammate.avatar.initials', function () {
    let initials = this.get('teammate.avatar.initials');
    return initials && initials.length > 0 ? initials.charAt(0).toUpperCase() : null;
  }),
  pxSize: computed('small', 'tiny', function () {
    if (this.tiny) {
      return 24;
    } else if (this.small) {
      return 36;
    } else {
      return 56;
    }
  }),
});
