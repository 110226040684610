/* import __COLOCATED_TEMPLATE__ from './cta-form.hbs'; */
/* RESPONSIBLE TEAM: team-web */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import ENV from 'embercom/config/environment';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { equal } from '@ember/object/computed';

export default Component.extend({
  media: service(),
  classNames: ['u__display-inline-block'],
  redirectService: service(),
  gtmService: service(),
  emailInput: null,
  submittedEmptyEmail: false,
  isSmall: equal('size', 'small'),
  inputClass: computed('isSmall', 'submittedEmptyEmail', function () {
    return `marketing-site__cta-form__input ${
      this.isSmall ? ' o__small' : ''
    } ${this.submittedEmptyEmail ? ' o__error' : ''}`;
  }),

  submitSignUp: task(function* () {
    let emailInput = this.emailInput;
    this.set('submittedEmptyEmail', !emailInput);

    if (emailInput) {
      let emailSubmissionID = yield this.gtmService.recordEmailSubmission(
        emailInput,
        MARKETO_FORM_IDS.trialSignupStart,
        { source: 'signup-intent', visitor_id: window.Intercom && window.Intercom('getVisitorId') },
      );
      let scheme = ENV.environment === 'development' ? 'http' : 'https';
      let tld = ENV.environment === 'development' ? 'test' : 'io';
      this.redirectService.redirect(
        `${scheme}://www.intercom.${tld}/signup-redirect?email_submission_id=${emailSubmissionID}`,
      );
    }
  }).drop(),
});
