/* import __COLOCATED_TEMPLATE__ from './add-tag-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AddTagToConversation extends Component {
  @service appService;

  get tagName() {
    let tagId = this.args.action.actionData.tag_id;

    if (!tagId) {
      return '';
    }

    let tag = this.appService.app.tags.findBy('id', tagId);
    return tag.name;
  }
}
