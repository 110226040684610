/* import __COLOCATED_TEMPLATE__ from './mobile-device-preview.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import QRCodeStyling from 'qr-code-styling';
import { inject as service } from '@ember/service';

export default class MobileDevicePreview extends Component {
  @service appService;

  @tracked showPreviewModal = false;
  @tracked companyName = 'Example corp';

  @action
  togglePreviewModal() {
    this.showPreviewModal = !this.showPreviewModal;
  }

  get lookAndFeelSettings() {
    return this.args.lookAndFeelSettings;
  }

  get defaultBrand() {
    return this.lookAndFeelSettings.defaultBrand;
  }

  get QRCodeLink() {
    let expiry = Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 2; // expire in 2 days
    let baseQRCodeLink = `https://app.intercom.com/sales-preview?expires=${expiry}&primary-color=${this.lookAndFeelSettings.primaryColorHex}&secondary-color=${this.lookAndFeelSettings.secondaryColorHex}&company=${this.companyName}&logo=${this.lookAndFeelSettings.logoUrl}&app_id_code=${this.appService.app.id}`;
    if (this.args.redesignPreview) {
      baseQRCodeLink += this.redesignParamsString;
    }
    return baseQRCodeLink;
  }

  get redesignParamsString() {
    let paramsString = `&background-type=${this.defaultBrand.backgroundType}&fade-to-white=${this.defaultBrand.fadeToWhite}&show-avatars=${this.defaultBrand.showAvatars}`;
    if (this.defaultBrand.backgroundType === 'colors') {
      paramsString += `&text-color=${this.defaultBrand.textColor}&gradient-first-color=${this.defaultBrand.gradientFirstColor}&gradient-second-color=${this.defaultBrand.gradientSecondColor}&gradient-third-color=${this.defaultBrand.gradientThirdColor}`;
    } else if (this.defaultBrand.backgroundType === 'image') {
      paramsString += `&text-color=${this.defaultBrand.textColor}&background-image-url=${this.defaultBrand.backgroundImageUrl}`;
    }
    return paramsString;
  }

  @action
  renderQRCode() {
    new QRCodeStyling({
      width: 286,
      height: 286,
      data: this.QRCodeLink,
      image: this.lookAndFeelSettings.logoUrl,
      dotsOptions: {
        color: this.lookAndFeelSettings.secondaryColor,
        type: 'classy-rounded',
      },
      imageOptions: {
        hideBackgroundDots: true,
      },
    }).append(document.getElementById('qr-code'));
  }
}
