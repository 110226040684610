/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { runTask } from 'ember-lifeline';
import { once } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import { computed, observer } from '@ember/object';
import { bool, not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { normalizeEvent } from 'ember-jquery-legacy';

export default Mixin.create({
  intl: service(),
  classNames: ['csv_file_component'],
  classNameBindings: ['isDragging:is-dragging', 'hasFile:has-file'],
  isDragging: false,
  fileExtension: 'png,gif,jpeg,jpg',
  maxFileSize: '1MB',
  invalidFileResetDelay: 1700,
  hasFile: bool('file'),
  fileIsValid: not('fileIsInvalid'),
  fileHasInvalidExtension: not('fileHasValidExtension'),

  message: computed('intl.locale', 'uploadDidFail', 'fileIsTooLarge', function () {
    if (this.fileIsTooLarge) {
      return this.intl.t('components.mixins.image-upload-component.large-size', {
        maxFileSize: this.maxFileSize,
      });
    } else if (this.uploadDidFail) {
      return this.intl.t('components.mixins.image-upload-component.error-occurred');
    } else if (this.fileHasInvalidExtension) {
      return this.intl.t('components.mixins.image-upload-component.valid-file');
    }
  }),

  updateReadyState: on(
    'didInsertElement',
    observer({
      dependentKeys: ['hasFile'],

      fn() {
        this.set('readyForUpload', !!this.hasFile);
      },

      sync: true,
    }),
  ),

  fileIsTooLarge: computed('file', function () {
    let file = this.file;
    // 15MB in bytes
    return !file || file.size > 15000000;
  }),

  fileIsInvalid: computed(
    'file',
    'fileHasInvalidExtension',
    'uploadDidFail',
    'fileIsTooLarge',
    function () {
      return (
        !this.file || this.fileHasInvalidExtension || this.uploadDidFail || this.fileIsTooLarge
      );
    },
  ),

  willDestroyElement() {
    this.set('file', null);
    this._super(...arguments);
  },

  dragEnter(e) {
    if (this.fileIsInvalid) {
      e.preventDefault();
      this.set('isDragging', true);
      this.resetFileIfInvalid();
    }
  },

  dragOver(e) {
    this.dragEnter(e);
  },

  dragLeave(e) {
    e.preventDefault();
    this.set('isDragging', false);
  },

  drop(e) {
    e.preventDefault();
    this.setFile(e.dataTransfer.files[0]);
  },

  change(event) {
    let nativeEvent = normalizeEvent(event);
    let target = nativeEvent.target;
    if ($(target).is('input:file')) {
      this.set('file', undefined);
      this.setFile(target.files[0]);
    }
  },

  resetFileIfInvalid() {
    if (this.fileIsInvalid) {
      if (!this.isDestroying) {
        this.setProperties({
          file: undefined,
          uploadDidFail: false,
        });
      }
    }
  },

  _doResetFileIfInvalid() {
    if (!this.isDestroying && this.fileIsInvalid) {
      runTask(
        this,
        function () {
          if (this.isDestroying) {
            return;
          }
          this.resetFileIfInvalid();
        },
        this.invalidFileResetDelay,
      );
    }
  },

  scheduleInvalidFileReset: observer({
    dependentKeys: ['file', 'uploadDidFail'],

    fn() {
      once(this, '_doResetFileIfInvalid');
    },

    sync: true,
  }),

  setFile(file) {
    if (!this.file) {
      this.setProperties({
        isDragging: false,
        file,
        hasFile: true,
        saving: false,
        previewImageUrl: undefined,
      });
      let reader = new FileReader();
      reader.onload = (e) => {
        this.send('imageLoaded', e.target.result);
      };
      reader.readAsDataURL(file);
    }
  },

  fileHasValidExtension: computed('file', 'fileExtension', function () {
    let fileName = this.get('file.name');
    let fileExtensions = this.fileExtension.split(',');

    return fileExtensions.some((fileExtension) => {
      let regexp = new RegExp(`\.${fileExtension}$`, 'i');
      return fileName && fileName.match(regexp);
    });
  }),

  actions: {
    imageLoaded(previewUrl) {
      this.set('previewImageUrl', previewUrl);
    },
  },
});
