/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class Editor extends Component {
  @service appService;

  @tracked currentDevice = 'ios';
  @tracked currentPushContent;
  @tracked isExpanded = false;

  constructor() {
    super(...arguments);
    this.setupCurrentModel();
    // Need to check if ruleset link is android first too.
    if (this.app.onlyHasAndroidSdk) {
      this.currentDevice = 'android';
    }
  }

  get app() {
    return this.appService.app;
  }

  get iosSelected() {
    return this.currentDevice === 'ios';
  }

  get androidSelected() {
    return this.currentDevice === 'android';
  }

  @action setupCurrentModel() {
    this.currentPushContent = this.args.push.localizedPushContents.firstObject;
  }

  @action changeDevice(device) {
    this.currentDevice = device;
  }

  @action changeExpanded(isExpanded) {
    this.isExpanded = isExpanded;
  }
}
