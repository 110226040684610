/* import __COLOCATED_TEMPLATE__ from './textarea-with-count.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

const CHAR_LIMIT = 160;

export default class TextareaWithCount extends Component {
  get textAreaRows() {
    return this.args.textAreaRows || 3;
  }

  get charLimit() {
    return this.args.charLimit || CHAR_LIMIT;
  }

  get charactersUsed() {
    return this.args.text.length || 0;
  }

  get charactersLeft() {
    return this.charLimit - this.charactersUsed;
  }
}
