/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  humanReadableObjectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';

export default class Selector extends Component {
  get filteredTemplates() {
    let templates = this._templatesFor(this.args.selectedObjectType);

    // The backend currently returns the templates for legacy in-apps and legacy emails using the object type
    // for the non-legacy objects.
    if (this.args.selectedObjectType === objectTypes.legacyMessageInApp) {
      templates.push(...this._templatesFor(objectTypes.inApp));
    } else if (this.args.selectedObjectType === objectTypes.legacyMessageEmail) {
      templates.push(...this._templatesFor(objectTypes.email));
    }

    return templates;
  }

  get humanReadableObjectName() {
    return humanReadableObjectNames[this.args.selectedObjectType];
  }

  _templatesFor(objectType) {
    return this.args.templates.filter(
      (template) => template.contentObjects.firstObject.type === objectType,
    );
  }
}
