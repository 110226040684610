/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { observer } from '@ember/object';
import $ from 'jquery';
import Mixin from '@ember/object/mixin';
import { addEventListener, removeEventListener } from 'ember-lifeline';

let CloseOnDocumentClickManager = Mixin.create({
  isOpen: false,

  _isOpenObserver: observer({
    dependentKeys: ['isOpen'],

    fn() {
      if (this.isOpen) {
        this._setupDocumentClickHandler();
      } else {
        this._teardownDocumentClickHandler();
      }
    },

    sync: true,
  }),

  _setupDocumentClickHandler() {
    addEventListener(this, document, 'click', this._closeOnDocumentClick);
  },

  _teardownDocumentClickHandler() {
    removeEventListener(this, document, 'click', this._closeOnDocumentClick);
  },

  _closeOnDocumentClick(e) {
    if (!this.isDestroying) {
      if ($(this.element).find(e.target).length === 0) {
        this.set('isOpen', false);
      }
    }
  },
});

export default CloseOnDocumentClickManager;
