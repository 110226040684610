/* import __COLOCATED_TEMPLATE__ from './conversation-import-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import { showNewMessageInIntercomWidget } from '../../lib/intercom-widget-helper';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  intercomEventService: service(),
  notificationsService: service(),
  router: service(),
  openCenteredWindowService: service(),
  importWindowCurrentlyOpen: false,
  onImportFlowComplete: () => {},

  openImportWindow: task(function* (origin) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'launch_importer',
      context: 'from_import_conversation_history_modal',
      metadata: { origin },
    });

    let url = this._urlForImportWindow(origin);
    try {
      let window = this.openCenteredWindowService.setup(url, 800, 1280, 'Import');
      if (window) {
        window.focus();
        yield this._waitForChildWindow(window);
      }
    } catch (error) {
      this.notificationsService.notifyError(error);
    }

    this.set('importWindowCurrentlyOpen', true);
  }),

  _waitForChildWindow(childWindow) {
    if (childWindow.closed) {
      this.set('importWindowCurrentlyOpen', false);
      if (window.importFlowCompleted) {
        this.onImportFlowComplete();
      }
    } else {
      later(() => this._waitForChildWindow(childWindow), 200);
    }
  },

  _urlForImportWindow(origin) {
    if (origin === 'zendesk_chat' || origin === 'zendesk_tickets') {
      return this.router.urlFor(`apps.app.import.zendesk-chat`, this.app, {
        queryParams: { origin },
      });
    } else {
      throw new Error(`Unknown origin "${origin}"`);
    }
  },

  actions: {
    openWidget() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'chat_with_us_link',
        context: 'from_import_conversation_history_modal',
      });

      showNewMessageInIntercomWidget(
        "Hi there, I'd like to import conversation history for Resolution Bot using a different tool",
      );
    },
  },
});
