/* import __COLOCATED_TEMPLATE__ from './sender-assignee-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
import EmberObject, { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { fmtStyle, notImplemented, ternary } from '@intercom/pulse/lib/computed-properties';
import IntercomDropdownComponent from 'embercom/components/intercom-dropdown-component';
import SelectKeyboardActions from 'embercom/components/mixins/select-keyboard-actions';

export default IntercomDropdownComponent.extend(SelectKeyboardActions, {
  isOpen: false,
  showDynamicSender: computed(
    'isFallback',
    'variation.isVisitorAutoMessage',
    'variation.isMobilePush',
    function () {
      return (
        !this.isFallback &&
        !this.get('variation.isMobilePush') &&
        !this.get('variation.isVisitorAutoMessage')
      );
    },
  ),
  isFallback: false,
  itemWidth: ternary('isFallback', 288, 308),
  itemHeight: 36,
  maxHeight: 400,
  additionalHeight: 22,
  dropdownMaxWidth: alias('itemWidth'),
  additionalClasses: 'message__editor__content__sidebar__admin-selector u__pad__5',
  scrollParentSelector: '.js__message-view-and-edit-component__container',
  classNames: ['f__custom-select', 'test__message-editor__sender-assignee-dropdown'],
  classNameBindings: ['isOpen:o__opened'],
  dynamicSenders: [EmberObject.create({ isDynamicSender: true, displayAs: 'Owner', id: 'owner' })],
  dynamicSendersWithLabel: computed('showDynamicSender', function () {
    if (this.showDynamicSender) {
      return [{ isLabel: true, text: 'Dynamic' }, ...this.dynamicSenders];
    }
    return [];
  }),
  selected: notImplemented(
    'message-editor/content/sidebar/sender-assignee-dropdown: You must override the `selected` property',
  ),
  items: notImplemented(
    'message-editor/content/sidebar/sender-assignee-dropdown: You must override the `items` property',
  ),
  containerHeight: computed('itemHeight', 'items.[]', function () {
    return Math.min(
      this.itemHeight * this.get('items.length') + this.additionalHeight,
      this.maxHeight,
    );
  }),
  overlayStyle: fmtStyle('height: %@px;', 'containerHeight'),

  actions: {
    handleSelection(selectedItem) {
      this.set('isOpen', false);
      this.setSelected(selectedItem);
    },
  },
});
