/* import __COLOCATED_TEMPLATE__ from './web.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

interface Signature {
  Args: {
    targetUserType: 'users' | 'leads_and_visitors';
    configuration: $TSFixMe;
    zoomInLauncherWeb: boolean;
    zoomInLauncherAndroid: boolean;
    zoomInLauncherIOS: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class ConversationalWebPreview extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare messengerSettingsService: MessengerSettingsService;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    this.intl.maybeLoadMessengerTranslations();
  }

  get showMessengerLauncher() {
    switch (this.args.targetUserType) {
      case 'users':
        return this.args.configuration.launcherVisibleForUsers;
      case 'leads_and_visitors':
        return this.args.configuration.launcherVisibleForVisitors;
      default:
        return false;
    }
  }

  get zoomInLauncher() {
    return this.args.zoomInLauncherWeb || this.zoomInLauncherMobile;
  }

  get zoomInLauncherMobile() {
    return this.args.zoomInLauncherAndroid || this.args.zoomInLauncherIOS;
  }

  get messengerAlignedToLeft() {
    return this.args.configuration.alignment === 'left';
  }

  get isLauncherAccordian() {
    return this.messengerSettingsService.openSectionId === 'launcher';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::Conversational::Web': typeof ConversationalWebPreview;
    'messenger-preview/conversational/web': typeof ConversationalWebPreview;
  }
}
