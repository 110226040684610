/* import __COLOCATED_TEMPLATE__ from './inbound-volume.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';

const ANALYTICS_EVENTS = [
  {
    attribute: 'messengerEnabledForVisitors',
    targetingAttribute: 'allowAllVisitors',
    enabledEventName: 'show_launcher_for_visitors',
    disabledEventName: 'hide_launcher_for_visitors',
  },
  {
    attribute: 'messengerEnabledForUsers',
    targetingAttribute: 'allowAllUsers',
    enabledEventName: 'show_launcher_for_users',
    disabledEventName: 'hide_launcher_for_users',
  },
  {
    attribute: 'inboundConversationsEnabledForVisitors',
    targetingAttribute: 'inboundConversationsForVisitorsPredicateGroup',
    enabledEventName: 'enable_new_conversations_for_visitors',
    disabledEventName: 'disble_new_conversations_for_visitors',
  },
  {
    attribute: 'inboundConversationsEnabledForUsers',
    targetingAttribute: 'inboundConversationsForUsersPredicateGroup',
    enabledEventName: 'enable_new_conversations_for_users',
    disabledEventName: 'disble_new_conversations_for_users',
  },
  {
    attribute: 'preventMultipleInboundConversationsEnabledForUsers',
    targetingAttribute: 'preventMultipleInboundConversationEnabledForUsers',
    enabledEventName: 'enable_preventing_duplicate_conversations_for_users',
    disabledEventName: 'disable_preventing_duplicate_conversations_for_users',
  },
  {
    attribute: 'preventMultipleInboundConversationsEnabledForVisitors',
    targetingAttribute: 'preventMultipleInboundConversationEnabledForVisitors',
    enabledEventName: 'enable_preventing_duplicate_conversations_for_visitors',
    disabledEventName: 'disable_preventing_duplicate_conversations_for_visitors',
  },
];

export default class InboundVolume extends Component {
  @service notificationsService;
  @service store;
  @service appService;
  @service intl;
  @service messengerSettingsTargetUserProvider;
  @service MessengerSettingsService;

  changedAttributes = null;
  helpCenterSite = null;

  constructor(owner, args) {
    super(owner, args);
    this.fetchHelpCenterSite.perform();
  }

  app() {
    return this.appService.app;
  }

  @task
  *fetchHelpCenterSite() {
    let helpCenterSites = yield this.store.findAll('help-center-site');
    this.helpCenterSite = helpCenterSites.firstObject;
  }

  sendAnalyticsEvents() {
    ANALYTICS_EVENTS.forEach((event) => {
      let changedAttributeKeys = Object.keys(this.changedAttributes);
      let settingChanged = changedAttributeKeys.includes(event.attribute);
      let targetingChanged = changedAttributeKeys.includes(event.targetingAttribute);
      let settingEnabled = this.visibilitySettings[event.attribute];

      let withPredicates;

      // Targeting info will not exist for new conversation targeting for apps without this feature enabled
      let targetingInfoExists = this.visibilitySettings[event.targetingAttribute] !== undefined;
      if (targetingInfoExists) {
        let targetingInfo = this.visibilitySettings[event.targetingAttribute];

        // This is required because new conversation targeting and launcher visibility handle predicates differently
        if (typeof targetingInfo === 'boolean') {
          withPredicates = !targetingInfo;
        } else {
          withPredicates = targetingInfo.hasPredicates;
        }
      }

      if (settingChanged || targetingChanged) {
        this.MessengerSettingsService.trackAnalyticsEvent({
          action: 'toggled',
          place: 'continued-conversations-settings',
          object:
            settingEnabled || targetingChanged ? event.enabledEventName : event.disabledEventName,
          withPredicates: settingEnabled || targetingChanged ? withPredicates : undefined,
        });
      }
    });

    this.singleConversationToggleAnalyticsEvent();
  }

  singleConversationToggleAnalyticsEvent() {
    let key = 'inboundConversationsDisabled';
    if (Object.keys(this.changedAttributes).includes(key)) {
      let value = this.changedAttributes[key][1];
      this.MessengerSettingsService.trackAnalyticsEvent({
        action: 'toggled',
        place: 'continued-conversations-settings',
        object: value ? 'disable_new_conversations' : 'enable_new_conversations',
      });
    }
  }

  @action
  beforeSave() {
    this.changedAttributes = this.visibilitySettings.changedAttributes();
    this.visibilitySettings.updateLauncherPredicates();
  }

  @action
  afterSave() {
    this.sendAnalyticsEvents();
    this.updateSearchBrowseSettingsIfNeeded();
    this.changedAttributes = null;
  }

  @action
  toggleProperty(property) {
    this.args.visibilitySettings[property] = !this.args.visibilitySettings[property];
    if (
      property === 'inboundConversationsEnabled' &&
      !this.args.visibilitySettings.inboundConversationsEnabled
    ) {
      this.args.homeSettings.disableRequireSearchBrowse();
    }
    this.trackToggleProperty(property);
  }

  trackToggleProperty(property) {
    let enabled = this.args.visibilitySettings[property];
    let snakeCasedProperty = property.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'toggled',
      object: snakeCasedProperty,
      toggled_to: enabled,
    });
  }

  @action
  toggleRequireSearch() {
    this.args.homeSettings.toggleRequireSearchBrowse();
  }

  @action
  toggleOperatorArticleAutoReaction(property) {
    this.args.operatorArticleAutoReaction[property] =
      !this.args.operatorArticleAutoReaction[property];
  }

  @action
  changeTargetUserType(value) {
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'clicked',
      object: `messenger_settings_preview_visibility_${value}`,
      context: 'from_panel',
    });
  }
}
