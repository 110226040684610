/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { isPresent } from '@ember/utils';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { COMPOSER_UPLOAD_WARNING } from 'embercom/models/data/messages/message-constants';

export default Mixin.create({
  notificationsService: service(),
  withComposerUploadCheck(block) {
    let message = isPresent(this.message) ? this.message : this.get('variation.message');
    if (message.get('isUploading')) {
      return this.notificationsService.notifyWarning(COMPOSER_UPLOAD_WARNING);
    } else {
      return block();
    }
  },
});
