/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import { A } from '@ember/array';
import { run } from '@ember/runloop';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import $ from 'jquery';
import AvatarAndMapSearch from 'embercom/components/mixins/avatar-and-map-search';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { captureException } from 'embercom/lib/sentry';
import mapMarkerTemplate from 'embercom/templates-raw/map/map-marker';
import mapPopupTemplate from 'embercom/templates-raw/map/map-popup';

function removeElement(e) {
  $(e.target).remove();
}

export default Component.extend(AvatarAndMapSearch, {
  appService: service(),
  app: readOnly('appService.app'),
  mapContainerId: alias('elementID'),
  elementID: '',
  classNames: [],
  mapBoxStyleUrl:
    'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
  initialCenterPoint: [18.0, 0.0],
  initialExtents: [
    [-35.0, -160.0],
    [60.0, 160.0],
  ],
  clusterOptions: {
    showCoverageOnHover: false,
    maxClusterRadius: 45,
  },
  mapOptions: {
    minZoom: 2,
    worldCopyJump: true,
  },
  layerOptions: {
    detectRetina: true,
    tileSize: 512,
    zoomOffset: -1,
    id: 'mapbox/streets-v11',
    accessToken: 'pk.eyJ1IjoicGF0cmlja29kIiwiYSI6ImY1LVY4WkUifQ.WK9SrChxuv4vz1NxPDooSw',
  },

  didInsertElement() {
    this._super(...arguments);
    if (typeof window.L === 'undefined') {
      $.getScript('https://api.tiles.mapbox.com/mapbox.js/v1.6.2/mapbox.js', () => {
        // @pat - Disabling sanitization linting here as we are just injecting static scripts. There is no unsafe user input.
        /* eslint-disable jquery-unsafe/no-append */
        $('head').append(
          $('<link rel="stylesheet" type="text/css" />').attr(
            'href',
            'https://api.tiles.mapbox.com/mapbox.js/v1.6.2/mapbox.css',
          ),
        );
        $('head').append(
          $('<link rel="stylesheet" type="text/css" />').attr(
            'href',
            'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-markercluster/v0.4.0/MarkerCluster.css',
          ),
        );
        $('head').append(
          $('<link rel="stylesheet" type="text/css" />').attr(
            'href',
            'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-markercluster/v0.4.0/MarkerCluster.Default.css',
          ),
        );
        $.getScript(
          'https://api.tiles.mapbox.com/mapbox.js/plugins/leaflet-markercluster/v0.4.0/leaflet.markercluster.js',
          () => {
            this.initializeMap();
            this.loadPage();
          },
        );
      });
    } else {
      this.initializeMap();
      this.loadPage();
    }
    // manually add event listener so we can listen on capture phase
    run(() => this.element.addEventListener('error', removeElement, true));
    this.set('usersHaveLoaded', false);
    this.set('usersLoadedCount', 0);
    this.set('totalUsersCount', undefined);
    this.set('uniqueUserCountries', A());
  },
  numberOfUserCountries: 0,

  willClearRender() {
    this._super(...arguments);
    if (this.xhr) {
      this.xhr.abort();
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    try {
      this.element.removeEventListener('error', removeElement, true);
      this.map.remove();
      this.set('map', null);
      this.set('cluster', null);
      this._super(...arguments);
    } catch (e) {
      captureException(e, {
        fingerprint: ['map-component', 'willDestroyElement'],
      });
    }
  },

  icon(user) {
    setProperties(user, {
      app_id: this.get('app.id'),
      default_avatar_url: DefaultAvatars.users[64],
    });
    let userData = user.serialize ? user.serialize() : user;
    let html = mapMarkerTemplate(userData);
    return {
      className: 'avatar o__m',
      html,
      iconSize: [32, 32],
    };
  },
  popupContent(user) {
    let userData = user.serialize ? user.serialize() : user;
    return mapPopupTemplate(userData);
  },
  marker(user) {
    return window.L.marker([user.geoip_data.latitude, user.geoip_data.longitude], {
      icon: window.L.divIcon(this.icon(user)),
    }).bindPopup(this.popupContent(user));
  },
  mappableUsers(users) {
    return users.filter(this.userHasLatitudeAndLongtitude);
  },
  userHasLatitudeAndLongtitude(user) {
    return user.geoip_data && user.geoip_data.latitude && user.geoip_data.longitude;
  },
  addUsers(mappableUsers) {
    let cluster = this.cluster;
    let markers = mappableUsers.map(function (user) {
      return this.marker(user);
    }, this);
    cluster.addLayers(markers);
    this.map.addLayer(cluster);
  },

  extractUniqueUserCountries(mappableUsers) {
    return mappableUsers.mapBy('geoip_data.country_code2').uniq();
  },

  setMapBackground() {
    $(this.element).css(
      'background-image',
      'linear-gradient( to bottom, #73b5e5, #73b5e5 50%, #e8e8e8 50%)',
    );
  },
  initializeMap() {
    let map = this.map;
    if (map) {
      map.remove();
    }
    this.set(
      'map',
      window.L.mapbox
        .map(this.mapContainerId, null, this.mapOptions)
        .fitBounds(this.initialExtents)
        .setView(this.initialCenterPoint)
        .addLayer(window.L.tileLayer(this.mapBoxStyleUrl, this.layerOptions)),
    );
    this.set('cluster', new window.L.MarkerClusterGroup(this.clusterOptions));
    this.setMapBackground();
  },

  addNewUniqueCountriesToCountrySet(mappableUsers) {
    let countrySet = this.uniqueUserCountries;
    countrySet.pushObjects(this.extractUniqueUserCountries(mappableUsers));
    countrySet = countrySet.uniq();
    this.set('numberOfUserCountries', countrySet.get('length'));
  },

  loadPage(page) {
    let app = this.app;
    let predicates = this.predicates;
    this.avatarAndMapSearch(app, predicates, page).then((response) => {
      let mappableUsers = this.mappableUsers(response.users);
      this.addNewUniqueCountriesToCountrySet(mappableUsers);
      this.addUsers(mappableUsers);
      this.incrementProperty('usersLoadedCount', response.users.length);
      this.set('totalUsersCount', response.totalCount);
      if (response.nextPage) {
        this.loadPage(response.nextPage);
      } else {
        this.set('usersHaveLoaded', true);
      }
    });
  },
});
