/* import __COLOCATED_TEMPLATE__ from './submenu.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

type SubmenuArgs = {
  model: $TSFixMe;
};

export default class Submenu extends Component<SubmenuArgs> {
  @service declare appService: $TSFixMe;

  get settings() {
    return this.args.model.settings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Submenu': typeof Submenu;
    'messenger-settings/conversational/submenu': typeof Submenu;
  }
}
