/* import __COLOCATED_TEMPLATE__ from './saved-contents-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly, gt, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { next } from '@ember/runloop';
import $ from 'jquery';

export default Component.extend({
  appService: service(),
  intercomEventService: service(),
  notificationsService: service(),
  app: readOnly('appService.app'),
  store: service(),
  savedContents: null,
  hasSavedContents: gt('savedContents.length', 0),
  selectedSavedContent: null,
  isCreateMode: equal('mode', 'create'),
  isEditMode: equal('mode', 'edit'),

  init() {
    this._super(...arguments);
    this.resetSelectedSavedContent();
    let action = this.isCreateMode ? 'opened_create_modal' : 'opened_manage_modal';
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'saved_content',
    });
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.isCreateMode) {
      this.createSavedContent();
      next(this, this._focusOnNameInput);
    }
  },

  _focusOnNameInput() {
    $('.js__saved-contents__name-input').focus();
  },

  resetSelectedSavedContent() {
    let serializedBlocks = [];
    if (this.hasSavedContents) {
      let savedContent = this.get('savedContents.firstObject');
      this.setSelectedSavedContent(savedContent);
      serializedBlocks = savedContent.get('serializedBlocks');
    } else {
      this.set('selectedSavedContent', null);
    }
    this.setComposerValues(serializedBlocks);
  },

  setComposerValues(serializedBlocks) {
    if (this.savedContentReadOnlyComposer) {
      this.savedContentReadOnlyComposer.send('load', serializedBlocks);
    } else if (this.savedContentComposer) {
      this.savedContentComposer.send('load', serializedBlocks);
    }
  },

  setSelectedSavedContent(savedContent) {
    this.setAllContentsInactive();
    this.set('selectedSavedContent', savedContent);
    this.selectedSavedContent.set('active', true);
  },

  setAllContentsInactive() {
    if (this.hasSavedContents) {
      this.savedContents.setEach('active', false);
    }
  },

  createSavedContent() {
    let savedContent = this.store.createRecord('saved-content', {
      adminId: this.get('admin.id'),
      appId: this.get('app.id'),
      blocks: [createFragmentFromBlock(this.store, { type: 'paragraph', text: '' })],
    });
    this.setProperties({
      selectedSavedContent: savedContent,
      mode: 'edit',
    });
    this.setSelectedSavedContent(savedContent);
    next(this, this._focusOnNameInput);
  },

  saveContent(savedContent) {
    savedContent
      .save()
      .then(async () => {
        await this.app.refreshSavedReplies();
        this.set('mode', '');
        this.notificationsService.notifyConfirmation('Content saved');
      })
      .catch((response) => {
        this._handleSaveError(response.jqXHR);
      });
  },

  _handleSaveError(response) {
    let errorMessage = 'An error occurred, please try again later';
    if (response.responseJSON && response.responseJSON.errors) {
      errorMessage = response.responseJSON.errors.firstObject.message;
    }
    this.notificationsService.notifyError(errorMessage);
  },

  deleteSavedContent: action(async function () {
    await this.selectedSavedContent.destroyRecord();
    this.resetSelectedSavedContent();
    if (!this.hasSavedContents) {
      this.set('mode', '');
    }
  }),

  actions: {
    selectSavedContent(savedContent) {
      this.setSelectedSavedContent(savedContent);
      this.savedContentReadOnlyComposer.send('load', savedContent.get('serializedBlocks'));
    },

    editSavedContent() {
      this.set('mode', 'edit');
    },

    createSavedContent() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'create_from_modal',
        object: 'saved_content',
      });
      this.createSavedContent();
    },

    async cancelEditSavedContent() {
      if (!this.selectedSavedContent.get('isValid')) {
        await this.selectedSavedContent.destroyRecord();
      }
      this.set('mode', '');
      this.set('selectedSavedContent', null);
      this.resetSelectedSavedContent();
    },

    saveSavedContent() {
      let blocks = this.savedContentComposer.getBlocks();
      let blockFragments = blocks.map((block) => createFragmentFromBlock(this.store, block));
      this.set('selectedSavedContent.blocks', blockFragments);
      if (this.selectedSavedContent.get('isValid')) {
        if (this.selectedSavedContent.get('isNameLengthValid')) {
          this.saveContent(this.selectedSavedContent);
        } else {
          this.notificationsService.notifyError(
            `Macro name exceeded maximum length of 140 characters`,
          );
        }
      } else {
        this.notificationsService.notifyError(`Macro name & body are required`);
      }
    },

    insertSavedContent() {
      this.insertSavedContent(this.selectedSavedContent);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'inserted_from_modal',
        object: 'saved_content',
      });
      this.closeModal();
    },

    async closeModal() {
      if (this.selectedSavedContent && !this.selectedSavedContent.get('isValid')) {
        await this.selectedSavedContent.destroyRecord();
      }
      this.closeModal();
    },
  },
});
