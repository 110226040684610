/* import __COLOCATED_TEMPLATE__ from './spaces-card.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SpacesCard extends Component {
  get previewSpaces() {
    return this.args.spaces?.selectedSpaces?.filter(
      (space) => (space.isMessages || space.isHelp || space.isTickets) && space.enabled,
    );
  }

  @action
  showDivder(space) {
    return (
      this.previewSpaces?.length > 1 &&
      space.name !== this.previewSpaces[this.previewSpaces.length - 1].name
    );
  }
}
