/* import __COLOCATED_TEMPLATE__ from './switch-messenger.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SwitchMessenger extends Component {
  @service declare router: any;
  @tracked messengerType = 'conversational';

  @action
  setMessengerType(type: string) {
    this.messengerType = type;
  }

  @action
  cancel() {
    window.history.back();
  }

  @action
  continueSetup() {
    if (this.messengerType === 'classic') {
      this.router.transitionTo('apps.app.settings.channels.messenger.web', {
        queryParams: { tab: 'content', section: 'layout-and-spaces' },
      });
    } else {
      this.router.transitionTo('apps.app.settings.channels.messenger.conversational.web', {
        queryParams: { tab: 'web', section: 'brand' },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::SwitchMessenger': typeof SwitchMessenger;
    'messenger-settings/conversational/switch-messenger': typeof SwitchMessenger;
  }
}
