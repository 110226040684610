/* import __COLOCATED_TEMPLATE__ from './cards.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

export default class Cards extends Component {
  get slotGroup() {
    return this.args.configuration.slotGroups.findBy('targetUserType', this.args.targetUserType);
  }

  get sortedSlots() {
    return this.slotGroup?.slots.sortBy('displayOrder:asc');
  }

  get sortedSlotsToDisplay() {
    let slots = this.sortedSlots;
    if (this.args.configuration.isMobileCompact && slots) {
      return slots.filter((slot) => slot.isExternalLinksSlot || slot.isNewConversationCardSlot);
    }

    return slots;
  }

  get searchBrowseRequired() {
    return this.args.configuration.requireSearchBrowse;
  }

  get showContinueConversationCard() {
    return (
      this.args.inboundConversationsDisabled || this.args.preventMultipleInboundConversationsEnabled
    );
  }

  get showNewConversationCardInSlot() {
    if (!this.canShowNewConversationCard) {
      return false;
    }
    return !(this.slotGroup.hasHelpCenterSlot && this.searchBrowseRequired);
  }

  get canShowNewConversationCard() {
    let inboundConversationsEnabled = !this.args.inboundConversationsDisabled;
    let preventMultipleInboundConversationsDisabled =
      !this.args.preventMultipleInboundConversationsEnabled;

    return inboundConversationsEnabled && preventMultipleInboundConversationsDisabled;
  }
}
