/* import __COLOCATED_TEMPLATE__ from './google-tag-manager-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseInstallMethodComponent from 'embercom/components/messenger-installation/install-methods/base-install-method-component';
import documentationMetadata from 'embercom/helpers/documentation-metadata';

export default BaseInstallMethodComponent.extend({
  googleTagManagerInstallationLinkMetadata: documentationMetadata('googleTagManagerInstallation'),
});
