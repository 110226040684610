/* import __COLOCATED_TEMPLATE__ from './audience-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import MatchingSystemAudienceRulesEditor from 'embercom/components/matching-system/audience-selector/audience-rules-editor';
import { action } from '@ember/object';
import { targetUserGroup } from 'embercom/models/data/carousel/target-user-group';

export default class AudienceRulesEditor extends MatchingSystemAudienceRulesEditor {
  get targetUserGroups() {
    return Object.keys(targetUserGroup).map((key) => {
      let option = targetUserGroup[key];
      return {
        text: option.label,
        value: option.value,
        isSelected: option.value === this.activeObject.targetUserGroup,
      };
    });
  }

  get selectedOptionText() {
    let selectedOption = this.targetUserGroups.find((o) => o.isSelected);
    if (selectedOption) {
      return selectedOption.text;
    } else {
      return targetUserGroup.all.label;
    }
  }

  get activeObject() {
    return this.args.ruleset.rulesetLinks.firstObject.object;
  }

  @action
  setTargetUserGroup(group) {
    this.args.ruleset.rulesetLinks.forEach((rl) => (rl.object.targetUserGroup = group));
  }
}
