/* import __COLOCATED_TEMPLATE__ from './domains.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class Domains extends Component {
  @action
  changeAllowedDomains(newDomains) {
    let domainsArray = newDomains.split(',');
    this.args.securitySettings.parsedAllowedDomains = domainsArray;
  }
}
