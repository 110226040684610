/* import __COLOCATED_TEMPLATE__ from './ticket-links.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';

export default class TicketLinks extends Component {
  get ticketLinks() {
    return this.noLinksConfigured ? [] : this.args.ticketLinks;
  }
  get noLinksConfigured() {
    let ticketLinks = this.args.ticketLinks;
    return isBlank(ticketLinks) || ticketLinks?.length === 0;
  }
}
