/* import __COLOCATED_TEMPLATE__ from './length-monitoring-textarea-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

export default class LengthMonitoringTextAreaComponent extends Component {
  @tracked size = 0;
  @tracked value = '';
  @tracked isActive = false;

  get remaining() {
    return this.maxSize - this.value.length;
  }
  get isOver() {
    return this.remaining < 0;
  }

  get maxSize() {
    return this.args.maxSize || 250;
  }

  resizeTextarea = modifier((element, [value], _named) => {
    if (value !== undefined) {
      element.style.height = '0px';
      element.style.height = `${element.scrollHeight + 1}px`;
    }
  });

  @action
  handleInput(event) {
    this.value = event.target.value;
  }

  @action
  handleFocusIn() {
    this.isActive = true;
  }

  @action
  handleFocusOut() {
    this.isActive = false;
  }
}
