/* import __COLOCATED_TEMPLATE__ from './brand-identity-logo-upload.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { ref } from 'ember-ref-bucket';
import type IntlService from 'ember-intl/services/intl';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

type Args = {
  lookAndFeelSettings: $TSFixMe;
  pickFile?: $TSFixMe;
};

const MAX_FILE_SIZE = 2000000; // 2MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
const ALLOWED_FORMATS = '.jpeg, .jpg, .png, .gif';
const ASPECT_RATIO = 1;

export default class BrandIdentityLogoUpload extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare imageCropperService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare MessengerSettingsService: MessengerSettingsService;
  @tracked uncroppedFile?: File | null;
  @tracked isDragging = false;
  @tracked isCropping = false;
  @tracked file?: File | null;
  @ref('inputElement') inputElement: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get hasImage() {
    return !!this.args.lookAndFeelSettings.brandIdentityLogoUrl;
  }

  get allowedTypeList() {
    return ALLOWED_FILE_TYPES.join(',');
  }

  get aspectRatio() {
    return ASPECT_RATIO;
  }

  get isUploading() {
    return taskFor(this.uploadLogo).isRunning;
  }

  validate(file: File) {
    let errors = [];
    if (!this.validFormat(file)) {
      errors.push(
        this.intl.t(
          'messenger.conversational.greeting.set-expectations.brand.logo-image.errors.format',
          {
            formats: ALLOWED_FORMATS,
          },
        ),
      );
    } else if (!this.validSize(file)) {
      errors.push(
        this.intl.t(
          'messenger.conversational.greeting.set-expectations.brand.logo-image.errors.size',
          {
            fileSize: MAX_FILE_SIZE / 1000000,
          },
        ),
      );
    }
    if (errors.length > 0) {
      errors.forEach((error) => this.notificationsService.notifyError(error));
      return false;
    }
    return true;
  }

  validFormat(file: File) {
    return ALLOWED_FILE_TYPES.includes(file.type);
  }

  validSize(file: File) {
    return file.size <= MAX_FILE_SIZE;
  }

  @dropTask *uploadLogo(file: File) {
    yield ajax({
      url: `/ember/messenger_settings/upload_logo/${this.app.id}`,
      type: 'POST',
      data: this.filePayload(file),
    })
      .then((response: any) => {
        this.handleUploadSuccess(response);
        this.trackImageUpload(response.url);
      })
      .catch((error: any) => {
        this.handleUploadError(error);
      });
  }

  filePayload(fileData: any) {
    return JSON.stringify({
      file_data: fileData.split(',')[1],
      filename: this.file?.name,
      content_type: this.file?.type,
    });
  }

  handleUploadError(error: any) {
    this.removeImage();
    this.notificationsService.notifyResponseError(error, {
      default: this.intl.t(
        'messenger.conversational.greeting.set-expectations.brand.logo-image.errors.default',
      ),
    });
  }

  handleImageLoadError(error: any) {
    this.removeImage();
    this.notificationsService.notifyResponseError(error, {
      default: this.intl.t(
        'messenger.conversational.greeting.set-expectations.brand.logo-image.errors.loading',
      ),
    });
  }

  handleUploadSuccess(response: any) {
    this.args.lookAndFeelSettings.brandIdentityLogoId = response.id;
    this.args.lookAndFeelSettings.brandIdentityLogoUrl = response.url;
  }

  trackImageUpload(imageUrl: string) {
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'uploaded',
      object: 'brand_identity_logo',
      image_url: imageUrl,
    });
  }

  pickFile() {
    if (typeof this.args.pickFile === 'function') {
      // this is here for testing 🤮
      return this.args.pickFile.call(this);
    }
    this.clearFileInput();
    this.inputElement.click();
  }

  async setFile(file: File) {
    this.file = file;
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.args.lookAndFeelSettings.brandIdentityLogoUrl = e.target.result;
      taskFor(this.uploadLogo).perform(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  async cropImage(file: File) {
    let options = { type: file.type };
    let url = URL.createObjectURL(file);

    await this.imageCropperService.cropCenter(url, ASPECT_RATIO);

    let croppedFile = await this.imageCropperService.toFile(file.name, options);
    return croppedFile;
  }

  clearFileInput() {
    this.inputElement.value = '';
  }

  @action
  dragOver(e: any) {
    e.preventDefault();
    this.isDragging = true;
  }

  @action
  dragLeave(e: any) {
    e.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(e: any) {
    e.preventDefault();
    this.isDragging = false;
    this.setOrStartCropping(e.dataTransfer.files[0]);
  }

  @action
  change(e: any) {
    if (e.target.files.length) {
      this.setOrStartCropping(e.target.files[0]);
    }
  }

  async checkAspectRatio(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      let inputImage = new Image();
      inputImage.onload = () => {
        let inputWidth = inputImage.naturalWidth;
        let inputHeight = inputImage.naturalHeight;
        let inputImageAspectRatio = inputWidth / inputHeight;
        resolve(inputImageAspectRatio);
      };

      inputImage.onerror = () => {
        reject(new Error('There was an error loading the image.'));
      };

      inputImage.src = URL.createObjectURL(file);
    });
  }

  @action
  async setOrStartCropping(file: File) {
    if (this.validate(file)) {
      let aspectRatio;
      try {
        aspectRatio = await this.checkAspectRatio(file);
      } catch (e) {
        this.handleImageLoadError(e);
        return;
      }

      if (aspectRatio !== ASPECT_RATIO && file.type !== 'image/gif') {
        this.uncroppedFile = file;
        this.isCropping = true;
      } else {
        this.setFile(file);
      }
    }
  }

  @action
  onCrop(file: File) {
    this.setFile(file);
    this.uncroppedFile = null;
    this.isCropping = false;
  }

  @action
  onCropModalClose() {
    this.uncroppedFile = null;
    this.isCropping = false;
  }

  @action
  removeImage() {
    this.clearFileInput();
    this.file = null;
    this.args.lookAndFeelSettings.brandIdentityLogoId = null;
    this.args.lookAndFeelSettings.brandIdentityLogoUrl = null;
  }

  @action
  showFilePicker() {
    if (taskFor(this.uploadLogo).isRunning) {
      return;
    }
    this.pickFile();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Greeting::BrandIdentityLogoUpload': typeof BrandIdentityLogoUpload;
    'messenger-settings/conversational/greeting/brand-identity-logo-upload': typeof BrandIdentityLogoUpload;
  }
}
