/* import __COLOCATED_TEMPLATE__ from './launcher.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { EXTRA_USER_ATTRIBUTES } from 'embercom/lib/messenger/installation-constants';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';
// @ts-ignore
import { copy } from 'ember-copy';

type Args = {
  model: $TSFixMe;
  isMobilePlatform: boolean;
  activeBrand: $TSFixMe;
};

export default class Launcher extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;

  get app() {
    return this.appService.app;
  }

  get visibilitySettings() {
    return this.args.model.visibility;
  }

  get userAttributes() {
    return this.attributeService.filterableUserAttributesMaybeWithSegmentsAndTags.filter(
      (attribute: any) => !attribute.isUntrackedCda,
    );
  }

  get companyAttributes() {
    return this.attributeService.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags;
  }

  get messengerConditionalDisplayAttributes() {
    return this.attributeService.messengerConditionalDisplayAttributes;
  }

  get attributeGroupListForUsers() {
    return [
      {
        heading: this.intl.t('messenger.predicates.user-data'),
        attributes: [
          ...this.messengerConditionalDisplayAttributes.filter((el: any) => {
            return this.args.isMobilePlatform
              ? false
              : EXTRA_USER_ATTRIBUTES.includes(el.get('name'));
          }),
          ...this.userAttributes,
        ],
      },
      {
        heading: this.intl.t('messenger.predicates.company-data'),
        attributes: this.companyAttributes,
      },
    ];
  }

  get attributeGroupListForVisitors() {
    return [
      {
        heading: this.intl.t('messenger.predicates.person-data'),
        attributes: this.messengerConditionalDisplayAttributes,
      },
    ];
  }

  @action
  updateUserPredicates(predicates: any) {
    this.visibilitySettings.launcherPredicatesForUsers.clear();
    let newPredicates = copy(predicates);
    this.visibilitySettings.launcherPredicatesForUsers.pushObjects(newPredicates);
  }

  @action
  updateVisitorPredicates(predicates: any) {
    this.visibilitySettings.launcherPredicatesForAnonymous.clear();
    let newPredicates = copy(predicates);
    this.visibilitySettings.launcherPredicatesForAnonymous.pushObjects(newPredicates);
  }

  @action
  toggleMessengerEnabledForVisitors() {
    this.visibilitySettings.messengerEnabledForVisitors =
      !this.visibilitySettings.messengerEnabledForVisitors;

    this.messengerSettingsTargetUserProvider.setVisitorTarget();
  }

  @action
  toggleMessengerEnabledForUsers() {
    this.visibilitySettings.messengerEnabledForUsers =
      !this.visibilitySettings.messengerEnabledForUsers;

    this.messengerSettingsTargetUserProvider.setUserTarget();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Content::Launcher': typeof Launcher;
    'messenger-settings/conversational/content/launcher': typeof Launcher;
  }
}
