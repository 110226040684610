/* import __COLOCATED_TEMPLATE__ from './privacy-policy-notice-composer.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import {
  BaseConfig,
  BlocksDocument,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import type PrivacyPolicyNoticeModel from 'embercom/models/messenger-settings/privacy-policy-notice';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { next } from '@ember/runloop';
import { INLINE_CONTROL_BLOCK_TYPES } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

export const MAX_CHARACTER_COUNT = 200;

class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = ['paragraph'];
  tools = [{ name: 'text-formatter' }, { name: 'anchor-editor' }];
  allowedInline = ['bold', 'italic', 'anchor'];
  allowTextAlignment = false;
  singleBlockMode = true;
  maxCharacterCount = MAX_CHARACTER_COUNT;
  hideFromTextFormatter = [INLINE_CONTROL_BLOCK_TYPES];
  placeholder = '';
}

type Locale = {
  localeId: string;
  name: string;
};

interface Args {
  locale: Locale;
  privacyPolicyNotice: PrivacyPolicyNoticeModel;
}

export default class PrivacyPolicyNoticeComposer extends Component<Args> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked blocksDoc: BlocksDocument;
  @tracked composerApi?: ComposerPublicAPI;
  @tracked remainingCharacters = MAX_CHARACTER_COUNT;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.serializedBlocks);
    this.remainingCharacters = this.calculateRemainingCharacters(this.blocksDoc);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    return new ComposerConfig();
  }

  get placeholder() {
    return this.intl.t('messenger.greeting.greeting-placeholder');
  }

  get locale() {
    return this.args.locale;
  }

  get privacyPolicyNotice() {
    return this.args.privacyPolicyNotice;
  }

  get privacyPolicyNoticeContentForLocale() {
    if (!this.privacyPolicyNotice.contents) {
      return null;
    }

    return this.privacyPolicyNotice.contents.findBy('localeId', this.locale.localeId);
  }

  get serializedBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.privacyPolicyNoticeContentForLocale?.notice || '',
      },
    ];
  }

  calculateRemainingCharacters(blocksDoc: BlocksDocument) {
    let blockText = '';
    let firstBlock = blocksDoc.blocks.firstObject;

    if (firstBlock && firstBlock.type === 'paragraph') {
      blockText = firstBlock.text;
    }
    let textContent = htmlToTextContent(blockText);
    return MAX_CHARACTER_COUNT - textContent.length;
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    this.remainingCharacters = this.calculateRemainingCharacters(blocksDoc);
    this.privacyPolicyNotice.updateContents(this.locale.localeId, blocksDoc);
  }

  @action onReady(composerApi: ComposerPublicAPI) {
    this.composerApi = composerApi;

    next(this, () => {
      this.composerApi?.composer.commands.focus();
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::General::PrivacyPolicyNoticeComposer': typeof PrivacyPolicyNoticeComposer;
    'messenger-settings/general/privacy-policy-notice-composer': typeof PrivacyPolicyNoticeComposer;
  }
}
