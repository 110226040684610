/* import __COLOCATED_TEMPLATE__ from './show-launcher.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import { EXTRA_USER_ATTRIBUTES } from 'embercom/lib/messenger/installation-constants';

export default class ShowLauncherSettingsComponent extends Component {
  @service attributeService;
  @service messengerSettingsTargetUserProvider;
  @service intl;

  get isUserType() {
    return this.messengerSettingsTargetUserProvider.isUserType;
  }

  get userAttributes() {
    return this.attributeService.filterableUserAttributesMaybeWithSegmentsAndTags.filter(
      (attribute) => !attribute.isUntrackedCda,
    );
  }

  get companyAttributes() {
    return this.attributeService.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags;
  }

  get messengerConditionalDisplayAttributes() {
    return this.attributeService.messengerConditionalDisplayAttributes;
  }

  get attributeGroupList() {
    if (this.messengerSettingsTargetUserProvider.isUserType) {
      return this.attributeGroupListForUsers;
    }
    return this.attributeGroupListForVisitors;
  }

  get attributeGroupListForUsers() {
    return [
      {
        heading: this.intl.t('messenger.predicates.user-data'),
        attributes: [
          ...this.messengerConditionalDisplayAttributes.filter((el) => {
            return this.args.isMobilePlatform
              ? false
              : EXTRA_USER_ATTRIBUTES.includes(el.get('name'));
          }),
          ...this.userAttributes,
        ],
      },
      {
        heading: this.intl.t('messenger.predicates.company-data'),
        attributes: this.companyAttributes,
      },
    ];
  }

  get attributeGroupListForVisitors() {
    return [
      {
        heading: this.intl.t('messenger.predicates.person-data'),
        attributes: this.messengerConditionalDisplayAttributes,
      },
    ];
  }

  get messengerEnabled() {
    return this.args.visibilitySettings.messengerEnabled;
  }

  get launcherPredicates() {
    return this.args.visibilitySettings.launcherPredicates;
  }

  @action
  toggleMessengerEnabled() {
    this.args.visibilitySettings.messengerEnabled = !this.messengerEnabled;
  }

  @action
  updatePredicates(predicates) {
    this.args.visibilitySettings.launcherPredicates.clear();
    let newPredicates = copy(predicates);
    this.args.visibilitySettings.launcherPredicates.pushObjects(newPredicates);
  }
}
