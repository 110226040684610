/* import __COLOCATED_TEMPLATE__ from './predicate-diff.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { type PredicateDiff } from 'embercom/objects/matching-system/match-check';

interface Args {
  note?: string;
  title: string;
  predicateDiff: PredicateDiff;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class PredicateDiffComponent extends Component<Args> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::MatchChecks::PredicateDiff': typeof PredicateDiffComponent;
  }
}
