/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import SenderAssigneeDropdown from 'embercom/components/message-editor/content/sidebar/sender-assignee-dropdown';

export default SenderAssigneeDropdown.extend({
  appService: service(),
  app: readOnly('appService.app'),
  selected: computed(
    'showDynamicSender',
    'variation.{hasCustomFromAddress,dynamicSender,from}',
    function () {
      let variation = this.variation;

      if (this.showDynamicSender && variation.get('dynamicSender')) {
        return this.get('dynamicSenders.firstObject');
      }

      if (variation.get('hasCustomFromAddress')) {
        return variation.get('customFromAddress');
      }

      return variation.get('from');
    },
  ),
  teammatesWithLabel: computed('app.humanAdmins.[]', function () {
    return [{ isLabel: true, text: 'Teammates' }].concat(this.get('app.humanAdmins'));
  }),
  teams: readOnly('app.assignableTeams'),
  teamsWithLabel: computed('teams.[]', function () {
    if (this.get('teams.length')) {
      return [{ isLabel: true, text: 'Teams' }].concat(this.teams);
    } else {
      return [];
    }
  }),

  emailsWithLabel: computed('app.customEmailAddresses.[]', function () {
    if (this.get('app.verifiedCustomEmailAddresses.length')) {
      return [{ isLabel: true, text: 'Verified email addresses' }].concat(
        this.get('app.verifiedCustomEmailAddresses'),
      );
    } else {
      return [];
    }
  }),
  items: computed('emails.[]', 'teammatesWithLabel.[]', 'teamsWithLabel.[]', function () {
    if (
      this.get('variation.message.isVisitorAutoMessage') ||
      this.get('variation.message.isInApp')
    ) {
      return [].concat(this.dynamicSendersWithLabel, this.teammatesWithLabel, this.teamsWithLabel);
    } else if (this.get('variation.message.isEmail')) {
      return [].concat(this.dynamicSendersWithLabel, this.teammatesWithLabel, this.emailsWithLabel);
    } else if (
      this.get('variation.message.isAutoMessage') ||
      this.get('variation.showDynamicSender')
    ) {
      return [].concat(this.dynamicSendersWithLabel, this.teammatesWithLabel);
    } else {
      return this.teammatesWithLabel;
    }
  }),
});
