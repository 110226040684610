/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import BaseContentComponent from 'embercom/components/settings/content-components/base';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

const TRANSLATION_KEYS = {
  archive: {
    user: 'components.modal.user-or-company-deletion.bulk-archive-base-component.archive-user',
    lead: 'components.modal.user-or-company-deletion.bulk-archive-base-component.archive-lead',
    company:
      'components.modal.user-or-company-deletion.bulk-archive-base-component.archive-company',
  },
  archived: {
    user: 'components.modal.user-or-company-deletion.bulk-archive-base-component.archived-user',
    lead: 'components.modal.user-or-company-deletion.bulk-archive-base-component.archived-lead',
    company:
      'components.modal.user-or-company-deletion.bulk-archive-base-component.archived-company',
  },
  archiveError: {
    user: 'components.modal.user-or-company-deletion.bulk-archive-base-component.user-archive-error',
    lead: 'components.modal.user-or-company-deletion.bulk-archive-base-component.lead-archive-error',
    company:
      'components.modal.user-or-company-deletion.bulk-archive-base-component.company-archive-error',
  },
};

export default BaseContentComponent.extend({
  notificationsService: service(),
  intl: service(),
  pluralizedEntity: '',
  entityString: '',
  modalTitle: computed('intl.locale', 'selected.[]', 'totalCount', 'entity', function () {
    let selectedCount = (this.selected || []).length;
    let countToUse = selectedCount > 0 ? selectedCount : this.totalCount;
    if (this.entityString && TRANSLATION_KEYS['archive'][this.entityString]) {
      return this.intl.t(TRANSLATION_KEYS['archive'][this.entityString], {
        count: countToUse,
      });
    } else {
      return this.intl.t(
        'components.modal.user-or-company-deletion.bulk-archive-base-component.archive-default',
        { count: countToUse },
      );
    }
  }),
  trackAnalytics(count) {
    // no-op
  },
  archive: task(function* () {
    let selectedEntities = this.selected;
    let count = selectedEntities.length || this.totalCount;

    this.trackAnalytics(count);

    try {
      yield ajax({
        url: `/ember/${this.pluralizedEntity}/bulk_delete.json`,
        type: 'POST',
        data: JSON.stringify({
          included_ids: selectedEntities.map((entity) => entity.get('id')),
          excluded_ids: [],
          app_id: this.get('app.id'),
          predicates: this.filteredPredicates,
          count,
        }),
      });
      this.notificationsService.notifyConfirmation(
        this.entityString && TRANSLATION_KEYS['archived'][this.entityString]
          ? this.intl.t(TRANSLATION_KEYS['archived'][this.entityString], { count })
          : this.intl.t(
              'components.modal.user-or-company-deletion.bulk-archive-base-component.archived-default',
            ),
      );
      this.reload();
    } catch (error) {
      this.notificationsService.notifyError(
        this.entityString && TRANSLATION_KEYS['archiveError'][this.entityString]
          ? this.intl.t(TRANSLATION_KEYS['archiveError'][this.entityString], { count })
          : this.intl.t(
              'components.modal.user-or-company-deletion.bulk-archive-base-component.default-archive-error',
              { count },
            ),
      );
    } finally {
      this.closeModal();
    }
  }).drop(),
  filteredPredicates: computed('selected.[]', 'predicates.[]', function () {
    return this.selected.length > 0 ? [] : this.predicates;
  }),
});
