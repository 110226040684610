/* import __COLOCATED_TEMPLATE__ from './prosemirror-composer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph', 'heading'];
  allowedInline = [];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor({ placeholder, resolver }) {
    super();

    this.placeholder = placeholder;
    this.templating = { picker: 'common/attribute-picker', resolver };
  }
}

export default class MobilePushProsemirrorComposer extends Component {
  bodyBlocksDoc;
  titleBlocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service store;

  constructor() {
    super(...arguments);
    this.bodyBlocksDoc = new BlocksDocument(this.serializedBodyBlocks);
    this.titleBlocksDoc = new BlocksDocument(this.serializedTitleBlocks);
  }

  get app() {
    return this.appService.app;
  }

  get bodyComposerConfig() {
    return new ComposerConfig({ placeholder: 'Your message...', resolver: this.resolver });
  }

  get titleComposerConfig() {
    return new ComposerConfig({ placeholder: 'Your title...', resolver: this.resolver });
  }

  get emptyBody() {
    return this.serializedBodyBlocks.length === 1 && this.serializedBodyBlocks[0].text === '';
  }

  get emptyTitle() {
    return this.serializedTitleBlocks.length === 1 && this.serializedTitleBlocks[0].text === '';
  }

  get localizedPushContent() {
    return this.args.push?.localizedPushContents?.firstObject;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get serializedBodyBlocks() {
    return this.localizedPushContent?.bodyBlocks?.serialize();
  }

  get serializedTitleBlocks() {
    return this.localizedPushContent?.titleBlocks?.serialize();
  }

  get contentImageUrl() {
    return this.localizedPushContent?.contentImageUrl;
  }

  @action updateBodyBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedPushContent.bodyBlocks = blockFragments;
  }

  @action updateTitleBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedPushContent.titleBlocks = blockFragments;
  }
}
