/* import __COLOCATED_TEMPLATE__ from './send-frequency-limit.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { copy } from 'ember-copy';

export const SECONDS_IN = {
  second: 1,
  minute: 60,
  hour: 3600,
  day: 3600 * 24,
  week: 3600 * 24 * 7,
  month: 3600 * 24 * 30,
  year: 3600 * 24 * 365,
};

export const TIME_UNITS = ['hour', 'day', 'week', 'month', 'year'] as const;

interface Args {
  spacingPeriod: number;
  setSpacingPeriod: (periodInSeconds: number) => void;
  hasRulesetTrigger: boolean;
}

interface Signature {
  Args: Args;
}

export default class SendFrequencyLimit extends Component<Signature> {
  @service declare appService: any;
  @tracked selectedDuration = 1;
  @tracked selectedUnit: keyof typeof SECONDS_IN = 'day';

  constructor(owner: any, args: Args) {
    super(owner, args);

    if (this.args.spacingPeriod === -1) {
      this.selectedDuration = 1;
      this.selectedUnit = 'day';
    } else {
      this.initializeSelectionFromSpacingPeriod();
    }
  }

  get canUseReducedMatchSpacingPeriod() {
    return this.appService.app?.canUseReducedMatchSpacingPeriod;
  }

  get timeUnits() {
    if (this.args.hasRulesetTrigger) {
      let units = this.canUseReducedMatchSpacingPeriod ? ['second', 'minute'] : ['minute'];
      return units.concat(TIME_UNITS);
    } else {
      return ['day', 'week'];
    }
  }

  initializeSelectionFromSpacingPeriod() {
    let seconds = this.args.spacingPeriod;
    let timeUnitsDescending: (keyof typeof SECONDS_IN)[] = copy(this.timeUnits).reverse();

    // Convert seconds into the largest unit of time that it divides evenly by
    // e.g. 86400 seconds would convert to `1 day` instead of `24 hours`
    for (let unit of timeUnitsDescending) {
      let conversion = SECONDS_IN[`${unit}`];
      if (seconds % conversion === 0) {
        this.selectedDuration = seconds / conversion;
        this.selectedUnit = unit;
        break;
      }
    }
  }

  @action onSelectUnit(unit: keyof typeof SECONDS_IN) {
    this.selectedUnit = unit;
    if (this.selectedUnit === 'second' && this.selectedDuration < 5) {
      this.selectedDuration = 5;
    }
    this.setSpacingPeriod();
  }

  @action setSpacingPeriod() {
    let inSeconds = this.selectedDuration * SECONDS_IN[`${this.selectedUnit}`];
    this.args.setSpacingPeriod(inSeconds);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::AudienceSelector::OverMessaging::SendFrequencyLimit': typeof SendFrequencyLimit;
  }
}
