/* import __COLOCATED_TEMPLATE__ from './identity-verification-web.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default class IdentityVerificationWeb extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;

  @tracked pingDomains;
  @tracked openSectionId = null;
  @tracked showUserHashCheckerModal = false;
  @tracked secret = this.app.api_secret;
  @tracked showIdentityVerificationConfirmationModal = false;
  @tracked showIdentityVerificationDisablingModal = false;
  @tracked showSecret = false;

  constructor() {
    super(...arguments);
    this.pingDomains = this.store.query('ping-domain', { ignore_anonymous: true });
  }

  get enforcementDisabled() {
    return this.app.identityVerified === false && this.pingDomains.isEvery('validUserHash', false);
  }

  get isIdvEnabled() {
    return this.app.identityVerified;
  }

  get openSection() {
    if (this.openSectionId) {
      return this.openSectionId;
    } else if (!this.enforcementDisabled) {
      return 'finish-setup-and-enforce';
    } else if (
      this.pingDomains.any((pingDomain) => pingDomain.errorCode === 'user_hash_is_missing')
    ) {
      return 'add-idv-to-application';
    } else if (this.pingDomains.any((pingDomain) => pingDomain.errorCode !== null)) {
      return 'test-the-installation';
    } else {
      return 'add-idv-to-application';
    }
  }

  get app() {
    return this.appService.app;
  }

  get maskedSecret() {
    if (this.secret) {
      // Leave up to the last 4 characters visible
      return this.secret.replace(/.{4}(?=.)/g, '•');
    } else {
      return '';
    }
  }

  get stampText() {
    if (this.app.identityVerified) {
      return this.intl.t(
        'components.settings.identity-verification.identity-verification-web-component.stamp-text.on',
      );
    } else if (this.enforcementDisabled) {
      return this.intl.t(
        'components.settings.identity-verification.identity-verification-web-component.stamp-text.requires-setup',
      );
    } else {
      return this.intl.t(
        'components.settings.identity-verification.identity-verification-web-component.stamp-text.off',
      );
    }
  }

  @action
  updateSecret(newSecret) {
    this.secret = newSecret;
  }

  get stampColor() {
    if (this.app.identityVerified) {
      return 'green';
    } else if (this.enforcementDisabled) {
      return 'red';
    } else {
      return 'yellow';
    }
  }

  @action onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  @action openUserHashChecker() {
    this.showUserHashCheckerModal = true;
  }

  @action closeUserHashChecker() {
    this.showUserHashCheckerModal = false;
  }

  @action toggleIdentityVerification() {
    if (this.isIdvEnabled === true) {
      this.showIdentityVerificationDisablingModal = true;
    } else {
      this.showIdentityVerificationConfirmationModal = true;
    }
  }

  @action confirmIdentityVerificationEnforcement() {
    ajax({
      url: `/ember/apps/${this.app.id}/update_identity_verification`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        identity_verified: true,
      }),
    });
    this.intercomEventService.trackEvent(`secure-mode-enabled`, {
      action: 'enabled',
      object: 'secure_mode',
      place: `web_settings`,
      owner: 'growth',
    });
    this.app.identityVerified = true;
    this.showIdentityVerificationConfirmationModal = false;
  }

  @action confirmIdentityVerificationDisabling() {
    ajax({
      url: `/ember/apps/${this.app.id}/update_identity_verification`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        identity_verified: false,
      }),
    });
    this.intercomEventService.trackEvent(`secure-mode-disabled`, {
      action: 'disabled',
      object: 'secure_mode',
      place: `web_settings`,
      owner: 'growth',
    });
    this.app.identityVerified = false;
    this.showIdentityVerificationDisablingModal = false;
  }
}
