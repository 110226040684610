/* import __COLOCATED_TEMPLATE__ from './logo-upload-image-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
export default class LogoUploadImageComponent extends Component {
  @service router;

  get headerBackgroundImageStyle() {
    return `background-image: url(${this.args.lookAndFeelSettings.activeBrand.backgroundImageUrl})`;
  }

  get isEditingMessenger() {
    return this.router.currentRouteName?.match(RouteRegexes.newSettings.messenger.root);
  }

  get headerStyle() {
    if (this.args.lookAndFeelSettings.activeBrand.showBackgroundImage) {
      return sanitizeHtml(this.headerBackgroundImageStyle);
    } else {
      return '';
    }
  }
}
