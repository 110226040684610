/* import __COLOCATED_TEMPLATE__ from './facepile.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  firstTeammate: $TSFixMe;
  secondTeammate: $TSFixMe;
}

interface Signature {
  Args: Args;
}

const Facepile = templateOnlyComponent<Signature>();
export default Facepile;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::Web::Facepile': typeof Facepile;
  }
}
