/* import __COLOCATED_TEMPLATE__ from './user-role-multi-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import UserRoleSelector from 'embercom/components/matching-system/audience-selector/user-role-selector';
import { action, computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { pluralize } from 'ember-inflector';
import toSentence from 'embercom/lib/to-sentence';
import { capitalize } from '@ember/string';

export default UserRoleSelector.extend({
  init() {
    this._super(...arguments);
    if (!this.isDisabled) {
      this.set('newlySelected', this.selected);
    }
  },

  hasOnlyOneUserRole: equal('dropdownItems.length', 1),

  currentSelectionDescription: computed('selected', 'newlySelected', 'items.[]', function () {
    let selected = this.newlySelected || this.selected;
    if (isPresent(selected)) {
      let names = selected.map((roleName) => capitalize(pluralize(roleName)));
      return toSentence(names);
    } else {
      return toSentence(
        this.items.filter((item) => !item.isDisabled).map((item) => item.text),
        'or',
      );
    }
  }),

  items: computed(
    'selected',
    'dropdownItems',
    'isMobileFirstMessage',
    'hasPushNotification',
    function () {
      let dropdownItems = this.dropdownItems.map((item) => ({
        text: item.text,
        value: item.value,
        isSelected: this.selected.includes(item.value),
        isDisabled: false,
      }));

      if (this.isMobileFirstMessage || this.hasPushNotification) {
        this._disableItem('visitor', dropdownItems);
        this._disableItem('lead', dropdownItems);
      }

      return dropdownItems;
    },
  ),

  updateNewlySelected: action(function (selectedItems) {
    if (selectedItems.length > 0) {
      this.set('newlySelected', selectedItems);
      this.onSelect(selectedItems);
    }
  }),

  _disableItem(value, dropdownItems) {
    let item = dropdownItems.filter((item) => item.value === value)[0];
    if (item) {
      item.isDisabled = true;
      if (this.isMobileFirstMessage) {
        item.tooltipText = `To send messages to ${item.value}s, change the delivery channel to Web in step 1 above.`;
      } else if (this.hasPushNotification) {
        item.tooltipText = `To send messages to ${item.value}s, remove the push notification in step 1 above.`;
      }
    }
  },

  onCloseSelector: action(function () {
    if (!this.isDestroying) {
      this.onSelect(this.newlySelected);
    }
  }),
});
