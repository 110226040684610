/* import __COLOCATED_TEMPLATE__ from './chrome.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import moment from 'moment-timezone';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  appService: service(),

  init() {
    this._super(...arguments);
    this.clockTick.perform();
  },

  clockTick: task(function* () {
    this.set('time', moment().format('h:mm'));
    // We don't need to run the clock during tests
    if (ENV.environment !== 'test') {
      yield timeout(ENV.APP._1000MS);
      this.clockTick.perform();
    }
  }).enqueue(),
});
