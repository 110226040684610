/* import __COLOCATED_TEMPLATE__ from './left-side-nav.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

type LeftSideNavArgs = {
  model: $TSFixMe;
};

export default class LeftSideNav extends Component<LeftSideNavArgs> {
  @service declare appService: $TSFixMe;
  @service declare MessengerSettingsService: MessengerSettingsService;

  get model() {
    return this.args.model.settings;
  }

  get isConversationalMessenger() {
    return this.appService.app.isOptedIntoConversationalMessengerBeta;
  }

  get isConversationalRouteActive() {
    return this.MessengerSettingsService.isConversationalMessengerRoute;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::LeftSideNav': typeof LeftSideNav;
    'messenger-settings/left-side-nav': typeof LeftSideNav;
  }
}
