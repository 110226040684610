/* import __COLOCATED_TEMPLATE__ from './greeting.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Main extends Component {
  @service messengerSettingsTargetUserProvider;

  get languageSettings() {
    return this.args.languageSettings;
  }

  get compactEnabled() {
    return this.args.selectedLayout === 'compact';
  }

  get permittedLocales() {
    let defaultLocale = this.languageSettings.supportedLocales.findBy(
      'localeId',
      this.languageSettings.defaultLocale,
    );

    let otherPermittedLocales = this.languageSettings.supportedLocales.filter(
      (locale) => locale.isPermitted,
    );
    return [defaultLocale, ...otherPermittedLocales];
  }
}
