/* import __COLOCATED_TEMPLATE__ from './disable-space-modal.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class DisableSpaceModal extends Component {
  @service intl;

  get title() {
    return this.intlTextFor('title');
  }

  get description() {
    return this.intlTextFor('description');
  }

  get label() {
    return this.intlTextFor('label');
  }

  get space() {
    return this.args.space;
  }

  get spaceName() {
    return this.space?.name;
  }

  get spaceAppName() {
    if (this.space?.isNews) {
      return this.intl.t('messenger.add-apps.news.title');
    } else if (this.space?.isTasks) {
      return this.intl.t('messenger.add-apps.tasks.title');
    }

    return this.intl.t('messenger.add-apps.article-search.title');
  }

  get intlOptions() {
    return {
      spaceName: this.spaceName,
      appName: this.spaceAppName,
    };
  }

  intlTextFor(key) {
    let text = this.intl.t(
      `messenger.settings-layout.spaces.disable-modal.${key}`,
      this.intlOptions,
    );
    if (this.space?.isTickets) {
      text = this.intl.t(
        `messenger.settings-layout.spaces.disable-modal.tickets.${key}`,
        this.intlOptions,
      );
    }
    return text;
  }
}
