/* import __COLOCATED_TEMPLATE__ from './styling.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import isValidUrl from 'embercom/lib/url-validator';
import {
  getDefaultColors,
  sanitizedDomainLink,
} from 'embercom/lib/onboarding/brand-fetch-functions';

export default class Styling extends Component {
  @service intl;
  @service appService;
  @service notificationsService;
  @service MessengerSettingsService;
  @service('messenger-settings-service') settingsService;
  @tracked showDeleteBrandModal = false;
  @tracked brandFetchUrl = '';
  @tracked brandInfo = null;
  @tracked showBrandSelectionModal = false;
  @tracked selectedActionColor;
  @tracked selectedBackgroundColor = this?.brandInfo?.brand_colors?.[0] || '';
  @tracked selectBrandLogo = true;
  @tracked isInvalidURL = false;
  @tracked invalidURLErrorMessage = '';
  constructor(owner, args) {
    super(owner, args);

    if (this.isMobile) {
      this.args.model.lookAndFeel.activeBrand = this.args.model.lookAndFeel.defaultMobileBrand;
    } else {
      this.args.model.lookAndFeel.activeBrand = this.args.model.lookAndFeel.defaultBrand;
    }
  }

  get activeBrand() {
    return this.args.model.lookAndFeel.activeBrand;
  }

  get lookAndFeelSettings() {
    return this.args.model.lookAndFeel;
  }

  get isMobile() {
    return this.args.isMobilePlatform;
  }

  get app() {
    return this.appService.app;
  }

  get isHubEnabled() {
    return (
      this.args.model.spaces.activePreviewSpace?.isHub ||
      this.args.model.form.selectedForm === 'compact'
    );
  }

  get brandInfoColors() {
    if (this.brandInfo === null) {
      return [];
    }

    return [
      {
        items: this.brandInfo.brand_colors.map((c) => {
          return {
            text: c,
            value: c,
            component: 'messenger-settings/styling/brand-color-item',
          };
        }),
      },
    ];
  }

  get isAllowedBrandFetch() {
    return this.brandFetchUrl !== '';
  }

  get headerStyle() {
    return `background-color: ${this.selectedBackgroundColor}`;
  }

  @action
  setBrand(brand) {
    this.args.model.lookAndFeel.activeBrand = brand;
  }

  @action selectActionColor(color) {
    this.selectedActionColor = color.text;
  }

  @action selectBackgroundColor(color) {
    this.selectedBackgroundColor = color.text;
  }

  @action
  toggleLogoSelection() {
    this.selectBrandLogo = !this.selectBrandLogo;
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'customize_messenger_with_brand.toggle',
      place: 'messenger_settings.use_logo_toggle',
      section: 'messenger',
      context: `toggle_logo_selection.${this.selectBrandLogo}`,
    });
  }

  @action
  saveBrandInfoFromFetch() {
    this.showBrandSelectionModal = false;
    let instrumentationContext = null;
    let addToInstrumentationContext = (section) => {
      instrumentationContext += `${instrumentationContext ? '-' : ''}${section}`;
    };
    if (this.selectBrandLogo) {
      this.uploadLogo.perform(this.brandInfo.logo_base64);
      addToInstrumentationContext('logo');
    }

    if (this.selectedActionColor) {
      this.lookAndFeelSettings.primaryColor = this.selectedActionColor;

      if (this.activeBrand) {
        this.activeBrand.messengerActionColor = this.selectedActionColor;
      }
      addToInstrumentationContext('action');
    }

    if (this.selectedBackgroundColor) {
      this.lookAndFeelSettings.secondaryColor = this.selectedBackgroundColor;

      if (this.activeBrand) {
        this.activeBrand.messengerBackgroundColor = this.selectedBackgroundColor;
      }
      addToInstrumentationContext('background');
    }
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'selected',
      object: 'customize_messenger_with_brand.selection',
      place: 'messenger_settings.brand_selection_modal',
      section: 'messenger',
      context: instrumentationContext,
    });
  }

  @action
  closeBrandSelectionModal() {
    this.showBrandSelectionModal = false;
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'closed',
      object: 'messenger_brand_modal',
      place: 'messenger_settings',
      section: 'messenger',
    });
  }

  @task *uploadLogo(fileData) {
    yield post(`/ember/messenger_settings/upload_logo/${this.app.id}`, {
      file_data: fileData,
      filename: 'logo.png',
      content_type: 'image/png',
    })
      .then((response) => {
        this.handleUploadSuccess(response);
      })
      .catch((error) => {});
  }

  handleUploadSuccess(response) {
    this.activeBrand.messengerLogoUrl = response.url;
    this.activeBrand.messengerLogoId = response.id;

    if (this.activeBrand.isDefault) {
      this.lookAndFeelSettings.logoUrl = response.url;
      this.lookAndFeelSettings.logoId = response.id;
    }
  }

  setDefaultColors(colors_arr, theme) {
    let colors = getDefaultColors(colors_arr, theme);
    this.selectedActionColor = colors.actionColor;
    this.selectedBackgroundColor = colors.backgroundColor;
  }

  urlIsFromSameDomain() {
    if (!this.brandInfo) {
      return false;
    }
    let newURL = new URL(sanitizedDomainLink(this.brandFetchUrl));
    let resultURL = new URL(this.brandInfo.website_url);
    return newURL.hostname === resultURL.hostname;
  }

  @task
  *deleteActiveBrand() {
    try {
      yield this.activeBrand.destroyRecord();
      this.showDeleteBrandModal = false;
      let defaultBrand = this.isMobile
        ? this.lookAndFeelSettings.defaultMobileBrand
        : this.lookAndFeelSettings.defaultBrand;
      this.setBrand(defaultBrand);
      this.MessengerSettingsService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'messenger_brand',
        place: 'messenger_settings',
        section: 'messenger',
        metadata: {
          brand_id: this.activeBrand.id,
        },
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.settings-layout.styling.brands.delete.confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('messenger.settings-layout.styling.brands.delete.error'),
      );
    }
  }

  @task
  *fetchBrandInfo() {
    this.settingsService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'customize_messenger_with_brand.import_cta',
      place: 'messenger_settings',
      section: 'messenger',
      context: 'brand_scrapping.import_cta_clicked',
      url: this.brandFetchUrl,
    });
    this.isInvalidURL = !isValidUrl(this.brandFetchUrl);
    if (this.isInvalidURL) {
      this.invalidURLErrorMessage =
        'messenger.settings-layout.styling.fetch-brand.error-message.not-found';
      this.MessengerSettingsService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'customize_messenger_with_brand.error_message',
        place: 'messenger_settings',
        section: 'messenger',
        context: 'brand_scrapping.invalid',
        invalid_url: this.brandFetchUrl,
      });
    } else {
      let url = sanitizedDomainLink(this.brandFetchUrl);
      if (this.urlIsFromSameDomain()) {
        this.showBrandSelectionModal = true;
        return;
      }
      this.brandInfo = null;
      yield post(`/ember/onboarding/website_scraper`, {
        website_url: url,
        app_id: this.app.id,
      })
        .then((response) => {
          if (!response.brand_colors) {
            this.isInvalidURL = true;
            this.invalidURLErrorMessage =
              'messenger.settings-layout.styling.fetch-brand.error-message.details-not-found';
            this.MessengerSettingsService.trackAnalyticsEvent({
              action: 'failed',
              object: 'customize_messenger_with_brand.error_message',
              place: 'messenger_settings',
              section: 'messenger',
              context: 'brand_scrapping.return_unavailable',
              invalid_url: this.brandFetchUrl,
            });
            return;
          }
          this.brandInfo = { ...response, website_url: url };
          this.setDefaultColors(response.brand_colors, response.theme);
          this.MessengerSettingsService.trackAnalyticsEvent({
            action: 'viewed',
            object: 'customize_messenger_with_brand',
            place: 'messenger_settings',
            section: 'messenger',
            context: 'brand_scrapping.success',
          });
          this.selectBrandLogo = true;
          this.showBrandSelectionModal = true;
        })
        .catch((e) => {
          this.MessengerSettingsService.trackAnalyticsEvent({
            action: 'failed',
            object: 'customize_messenger_with_brand.error_message',
            place: 'messenger_settings',
            section: 'messenger',
            context: 'brand_scrapping.return_invalid',
            invalid_url: this.brandFetchUrl,
            response: e.jqXHR.responseJSON.code,
          });
          if (['404', '400'].includes(e.jqXHR.responseJSON.code)) {
            this.invalidURLErrorMessage =
              'messenger.settings-layout.styling.fetch-brand.error-message.not-found';
          } else {
            this.invalidURLErrorMessage =
              'messenger.settings-layout.styling.fetch-brand.error-message.internal-issue';
          }
          this.isInvalidURL = true;
          console.error(e);
        });
    }
  }
}
