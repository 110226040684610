/* import __COLOCATED_TEMPLATE__ from './home-screen-compact.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { inject as service } from '@ember/service';

export default class HomeScreenCompact extends Component {
  @service appService;
  get greetingText() {
    let greetingText = this.args.configuration.greetingText || '';
    let previewHtml = renderPreview(greetingText, {});
    return htmlToTextContent(previewHtml);
  }

  get initials() {
    return this.args.configuration.m5sampleTeammates
      .map((teammate) => {
        let initials = teammate.avatar?.initials;
        return initials && initials.length > 0 ? initials.charAt(0).toUpperCase() : null;
      })
      .slice(0, 1); // Take only first because of styling difficulties (TO DO!)
  }

  get slotGroup() {
    return this.args.configuration.slotGroups.findBy('targetUserType', this.args.targetUserType);
  }

  get slotsForDisplay() {
    let slots = this.slotGroup?.slots;
    if (!slots) {
      return [];
    }
    return slots;
  }

  get searchBrowseRequired() {
    let { requireSearchBrowseForVisitors, requireSearchBrowseForUsers } =
      this.args.configuration.home;
    return this.args.targetUserType === 'leads_and_visitors'
      ? requireSearchBrowseForVisitors
      : requireSearchBrowseForUsers;
  }

  get showContinueConversationCard() {
    return (
      this.args.inboundConversationsDisabled || this.args.preventMultipleInboundConversationsEnabled
    );
  }

  get canShowNewConversationCard() {
    let inboundConversationsEnabled = !this.args.inboundConversationsDisabled;
    let preventMultipleInboundConversationsDisabled =
      !this.args.preventMultipleInboundConversationsEnabled;

    return inboundConversationsEnabled && preventMultipleInboundConversationsDisabled;
  }

  get showNewConversationCardInSlot() {
    if (!this.canShowNewConversationCard) {
      return false;
    }
    return !(this.slotGroup.hasHelpCenterSlot && this.searchBrowseRequired);
  }

  get spacesItems() {
    return this.args.spaces?.spacesForPreview
      ?.filter((space) => space.type !== 'home')
      .map((space) => {
        let isActive = space.type === this.args.activeSpaceType;
        return {
          iconPath: space.previewCompactIconPath(),
          title: space.messengerTitleKey,
          isActive,
        };
      });
  }
}
