/* import __COLOCATED_TEMPLATE__ from './external-links-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ExternalLinksSideDrawer extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;
  @tracked showSideDrawer = false;
  @tracked showErrors = false;

  constructor() {
    super(...arguments);

    if (this.args.slot && this.args.slot.isNew && !this.args.slot.hasConfigured) {
      this.args.slot.hasConfigured = true;
      this.openSideDrawer();
    }
  }

  @action
  openSideDrawer() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: `external_links`,
      place: 'messenger_settings_external_links_side_drawer',
    });
    if (this.hasNoLinks) {
      this.addExternalLink();
    }
    this.showSideDrawer = true;
  }

  get hasErrors() {
    return !!this.externalLinks.toArray().some((link) => link.validations.isInvalid);
  }

  @action
  done() {
    if (this.hasErrors) {
      this.showErrors = true;
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'done',
        object: `external_links`,
        place: 'messenger_settings_external_links_side_drawer',
      });
      this.showErrors = false;
      this.showSideDrawer = false;
    }
  }

  @action
  cancel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'canceled',
      object: `external_links`,
      place: 'messenger_settings_external_links_side_drawer',
    });
    this.showSideDrawer = false;
    this.clearExternalLinks();
    if (this.args.slot.isNew) {
      this.args.removeSlot(this.args.slot);
    }
  }

  @action
  resetExternalLinkDisplayOrder(reorderedLinks) {
    this.args.slot.resetExternalLinkDisplayOrder(reorderedLinks);
  }

  @action
  addExternalLink() {
    this.args.slot.addExternalLink({ label: null, href: null });
  }

  @action
  removeExternalLink(link) {
    this.args.slot.removeExternalLink(link);
  }

  clearExternalLinks() {
    this.externalLinks.forEach((link) => link.rollbackAttributes());
  }

  get externalLinks() {
    return this.args.slot.externalLinks;
  }

  get hasNoLinks() {
    return this.externalLinks.length === 0;
  }

  get maximumLinksAdded() {
    return this.externalLinks.length >= 10;
  }

  get hasOnlyOneLink() {
    return this.externalLinks.length === 1;
  }

  get showingEmptyLink() {
    return this.hasOnlyOneLink && this.externalLinks.lastObject.validations.isInvalid;
  }

  get cancelButtonText() {
    return this.args.slot.isNew
      ? this.intl.t('messenger.add-apps.external-links.side-drawer-remove-app')
      : this.intl.t('messenger.add-apps.external-links.side-drawer-revert-changes');
  }
}
