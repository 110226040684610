/* import __COLOCATED_TEMPLATE__ from './color-pickers.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { tinycolor } from '@intercom/intercom-messenger-colors';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import { debounce } from '@ember/runloop';

export default class BackgroundColorPickers extends Component {
  @tracked showGradientThirdColor;
  @service MessengerSettingsService;

  colorOptions = DEFAULT_MESSENGER_COLORS;

  constructor(owner, args) {
    super(owner, args);

    if (!this.args.activeBrand.gradientFirstColor) {
      this.args.activeBrand.gradientFirstColor = tinycolor(
        this.args.configuration.appColors.gradient_start_color,
      ).toHexString();
    }
    if (!this.args.activeBrand.gradientSecondColor) {
      this.args.activeBrand.gradientSecondColor = tinycolor(
        this.args.configuration.appColors.gradient_end_color,
      ).toHexString();
    }
    this.showGradientThirdColor = !!this.args.activeBrand.gradientThirdColor;
  }

  get showAddGradientColor() {
    return !this.showGradientThirdColor;
  }

  @action
  updateGradientColorsInputs() {
    this.showGradientThirdColor = !!this.args.activeBrand.gradientThirdColor;
  }

  @action
  setGradientFirstColor(color) {
    this.args.activeBrand.gradientFirstColor = color;
    this.debouncedTrackColorChange('gradient_first_color', color);
  }

  @action
  setGradientSecondColor(color) {
    this.args.activeBrand.gradientSecondColor = color;
    this.debouncedTrackColorChange('gradient_second_color', color);
  }

  @action
  setGradientThirdColor(color) {
    this.args.activeBrand.gradientThirdColor = color;
    this.debouncedTrackColorChange('gradient_third_color', color);
  }

  debouncedTrackColorChange(object, color) {
    debounce(
      this.MessengerSettingsService,
      this.MessengerSettingsService.trackAnalyticsEvent,
      {
        action: 'changed',
        object,
        color,
      },
      500,
    );
  }

  @action
  addGradientColor() {
    if (!this.showGradientThirdColor) {
      let gradientThirdColor = tinycolor(this.args.activeBrand.gradientSecondColor);
      gradientThirdColor.spin(90);
      this.args.activeBrand.gradientThirdColor = gradientThirdColor.toHexString();
      this.showGradientThirdColor = true;
      return;
    }
  }

  @action
  removeThirdGradient() {
    this.args.activeBrand.gradientThirdColor = '';
    this.showGradientThirdColor = false;
  }
}
