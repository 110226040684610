/* import __COLOCATED_TEMPLATE__ from './date-offset-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { schedule } from '@ember/runloop';
import { isPresent } from '@ember/utils';

export default class DateOffsetEditor extends Component {
  @tracked openDropdown = false;

  get attributeSelectItems() {
    return [
      {
        items: [
          { icon: 'trigger', text: 'Before the date', value: -1 },
          { icon: 'trigger', text: 'On the date', value: 0 },
          { icon: 'trigger', text: 'After the date', value: 1 },
        ],
      },
    ];
  }

  get recurringSchedule() {
    return this.args.recurringSchedule;
  }

  get isDisabled() {
    return isPresent(this.recurringSchedule.offsetValue);
  }

  @action setDateAttributePredicate(value) {
    this.recurringSchedule.dayOffset = value;
    this.openDropdown = true;
  }

  @action openOffsetSelector() {
    if (this.args.isEditingDateOffset) {
      schedule('afterRender', () => {
        let offsetSelector = document.querySelector(
          '[data-recurring-schedule-date-offset-selection-list]',
        );

        if (offsetSelector) {
          offsetSelector.click();
        }
      });
    }
  }
}
