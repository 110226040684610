/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';

interface PreviewSignature {
  Element: any;
  Args: {
    languageOverride?: string;
    messengerType: 'classic' | 'conversational';
  };
}

export default class Preview extends Component<PreviewSignature> {
  @service declare appService: any;
  @service declare session: any;

  get appId() {
    return this.appService.app.id;
  }

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');
    // Add `&local_client=true` to the end of the URL to see changes to intercom-js code in the preview.
    let iframeUrl = `${window.location.origin}/hosted_messenger/${this.appId}/conversational_messenger_preview?preview_only=true&skip_matching=true`;
    iframeUrl += `&language_override=${this.args.languageOverride ?? 'en'}`;

    if (
      !this.appService.app.isOptedIntoConversationalMessengerBeta &&
      this.args.messengerType === 'conversational'
    ) {
      iframeUrl += '#intercom-force-conversational-form';
    }
    if (this.args.messengerType === 'classic') {
      iframeUrl += '#intercom-force-classic-form';
    }

    iframe.src = iframeUrl;
    iframe.setAttribute('id', 'hosted-messenger-conversational-messenger-preview');

    el.replaceChildren(iframe);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Preview: typeof Preview;
    'MessengerSettings::Conversational::Preview': typeof Preview;
  }
}
