/* import __COLOCATED_TEMPLATE__ from './predicates-group-editor.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
//@ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';

interface Args {
  predicateGroup: any;
  isDisabled?: boolean;
  attributeGroupList: Array<any>;
  attributePickerLabel?: string;
}

export default class PredicatesGroupEditor extends Component<Args> {
  @service declare intl: IntlService;
  logicalSwitcherItems: Array<{ text: string; value: string }>;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.logicalSwitcherItems = [
      {
        text: this.intl.t('messenger.predicates.comparison.and'),
        value: 'and',
      },
      {
        text: this.intl.t('messenger.predicates.comparison.or'),
        value: 'or',
      },
    ];
  }

  @action
  onUpdatePredicates(predicates: any[]) {
    this.args.predicateGroup.predicates = copy(predicates);
  }

  get attributePickerLabel() {
    return this.args.attributePickerLabel || this.intl.t('messenger.predicates.add-label');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Common::PredicatesGroupEditor': typeof PredicatesGroupEditor;
  }
}
