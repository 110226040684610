/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import { isPresent } from '@ember/utils';

export default class Preview extends Component {
  @service attributeService;

  get columns() {
    let columns = [
      {
        label: 'Name',
        icon: 'people',
        valuePath: 'displayAs',
      },
    ];
    columns = columns.concat(
      this.attributeService.displayedUserAttributes.map((displayedAttribute) => ({
        label: displayedAttribute.attribute.name,
        icon: displayedAttribute.attribute.icon,
        valuePath: displayedAttribute.attribute.identifier,
      })),
    );
    return columns;
  }

  get hasEditablePredicates() {
    return isPresent(this.args.ruleset.predicateGroup.editablePredicates);
  }

  get isEmptyState() {
    return (
      (!this.showSpinner && !this.args.ruleset.get('audiencePreview.hasAudience')) ||
      this.isTargetingVisitorsOnly
    );
  }

  get isLimitedAudiencePreview() {
    return this.args.ruleset.get('audiencePreview.limited');
  }

  get emptyStateMessageForUsers() {
    return `No ${pluralize(this.userTypeDescription)} match these rules yet`;
  }

  get emptyStateDescriptionForVisitors() {
    if (this.hasEditablePredicates) {
      return this.args.ruleset.isMemberOfSeries
        ? 'Improve the targeting by adding rules'
        : 'This will show to visitors whenever they match the audience rule';
    }
    let readableObjectName = this.args.ruleset.isMemberOfSeries
      ? 'series'
      : this.args.ruleset.rulesetLinks.firstObject.humanReadableObjectName;
    return `Once live, all visitors will be shown this ${readableObjectName}. Adding rules will improve your targeting.`;
  }

  get emptyStateMessageforVisitors() {
    if (this.args.ruleset.isMemberOfSeries) {
      if (this.hasEditablePredicates) {
        return 'Visitors who match the rules will enter this series';
      } else {
        return 'All visitors will enter this series';
      }
    } else if (this.hasEditablePredicates) {
      return `Visitors who match the rules will receive this ${this.args.ruleset.rulesetLinks.firstObject.humanReadableObjectName}`;
    } else {
      return `All visitors will receive this ${this.args.ruleset.rulesetLinks.firstObject.humanReadableObjectName}`;
    }
  }

  get isTargetingVisitorsOnly() {
    return this.args.ruleset.userType === 'visitor';
  }

  get showSpinner() {
    return this.args.ruleset.fetchAudiencePreviewTask.isRunning;
  }

  get showRemainderCount() {
    return (
      this.args.ruleset.get('audiencePreview.count') >
      this.args.ruleset.get('audiencePreview.users.length')
    );
  }

  get userTypeDescription() {
    let userTypeFromAudiencePreview =
      this.args.ruleset.get('audiencePreview.predicateGroup.userType') ||
      this.args.ruleset.get('audiencePreview.rolePredicateGroup.userType');
    let userType = userTypeFromAudiencePreview || this.args.ruleset.userType || 'user';
    if (userType === 'user') {
      return 'user';
    } else if (userType === 'lead') {
      return 'lead';
    }
    return 'person';
  }

  get rulesetHasTriggers() {
    if (!this.args.ruleset) {
      return false;
    }

    return this.args.ruleset.rulesetTriggers.length > 0;
  }

  get rulesetTriggerEventName() {
    return this.args.ruleset.rulesetTriggers.firstObject.eventName;
  }

  constructor() {
    super(...arguments);
    this.args.ruleset.fetchAudiencePreviewTask.perform(this.args.ignoredAttributeTypesForPreview);
  }
}
