/* import __COLOCATED_TEMPLATE__ from './actions-preview.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

export default class MacrosActionsPreviewComponent extends Component {
  get hasText() {
    let blocks = this.args.parentModel.blocks;
    return blocks && blocks.length > 0;
  }
}
