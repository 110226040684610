/* import __COLOCATED_TEMPLATE__ from './upfront-collection-form.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class UpfrontCollectionForm extends Component {
  @service attributeService;

  get collectionAttributes() {
    return this.attributeService.findByIdentifiers(this.args.attributeIdentifiers);
  }
}
