/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { isArray } from '@ember/array';
import { assert } from '@ember/debug';
import { bind, debounce, throttle } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import { not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import ENV from 'embercom/config/environment';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

const notImplementedError = 'You must add this property for the Paging Mixin to work';

export default Mixin.create({
  isLoadingNextPage: false,
  hasMoreItemsToShow: true,
  isNotLoadingNextPage: not('isLoadingNextPage'),
  scrollingContainerSelector: notImplemented(notImplementedError),
  listItemsContainerSelector: notImplemented(notImplementedError),
  nearPageBottomThreshold: notImplemented(notImplementedError),
  setupPaging: on('didInsertElement', function () {
    this.set('$pagingContainer', $(this.scrollingContainerSelector));
    this.set('$listItemsContainer', $(this.listItemsContainerSelector));
    this.$pagingContainer.on(
      `scroll.paging-${this.elementId}`,
      bind(this, this._throttleTryLoadNextPage),
    );
    this.$pagingContainer.on(
      `resize.paging-${this.elementId}`,
      bind(this, this._debounceTryLoadNextPage),
    );
    this._tryLoadNextPage();
  }),
  resetPaging() {
    this.set('hasMoreItemsToShow', true);
  },
  teardownPaging: on('willDestroyElement', function () {
    this.$pagingContainer.off(`scroll.paging-${this.elementId}`);
    this.$pagingContainer.off(`resize.paging-${this.elementId}`);
    this.set('$pagingContainer', null);
    this.set('$listItemsContainer', null);
  }),
  _debounceTryLoadNextPage() {
    debounce(this, this._tryLoadNextPage, ENV.APP._500MS, false);
  },
  _throttleTryLoadNextPage() {
    throttle(this, this._tryLoadNextPage, ENV.APP._500MS, false);
  },
  _tryLoadNextPage() {
    if (this._shouldLoadNextPage()) {
      this.set('isLoadingNextPage', true);
      this.loadNextPage()
        .then((items) => {
          assert(
            'The action "loadNextPage" must resolve with the array of loaded items',
            isArray(items),
          );
          this.setProperties({
            isLoadingNextPage: false,
            hasMoreItemsToShow: items.length > 0,
          });
        })
        .catch(() => {
          this.setProperties({ isLoadingNextPage: false });
        });
    }
  },
  _shouldLoadNextPage() {
    return this._isNearBottomOfPage() && this.isNotLoadingNextPage && this.hasMoreItemsToShow;
  },
  _isNearBottomOfPage() {
    let scrollTop = this.$pagingContainer.scrollTop();
    let innerHeight = window.innerHeight;
    let heightDifference = this.$listItemsContainer.height() - this.nearPageBottomThreshold;
    return scrollTop + innerHeight >= heightDifference;
  },
});
