/* import __COLOCATED_TEMPLATE__ from './sender-assignee-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { equalToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: 'a',
  appService: service(),
  attributeBindings: ['data-test-sender-assignee-dropdown-item'],
  'data-test-sender-assignee-dropdown-item': readOnly('model.id'),

  disabled: false,

  classNames: [
    'message__editor__content__sidebar__admin-selector-item',
    't__no-decoration',
    'u__prevent-breaks',
    'test__message-editor__sender-assignee-item',
  ],
  classNameBindings: ['isCurrentSelected:o__selected', 'disabled:o__disabled'],
  isCurrentIdSelected: equalToProperty('model.id', 'selected.id'),
  isCurrentTypeSelected: equalToProperty('model.type', 'selected.type'),
  isCurrentSelected: and('isCurrentIdSelected', 'isCurrentTypeSelected'),
  modelType: computed(
    'model.isTeam',
    'model.isCustomEmailAddress',
    'model.isDynamicSender',
    function () {
      if (this.get('model.isCustomEmailAddress')) {
        return 'custom-email-address';
      } else if (this.get('model.isDynamicSender')) {
        return 'dynamic-sender';
      } else if (this.get('model.isTeam')) {
        return 'team';
      } else {
        return 'admin';
      }
    },
  ),
  currentModelData: computed('model.id', 'modelType', function () {
    return {
      type: this.modelType,
      model: this.model,
    };
  }),
  click(e) {
    if (this.handleClick) {
      e.stopPropagation();
      this.handleClick(this.currentModelData);
    }
  },
});
