/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

type Args = {
  model: $TSFixMe;
  isMobilePlatform: boolean;
};

export default class Content extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare messengerSettingsService: MessengerSettingsService;

  get app() {
    return this.appService.app;
  }

  get spaces() {
    return this.args.model.spaces;
  }

  get isHelpSpaceEnabled() {
    return this.args.isMobilePlatform
      ? !!this.spaces.mobileUserHelpSpace?.enabled
      : !!(this.spaces.userHelpSpace?.enabled || this.spaces.visitorHelpSpace?.enabled);
  }

  get activeBrand() {
    return this.args.isMobilePlatform
      ? this.args.model.lookAndFeel.defaultMobileBrand
      : this.args.model.lookAndFeel.defaultBrand;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Content': typeof Content;
    'messenger-settings/conversational/content': typeof Content;
  }
}
