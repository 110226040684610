/* import __COLOCATED_TEMPLATE__ from './alert-billing-admin-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export interface Args {
  shouldShowModal: boolean;
  onModalClose: () => void;
}

interface Signature {
  Args: Args;
  Blocks: any;
  Element: HTMLElement;
}

export default class AlertBillingAdminModal extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get url() {
    return `${window.location.protocol}//${window.location.host}/a/apps/${this.appService.app.id}`;
  }

  get adminsWithBillingPermissions() {
    return this.appService.app.adminsWithBillingPermissions;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::AlertBillingAdminModal': typeof AlertBillingAdminModal;
    'modal/alert-billing-admin-modal': typeof AlertBillingAdminModal;
  }
}
