/* import __COLOCATED_TEMPLATE__ from './unsubscribed-preference.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UnsubscribedPreference extends Component {
  @service intercomEventService;
  @tracked showModal = false;

  get emails() {
    let emailRulesetLinks = this.args.ruleset.rulesetLinks.filter(
      (link) => link.objectType === objectTypes.email,
    );
    return emailRulesetLinks.map((link) => link.object);
  }

  get sendToUnsubscribed() {
    return this.emails.any((email) => email.sendToUnsubscribed);
  }

  get isLegallyMandated() {
    return this.emails.any((email) => email.isLegallyMandated);
  }

  @action
  toggleSendToUnsubscribedValue() {
    this.showModal = false;
    let oldValue = this.sendToUnsubscribed;
    this.emails.forEach((email) => (email.sendToUnsubscribed = !oldValue));
    this._trackAnalyticsEvent('send_to_unsubscribed', this.sendToUnsubscribed);
    this.args.ruleset.fetchAudiencePreviewTask.perform();
  }

  _trackAnalyticsEvent(object, value) {
    if (value) {
      let localizedEmailContent = this.emails.firstObject.localizedEmailContents.firstObject;

      let analyticsEvent = {
        action: 'turned_on',
        object,
        custom_domain: localizedEmailContent.isSenderUsingCustomDomain,
        dkim_authenticated: localizedEmailContent.isDomainDkimAuthenticated,
        return_path_authenticated: localizedEmailContent.isDomainReturnPathAuthenticated,
      };
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'turned_off',
        object,
      });
    }
  }
}
