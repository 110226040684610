/* import __COLOCATED_TEMPLATE__ from './upload.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import { importTypes, maxFileSizes, recordTypes } from 'embercom/models/csv-import';

export const uploadStates = {
  notStarted: 0,
  started: 1,
  finished: 2,
  failed: 3,
};

export default class Upload extends Component {
  @service appService;
  @service intercomEventService;
  @service store;

  @tracked fileUploadError;
  @tracked uploadState;

  @task
  *uploadFile(file) {
    try {
      let uploadedModel = yield this.saveCsvImportRecord(file);
      if (uploadedModel) {
        this.store.pushPayload('csv-import', {
          'csv-import': uploadedModel,
        });
        let model = this.store.peekRecord('csv-import', uploadedModel.id);
        this.onUploadFinish(model);
      }
    } catch (error) {
      captureException(error);
      this.onUploadFail(error);
    }
  }

  @action
  saveCsvImportRecord(file) {
    let payload = new FormData();
    payload.append('app_id', this.app.id);
    payload.append('csv_import[file]', file);
    payload.append('csv_import[record_type]', this.recordType);
    payload.append('csv_import[import_type]', importTypes.tagOnly);

    return this.ajaxRequest({
      endpoint: '/ember/fixed_list_messaging/csv_imports',
      payload,
    });
  }

  @action
  upload(file) {
    this.intercomEventService.trackEvent('csv_tag_event', {
      action: 'start',
      place: 'csv_tag_modal',
      object: 'csv_upload',
    });
    this.stateChange(uploadStates.started);
    this.uploadFile.perform(file);
  }

  @action
  setFile(file) {
    // This is called with `null` after removing file.
    if (isBlank(file)) {
      this.reset();
    }
  }

  get fileFailedToUpload() {
    return this.uploadState === uploadStates.failed;
  }

  get uploadFinished() {
    return this.uploadState === uploadStates.finished;
  }

  get fileSizeLimit() {
    let limit = maxFileSizes.default;
    if (this.app.canBumpCSVImportFileSizeLimit) {
      limit = maxFileSizes.tagOnly;
    }

    return limit;
  }

  get recordType() {
    let selectedUserTypes = this.args.selectedUserTypes || [];

    let hasUserType = selectedUserTypes.find((userType) => userType === 'user');
    let hasLeadType = selectedUserTypes.find((userType) => userType === 'lead');

    if (hasUserType && hasLeadType) {
      return recordTypes.usersAndLeads;
    } else if (hasLeadType) {
      return recordTypes.leads;
    }
    return recordTypes.users;
  }

  get app() {
    return this.appService.app;
  }

  reset() {
    this.fileUploadError = null;
    this.stateChange(uploadStates.notStarted);
  }

  stateChange(newState) {
    this.uploadState = newState;
    if (this.args.onUploadStateChange) {
      this.args.onUploadStateChange(newState);
    }
  }

  onUploadFinish(importRecord) {
    this.intercomEventService.trackEvent('csv_tag_event', {
      action: 'finish',
      place: 'csv_tag_modal',
      object: 'csv_upload',
      csv_import_id: importRecord.id,
    });
    this.stateChange(uploadStates.finished);
    if (this.args.onUploadFinish) {
      this.args.onUploadFinish(importRecord);
    }
  }

  onUploadFail(error) {
    this.stateChange(uploadStates.failed);
    this.fileUploadError = error.jqXHR.responseJSON.error;
    if (this.args.onUploadFail) {
      this.args.onUploadFail();
    }
  }

  ajaxRequest(options = {}) {
    let { endpoint, payload } = options;
    if (endpoint && payload) {
      return ajax({
        url: endpoint,
        method: 'POST',
        data: payload,
        contentType: false,
        processData: false,
      });
    }
  }
}
