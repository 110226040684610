/* import __COLOCATED_TEMPLATE__ from './web-installation-banner.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class MessengerSettingsWebInstallationBanner extends Component {
  @service onboardingHomeService;

  @action
  openWebInstallationSettings(e) {
    e.preventDefault();
    return safeWindowOpen(this.onboardingHomeService.messengerInstallationStepUrl(), '_self');
  }
}
