/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';

export default class GeneralStyles extends Component {
  @service MessengerSettingsService;
  colorOptions = DEFAULT_MESSENGER_COLORS;

  @action
  setPrimaryColor(color) {
    this.args.activeBrand.messengerActionColor = color;
    if (this.args.activeBrand.isDefault) {
      this.args.lookAndFeelSettings.primaryColor = color;
    }
    this.debouncedTrackColorChange('action_color', color);
  }

  @action
  setSecondaryColor(color) {
    this.args.activeBrand.messengerBackgroundColor = color;
    if (this.args.activeBrand.isDefault) {
      this.args.lookAndFeelSettings.secondaryColor = color;
    }
    this.debouncedTrackColorChange('background_color', color);
  }

  debouncedTrackColorChange(object, color) {
    debounce(
      this.MessengerSettingsService,
      this.MessengerSettingsService.trackAnalyticsEvent,
      {
        action: 'changed',
        object,
        color,
      },
      500,
    );
  }
}
