/* import __COLOCATED_TEMPLATE__ from './image-upload.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { ref } from 'ember-ref-bucket';

export default class ImageUploadComponent extends Component {
  @service appService;
  @service notificationsService;
  @service imageCropperService;
  @service intl;
  @service MessengerSettingsService;
  @tracked uncroppedFile = false;
  @tracked isDragging = false;
  @tracked isCropping = false;
  fileExtension = 'png,gif,jpeg,jpg';
  file;
  @ref('inputElement') inputElement;
  allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
  maxFileSize = 2000000; // 2MB
  aspectRatio = 400 / 329;

  get app() {
    return this.appService.app;
  }

  get hasImage() {
    return !!this.args.activeBrand.backgroundImageUrl;
  }

  get allowedTypeList() {
    return this.allowedFileTypes.join(',');
  }

  validate(file) {
    let errors = [];
    if (!this.validFormat(file)) {
      errors.push(
        this.intl.t('messenger.styling.background.options.image.errors.format', {
          formats: this.allowedFormats,
        }),
      );
    } else if (!this.validSize(file)) {
      errors.push(
        this.intl.t('messenger.styling.background.options.image.errors.size', {
          fileSize: this.maxFileSize / 1000000,
        }),
      );
    }
    if (errors.length > 0) {
      errors.forEach((error) => this.notificationsService.notifyError(error));
      return false;
    }
    return true;
  }

  validFormat(file) {
    return this.allowedFileTypes.includes(file.type);
  }

  validSize(file) {
    return file.size <= this.maxFileSize;
  }

  @dropTask *uploadImage(file) {
    yield ajax({
      url: `/ember/messenger_settings/upload_logo/${this.args.app.id}`,
      type: 'POST',
      data: this.filePayload(file),
    })
      .then((response) => {
        this.handleUploadSuccess(response);
        this.trackImageUpload(response.url);
      })
      .catch((error) => {
        this.handleUploadError(error);
      });
  }

  filePayload(fileData) {
    return JSON.stringify({
      file_data: fileData.split(',')[1],
      filename: this.file.name,
      content_type: this.file.type,
    });
  }

  handleUploadError(error) {
    this.removeImage();
    this.notificationsService.notifyResponseError(error, {
      default: this.intl.t('messenger.styling.background.options.image.errors.default'),
    });
  }

  handleUploadSuccess(response) {
    this.args.activeBrand.backgroundImageId = response.id;

    if (this.args.activeBrand.isDefault) {
      this.args.lookAndFeelSettings.backgroundImageId = response.id;
    }

    // Change image url only after loading it
    let img = new Image();
    img.onload = () => {
      this.args.activeBrand.backgroundImageUrl = response.url;
      if (this.args.activeBrand.isDefault) {
        this.args.lookAndFeelSettings.backgroundImageUrl = response.url;
      }
    };
  }

  trackImageUpload(imageUrl) {
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'uploaded',
      object: 'background_image',
      image_url: imageUrl,
    });
  }

  pickFile() {
    if (typeof this.args.pickFile === 'function') {
      // this is here for testing 🤮
      return this.args.pickFile.call(this);
    }
    this.clearFileInput();
    this.inputElement.click();
  }

  async setFile(originalFile) {
    if (this.validate(originalFile)) {
      if (originalFile.type !== 'image/gif') {
        this.file = await this.cropImage(originalFile);
      } else {
        this.file = originalFile;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.args.activeBrand.backgroundImageUrl = e.target.result;
        this.uploadImage.perform(e.target.result);
      };
      reader.readAsDataURL(this.file);
    }
  }

  async cropImage(originalFile) {
    let options = { type: originalFile.type };
    let url = URL.createObjectURL(originalFile, options);

    await this.imageCropperService.cropCenter(url, this.aspectRatio);

    let croppedFile = await this.imageCropperService.toFile(originalFile.name, options);
    return croppedFile;
  }

  clearFileInput() {
    this.inputElement.value = '';
  }

  @action
  dragOver(e) {
    e.preventDefault();
    this.isDragging = true;
  }

  @action
  dragLeave(e) {
    e.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(e) {
    e.preventDefault();
    this.isDragging = false;
    this.setOrStartCropping(e.dataTransfer.files[0]);
  }

  @action
  change(e) {
    if (e.target.files.length) {
      this.setOrStartCropping(e.target.files[0]);
    }
  }

  @action
  setOrStartCropping(file) {
    if (file.type !== 'image/gif') {
      this.uncroppedFile = file;
      this.isCropping = true;
    } else {
      this.setFile(file);
    }
  }

  @action
  onCrop(file) {
    this.setFile(file);
    this.uncroppedFile = null;
    this.isCropping = false;
  }

  @action
  onCropModalClose() {
    this.uncroppedFile = null;
    this.isCropping = false;
  }

  @action
  removeImage() {
    this.clearFileInput();
    this.file = null;
    this.args.activeBrand.backgroundImageId = null;
    this.args.activeBrand.backgroundImageUrl = null;

    if (this.args.activeBrand.isDefault) {
      this.args.lookAndFeelSettings.backgroundImageId = null;
      this.args.lookAndFeelSettings.backgroundImageUrl = null;
    }
  }

  @action
  showFilePicker() {
    if (this.uploadImage.isRunning || this.args.disabled) {
      return;
    }
    this.pickFile();
  }
}
