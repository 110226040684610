/* import __COLOCATED_TEMPLATE__ from './pricing-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import {
  BILLING_PERIODS,
  defaultSolutionId,
  findLatestPricing5PlanId,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_EXPERT_ID,
} from 'embercom/lib/billing';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

import type Breakdown from 'embercom/models/billing/price/breakdown';
import type Charge from 'embercom/models/billing/price/charge';
import type IntlService from 'ember-intl/services/intl';
import type Plan from 'embercom/models/plan';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type RouterService from '@ember/routing/router-service';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    plan: Plan;
    isLoading: boolean;
    yearlyBillingSelected: boolean;
    isEligibleForAnnualPlan: boolean;
    seatNumber: number;
  };
}

export default class PricingCard extends Component<Signature> {
  @service declare cardlessConversionModalService: any;
  @service declare quoteService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare purchaseAnalyticsService: any;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare router: RouterService;
  @tracked planIdAsNumber = parseInt(this.args.plan.id, 10);

  CAN_MANAGE_TEAMMATES_PERMISSION = 'can_manage_teammates';
  CAN_ACCESS_BILLING_SETTINGS = 'can_access_billing_settings';

  // Render state
  get displayPricesWithAnnualDiscountApplied() {
    return this.args.isEligibleForAnnualPlan && this.args.yearlyBillingSelected;
  }

  get planDesc() {
    return this.intl.t(
      `modal.cardless-conversion-modal-pricing5.compare-plans.pricing_subtitle_${this.args.plan.id}`,
    );
  }

  get activeOrExpiredPlanTrialIds() {
    return this.cardlessTrialService.activeOrExpiredPlanTrialIds;
  }

  get isHighlighted() {
    return this.isTrialPlan || this.isRecommended;
  }

  get isRecommended() {
    return (
      this.cardlessTrialService.noActiveSubOnP5 &&
      this.args.plan.idAsNumber === Number(PRICING_5_X_CORE_ADVANCED_ID)
    );
  }

  get isTrialPlan() {
    return findLatestPricing5PlanId(this.activeOrExpiredPlanTrialIds) === this.args.plan.idAsNumber;
  }

  // Prices

  get totalPlanPricePerBillingPeriodFormatted() {
    return this.totalPricePerBillingPeriod;
  }

  get pricePerSeat() {
    return this.getFormattedPrice(this.planPricePerSeat);
  }

  get planPricePerSeat() {
    return this.displayPricesWithAnnualDiscountApplied && this.hasNonZeroDiscountValue
      ? this.discountedSeatPrice
      : this.corePlanFullSeatPrice;
  }

  get corePlanFullSeatPrice() {
    return this.quoteForMetrics?.fullSeatPrice(this.planIdAsNumber, Metric.core_seat_count) ?? 0;
  }

  get quoteForMetrics() {
    return this.quoteService.getQuoteById(
      this.cardlessConversionModalService.planIdsForQuoteRetrieval,
    );
  }

  get planQuoteBreakdown() {
    let quote = this.quoteService.getQuoteById(
      this.cardlessConversionModalService.planIdsForQuoteRetrieval,
    );
    return quote?.breakdown.find(
      (quoteBreakdown: Breakdown) => quoteBreakdown.plan_id === this.args.plan.idAsNumber,
    );
  }

  get monthlyPrice() {
    return this.getFormattedPrice(this.planPricePerSeat * +this.args.seatNumber);
  }

  get yearlyPrice() {
    return this.getFormattedPrice(this.planPricePerSeat * 12 * +this.args.seatNumber);
  }

  get totalPricePerBillingPeriod() {
    if (this.args.yearlyBillingSelected) {
      return this.intl.t('modal.cardless-conversion-modal-pricing5.total_price_per_year', {
        price: this.yearlyPrice,
        seatNumber: this.args.seatNumber,
      });
    }

    return this.intl.t('modal.cardless-conversion-modal-pricing5.total_price_per_month', {
      price: this.monthlyPrice,
      seatNumber: this.args.seatNumber,
    });
  }

  // Discounts
  get discountedSeatPrice() {
    return this.convertCentsToDollars(
      this.pricingMetric(Metric.core_seat_count)?.per_unit_pricing_by_billing_period[
        BILLING_PERIODS.Annual
      ]?.monthly_equivalent_when_billed_by_billing_period,
    );
  }

  get hasNonZeroDiscountValue() {
    //Only show discounts for self serve plans that have a discount
    return this.args.plan.selfServe && this.discountedSeatPrice > 0;
  }

  get planAnnualDiscount() {
    return this.hasNonZeroDiscountValue
      ? this.corePlanFullSeatPrice - this.discountedSeatPrice
      : this.corePlanFullSeatPrice;
  }

  get totalYearlyDiscountSavings() {
    let savings = this.planAnnualDiscount * +this.args.seatNumber * 12;

    return this.intl.t('modal.cardless-conversion-modal-pricing5.total_savings', {
      savings: this.getFormattedPrice(savings),
    });
  }

  // Button CTAS/actions
  get secondaryBotTarget() {
    switch (this.args.plan.idAsNumber) {
      case Number(PRICING_5_X_CORE_EXPERT_ID):
        return 'fin_cardless_premium_sales_bot';
      case Number(PRICING_5_X_CORE_ADVANCED_ID):
        return 'fin_cardless_pro_sales_bot';
      default:
        return '';
    }
  }

  get showSecondaryCta() {
    // Only show chat to sales for plans that are not essential and are self serve.
    // If not self serve chat to sales is main CTA
    return (
      this.args.plan.selfServe &&
      this.args.plan.idAsNumber !== Number(PRICING_5_X_CORE_ESSENTIAL_ID)
    );
  }

  // Actions
  @action sendAnalyticsEvent({
    action,
    object,
    extra,
  }: {
    action: string;
    object: string;
    extra?: any;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context: 'cardless_trial',
      place: 'conversion_modal',
      ...(extra && { ...extra }),
    });
  }

  @action
  talkToSales() {
    let object = `chat_about_${this.args.plan.name.toLowerCase()}_plan`;
    showNewMessageInIntercomWidget();
    this.sendAnalyticsEvent({ action: 'clicked', object });
  }

  @action
  primaryCtaAction() {
    if (this.args.plan.selfServe) {
      let object = `buy_${this.args.plan.name}_button`;
      let solutionId = defaultSolutionId(this.args.plan.id);
      if (!this.adminHasBillingPermissions) {
        this.sendAnalyticsEvent({ action: 'blocked', object });
        return this.permissionsService.checkPermission(this.CAN_ACCESS_BILLING_SETTINGS);
      }
      if (!this.adminHasTeammatesPermissions) {
        this.sendAnalyticsEvent({ action: 'blocked', object });
        return this.permissionsService.checkPermission(this.CAN_MANAGE_TEAMMATES_PERMISSION);
      }
      this.cardlessConversionModalService.toggle();
      this.sendAnalyticsEvent({ action: 'clicked', object });
      let appId = this.cardlessTrialService.isTestApp
        ? this.cardlessTrialService.parentAppId!
        : this.cardlessTrialService.appId!;
      this.router.transitionTo('apps.app.teams-checkout.plan', appId, {
        queryParams: {
          solution_id: solutionId,
          cardless_trial: true,
        },
      });
    }
  }

  private adminHasTeammatesPermissions() {
    return this.permissionsService.currentAdminCan(this.CAN_MANAGE_TEAMMATES_PERMISSION);
  }

  private adminHasBillingPermissions() {
    return this.permissionsService.currentAdminCan(this.CAN_ACCESS_BILLING_SETTINGS);
  }

  private pricingMetric(pricingMetric: string) {
    return this.planQuoteBreakdown?.charges.find(
      (charge: Charge) => charge.pricing_metric === pricingMetric,
    );
  }

  private convertCentsToDollars(cents: number) {
    return cents / 100;
  }

  private getFormattedPrice(price: number) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::CardlessConversionModalPricing5::PricingCard': typeof PricingCard;
  }
}
