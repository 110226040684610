/* import __COLOCATED_TEMPLATE__ from './matching-timetable-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  timetableTypes,
  timezoneTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { isPresent, isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export const HOURS = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

export const DAYS = ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'];

export const DAY_IN_MINUTES = 60 * 24;

export const BIG_DROPDOWN_MIN_WIDTH_PX = 640;
export const SMALL_DROPDOWN_MIN_WIDTH_PX = 200;

class GridInterval {
  @tracked ruleset;
  @tracked gridComponent;
  @tracked index;
  @tracked startMinute;
  @tracked endMinutes;
  @tracked label;
  @tracked active;

  get subHead() {
    return `Choose the optimal send times for your ${this.contentName}`;
  }

  get listIntro() {
    return `Set days and times this ${this.contentName} can send`;
  }

  constructor(options = {}) {
    this.ruleset = options.ruleset;
    this.index = options.index;
    this.startMinute = options.startMinute;
    this.endMinute = options.endMinute;
    this.gridComponent = options.gridComponent;

    let active =
      this.ruleset.hasMatchingTimetable &&
      this.ruleset.matchingTimetable.timeIntervals
        .toArray()
        .some(
          (interval) =>
            interval.startMinute <= this.startMinute && interval.endMinute >= this.endMinute,
        );
    this.active = active;
  }

  get startDayIndex() {
    return Math.floor(this.startMinute / DAY_IN_MINUTES) % 7;
  }

  get endDayIndex() {
    return Math.floor(this.endMinute / DAY_IN_MINUTES) % 7;
  }

  get startHourIndex() {
    return Math.floor((this.startMinute % DAY_IN_MINUTES) / 60);
  }

  get endHourIndex() {
    return Math.floor((this.endMinute % DAY_IN_MINUTES) / 60);
  }

  get startDayName() {
    return DAYS[this.startDayIndex];
  }

  get endDayName() {
    return DAYS[this.endDayIndex];
  }

  get startHourName() {
    return HOURS[this.startHourIndex];
  }

  get endHourName() {
    return HOURS[this.endHourIndex];
  }
}

export default class MatchingTimetableEditor extends Component {
  matchingTimetableTypes = timetableTypes;
  noTimetableSetting = -1;

  @service appService;
  @service store;
  @tracked selectedSetting = this.noTimetableSetting;
  @tracked messengerSettings;
  @tracked timeIntervals = [];

  constructor() {
    super(...arguments);
    if (this.args.ruleset.hasMatchingTimetable) {
      this.selectedSetting = this.args.ruleset.matchingTimetable.timetableType;
    }

    this.fetchMessengerSettings.perform();
    this.initializeTimeIntervals();
  }

  initializeTimeIntervals() {
    let timeIntervals = [];
    for (let i = 0, j = 60, k = 0; j <= DAY_IN_MINUTES * 7; i += 60, j += 60, ++k) {
      let interval = new GridInterval({
        ruleset: this.args.ruleset,
        index: k,
        startMinute: i,
        endMinute: j,
        gridComponent: this,
      });
      timeIntervals.push(interval);
    }
    this.timeIntervals = timeIntervals;
    this.updateGroupLabels();
  }

  @task *fetchMessengerSettings() {
    let messengerSettings = yield this.store.findRecord(
      'messenger-settings/availability',
      this.appService.app.id,
    );
    this.messengerSettings = messengerSettings;
  }

  hoursOfTheDay = HOURS;
  daysOfTheWeek = DAYS;

  get dropdownMinimumWidth() {
    return this.args.ruleset.matchingTimetable?.isCustomTimesType
      ? BIG_DROPDOWN_MIN_WIDTH_PX
      : SMALL_DROPDOWN_MIN_WIDTH_PX;
  }

  get dropdownOpenerText() {
    if (this.insideOfficeHoursSelected) {
      return 'during office hours';
    } else if (this.outsideOfficeHoursSelected) {
      return 'outside office hours';
    } else if (this.customTimesSelected) {
      return 'at custom times';
    } else {
      return 'on any day, any time';
    }
  }

  get timezoneTypeForCustomTimes() {
    return this.args.timezoneType || timezoneTypes.basedOnAppTimezone;
  }

  get triggerTimesDisabled() {
    return (
      isEmpty(this.messengerSettings) || !this.messengerSettings.hasDefaultOfficeHoursIntervals
    );
  }

  get noTimetableSelected() {
    return this.selectedSetting === this.noTimetableSetting;
  }

  get insideOfficeHoursSelected() {
    return this.selectedSetting === this.matchingTimetableTypes.insideOfficeHours;
  }

  get outsideOfficeHoursSelected() {
    return this.selectedSetting === this.matchingTimetableTypes.outsideOfficeHours;
  }

  get customTimesSelected() {
    return this.selectedSetting === this.matchingTimetableTypes.customTimes;
  }

  setMatchingTimetable(options) {
    this.args.ruleset.matchingTimetable = this.store.createFragment(
      'matching-system/matching-timetable',
      options,
    );
  }

  get intervalGroups() {
    let activeIntervals = this.timeIntervals.filterBy('active');
    let intervalGroups = [];
    for (let i = 0; i < activeIntervals.length; ++i) {
      let interval = activeIntervals[i];
      if (i === 0) {
        intervalGroups.pushObject([interval]);
      } else if (interval.index === activeIntervals[i - 1].index + 1) {
        intervalGroups.lastObject.pushObject(interval);
      } else {
        intervalGroups.pushObject([interval]);
      }
    }
    return intervalGroups;
  }

  updateGroupLabels() {
    this.timeIntervals.setEach('label', null);
    this.intervalGroups.forEach((group) => {
      let firstIntervalOfGroup = group.firstObject;
      let label;
      if (group.firstObject.startDayIndex === group.lastObject.endDayIndex) {
        label = `${DAYS[group.firstObject.startDayIndex]} ${
          HOURS[group.firstObject.startHourIndex]
        } – ${HOURS[group.lastObject.endHourIndex]}`;
      } else {
        label = `${DAYS[group.firstObject.startDayIndex]} ${
          HOURS[group.firstObject.startHourIndex]
        } – ${DAYS[group.lastObject.endDayIndex]} ${HOURS[group.lastObject.endHourIndex]}`;
      }
      firstIntervalOfGroup.label = label;
    });
  }

  updateTimetableIntervals() {
    let timeIntervals = this.intervalGroups.map((group) => ({
      startMinute: group.firstObject.startMinute,
      endMinute: group.lastObject.endMinute,
    }));

    this.setMatchingTimetable({
      timetableType: timetableTypes.customTimes,
      timezoneType: this.timezoneTypeForCustomTimes,
      timeIntervals,
    });

    this.updateGroupLabels();
  }

  @action clickInterval(timeInterval, event) {
    timeInterval.active = !timeInterval.active;
    if (isPresent(this.lastSetIndex) && event.shiftKey) {
      for (let i = this.lastSetIndex; i < timeInterval.index; ++i) {
        let containedInterval = this.timeIntervals[i];
        containedInterval.active = timeInterval.active;
      }
    }
    this.lastSetIndex = timeInterval.index;
    this.updateTimetableIntervals();
  }

  @action removeMatchingTimetable() {
    this.args.ruleset.matchingTimetable = null;
    this.selectedSetting = this.noTimetableSetting;
  }

  @action setInsideOfficeHours() {
    this.selectedSetting = timetableTypes.insideOfficeHours;
    if (this.args.ruleset.matchingTimetable?.isInsideOfficeHoursType) {
      return;
    }
    this.setMatchingTimetable({
      timetableType: timetableTypes.insideOfficeHours,
      timezoneType: timezoneTypes.basedOnAppTimezone,
    });
  }

  @action setOutsideOfficeHours() {
    this.selectedSetting = timetableTypes.outsideOfficeHours;
    if (this.args.ruleset.matchingTimetable?.isOutsideOfficeHoursType) {
      return;
    }
    this.setMatchingTimetable({
      timetableType: timetableTypes.outsideOfficeHours,
      timezoneType: timezoneTypes.basedOnAppTimezone,
    });
  }

  @action setCustomTimes() {
    this.selectedSetting = timetableTypes.customTimes;
    if (this.args.ruleset.matchingTimetable?.isCustomTimesType) {
      return;
    } else if (this.timeIntervals) {
      this.updateTimetableIntervals();
    } else {
      this.setMatchingTimetable({
        timetableType: timetableTypes.customTimes,
        timezoneType: this.timezoneTypeForCustomTimes,
        timeIntervals: [],
      });
    }
  }
}
