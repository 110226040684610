/* import __COLOCATED_TEMPLATE__ from './domain-form-item.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnlyComponent from '@ember/component/template-only';
import type BrandDomain from 'embercom/models/brand-domain';

interface Signature {
  Args: {
    form: $TSFixMe; // embercom/models/custom-answers/custom-answer
    domain: BrandDomain;
    canRemove: boolean;
    removeDomain: $TSFixMe;
    showEmptyDomainMessage: boolean;
  };
}

const DomainFormItem = templateOnlyComponent<Signature>();
export default DomainFormItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::LookAndFeel::Multibrand::DomainFormItem': typeof DomainFormItem;
  }
}
