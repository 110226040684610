/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import { computed, observer } from '@ember/object';
import { cancel, scheduleOnce, next } from '@ember/runloop';
import { on } from '@ember/object/evented';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import PointerPositioner from 'embercom/lib/pointer-position';
import { fmtStyle, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { addEventListener } from 'ember-lifeline';

export default Component.extend({
  attributeBindings: ['style'],
  classNames: ['overlay', 'o__popover', 'z__2', 'js__popover'],
  extraClassNames: [],
  caretOffset: 0,
  viewportSelector: '.js__main-container',
  openerElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover-opener`;
  }),
  popoverElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover`;
  }),
  isOpen: false,
  allowAutoClose: true,
  popoverPosition: 'above',
  isPopoverAbove: equal('popoverPosition', 'above'),
  isPopoverBelow: equal('popoverPosition', 'below'),
  style: ternaryToProperty('isOpen', 'styleWhenOpen', 'styleWhenClosed'),
  styleWhenOpen: fmtStyle('top: %@px; left: %@px', 'topPosition', 'leftPosition'),
  styleWhenClosed: fmtStyle('top: 0px; left: -9999px'),
  pendingUpdate: null,

  init() {
    this._super();
    this.classNames = this.classNames.slice().concat(this.extraClassNames);
  },

  onDidInsertElement: on('didInsertElement', function () {
    this.set('pointerPositioner', new PointerPositioner(this.caretOffset));
    this.updatePopoverPosition();
    if (this.isOpen) {
      this._isOpenChanged();
    }
  }),

  setUpEvents() {
    $(this.element)
      .parents()
      .each((_, element) =>
        addEventListener(this, element, 'scroll', (e) => this.updatePopoverPosition(e)),
      );
    addEventListener(this, window, 'resize', (e) => this.updatePopoverPosition(e));
    addEventListener(this, window, 'click', (e) => this._handleMouseClickEvent(e));
    addEventListener(this, window, 'keyup', (e) => this._handleEscapeKeyPressed(e));
    this.setUpAdditionalEvents();
  },

  tearDownEvents: on('willDestroyElement', function () {
    if (this.pendingUpdate) {
      cancel(this.pendingUpdate);
    }
    this.tearDownAdditionalEvents();
  }),

  setUpAdditionalEvents() {},
  tearDownAdditionalEvents() {},

  updatePopoverPosition() {
    this.pendingUpdate = scheduleOnce('afterRender', this, this._updatePopoverPosition);
  },

  parentElementId: computed(function () {
    if ($(this.element)) {
      return $(this.element).parents('[id]').first().attr('id');
    }
  }),

  leftStyle: fmtStyle('left: calc(50% - %@px);', 'horizontalOffset'),
  topStyle: fmtStyle('top: calc(50% - %@px);', 'verticalOffset'),
  nullStyle: '',
  leftStyleIfPresent: ternaryToProperty('horizontalOffset', 'leftStyle', 'nullStyle'),
  topStyleIfPresent: ternaryToProperty('verticalOffset', 'topStyle', 'nullStyle'),

  caretStyle: computed('leftStyleIfPresent', 'topStyleIfPresent', function () {
    return `${this.leftStyleIfPresent} ${this.topStyleIfPresent}`;
  }),

  focusOnInput() {
    if (!this.isDestroying) {
      $('.js__popover__input').first().focus();
    }
  },

  _isOpenChanged: observer({
    dependentKeys: ['isOpen'],

    fn() {
      if (this.isOpen) {
        this.setUpEvents();
        this.updatePopoverPosition();
        next(this, this.focusOnInput);
      } else {
        this.tearDownEvents();
      }
    },

    sync: false,
  }),

  _updatePopoverPosition() {
    if (this.isDestroying) {
      return;
    }
    let $openerElement = $(this.openerElementSelector);
    // TODO - DB: We have to do this here as we can't do a fallback
    // via the pointer positioner code, though that's a better
    // place for this sort of thing
    //
    // Fallback for missing viewportSelector
    if ($(this.viewportSelector).length === 0) {
      this.set('viewportSelector', 'body');
    }

    if (!$openerElement || $openerElement.length === 0) {
      return;
    }

    let openerElement = $openerElement.first();
    if (!openerElement) {
      return;
    }

    let position = null;
    try {
      position = this.pointerPositioner.getPosition(
        this.openerElementSelector,
        this.popoverElementSelector,
        this.viewportSelector,
      );
    } catch (e) {
      // no-op
    }
    if (!position) {
      return;
    }
    if (position.location === 'below') {
      this.setProperties({
        topPosition: openerElement.offset().top + openerElement.outerHeight(),
        leftPosition: openerElement.offset().left,
        horizontalOffset: 0,
        verticalOffset: 0,
        popoverPosition: position.location,
      });
    } else {
      this.setProperties({
        topPosition: position.top,
        leftPosition: position.left,
        horizontalOffset: position.horizontalOffset,
        verticalOffset: position.verticalOffset,
        popoverPosition: position.location,
      });
    }

    let openerIsVisible = openerElement.visible(true);
    this.set('openerIsVisible', openerIsVisible);
    if (this.allowAutoClose && !openerIsVisible) {
      this._dismiss();
    }
  },

  _handleMouseClickEvent(event) {
    if (
      this.allowAutoClose &&
      this._isMouseClickOutsidePopover(event) &&
      this._isMouseClickNotOpenerElement(event)
    ) {
      this._dismiss();
    }
  },

  _handleEscapeKeyPressed(event) {
    if (event.keyCode === 27) {
      this._dismiss();
    }
  },

  _dismiss() {
    this.set('isOpen', false);
    if (this.onDismiss) {
      this.onDismiss();
    }
  },

  _isMouseClickOutsidePopover(event) {
    let $container = $(this.element);
    return !($container.is(event.target) || $container.has(event.target).length);
  },

  _isMouseClickNotOpenerElement(event) {
    let $opener = $(this.openerElementSelector);
    return !($opener.is(event.target) || $opener.has(event.target).length);
  },
});
