/* import __COLOCATED_TEMPLATE__ from './create-user.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { computed, action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default Component.extend({
  notificationsService: service(),
  router: service(),
  store: service(),
  intercomEventService: service(),
  userName: '',
  userEmail: '',
  userId: '',
  phoneNumber: '',
  role: 'user_role',

  createNewUser: task(function* () {
    let user = this.store.createRecord('user', {
      name: this.userName,
      email: this.userEmail,
      user_id: this.userId,
      phone: this.phoneNumber,
      role: this.role,
    });

    try {
      yield user.save().then(() => {
        this.router.transitionTo('apps.app.users.user', user.id);
      });
      this.intercomEventService.trackAnalyticsEvent({
        object: 'new_user',
        place: 'user_list',
        action: 'created',
        context: {
          user_id: !!this.userId,
          email: !!this.userEmail,
          name: !!this.userName,
          phone: !!this.phoneNumber,
        },
      });
      this.cleanAndHideCreateUserModal();
    } catch (e) {
      this.notificationsService.notifyError(
        `An error occurred while creating the user: ${e.jqXHR.responseJSON.errors}`,
      );
      this.cleanAndHideCreateUserModal();
    }
  }),

  disableCreateButton: computed('userName', 'userEmail', 'userId', function () {
    let allValuesEmpty = !(this.userName || this.userEmail || this.userId);
    let emailIsInvalid = !document.querySelector('[data-create-email]').validity.valid;
    return allValuesEmpty || emailIsInvalid;
  }),

  disableCreateTooltip: 'Please make sure to fill the form correctly',

  cleanAndHideCreateUserModal() {
    this.set('newUser', '');
    this.set('userEmail', '');
    this.set('userId', '');
    this.set('userName', '');
    this.set('phoneNumber', '');
    this.set('showUserCreateModal', false);
  },

  cancelCreateUser: action(function () {
    this.cleanAndHideCreateUserModal();
  }),

  navigateToAddMultipleUsersArticle: action(function () {
    safeWindowOpen(
      'https://www.intercom.com/help/configure-intercom/customize-intercom-to-be-about-your-users/import-your-user-data-into-intercom',
      '_blank',
    );
  }),
});
