/* import __COLOCATED_TEMPLATE__ from './team-introduction.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

export default class TeamIntroduction extends Component {
  get languageSettings() {
    return this.args.languageSettings;
  }

  get permittedLocales() {
    let defaultLocale = this.languageSettings.supportedLocales.findBy(
      'localeId',
      this.languageSettings.defaultLocale,
    );

    let otherPermittedLocales = this.languageSettings.supportedLocales.filter(
      (locale) => locale.id !== this.languageSettings.defaultLocale && locale.isPermitted,
    );
    return [defaultLocale, ...otherPermittedLocales];
  }
}
