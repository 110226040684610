/* import __COLOCATED_TEMPLATE__ from './identity-verification-reminder.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default class IdentityVerificationReminder extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;

  @tracked settings = this.args.settings;
  @tracked selectedReminderOption = this.settings.reminderEnabled.toString();
  @tracked reminder = this.args.reminder;
  @tracked selectedDate = this.reminder?.send_at || this._startOfNextHour().add(1, 'day').toDate();
  @tracked disableDatesBefore = moment();
  @tracked showSkipModal = false;

  constructor() {
    super(...arguments);
  }

  get app() {
    return this.appService.app;
  }

  get remindMeButtonEnabled() {
    return this.selectedDate.getTime() !== this.reminder?.send_at?.getTime();
  }

  get optOutAdminName() {
    let admin = this.app.humanAdmins.findBy(
      'id',
      this.settings.reminderOptedOutAdminId?.toString(),
    );
    return (
      admin?.name || this.intl.t('onboarding.home.steps.keep-messenger-secure.unknown-teammate')
    );
  }

  get optOutText() {
    if (this.settings.reminderEnabled || !this.settings.reminderOptedOutAdminId) {
      return '';
    }
    let time = moment(this.settings.reminderOptedOutAt).fromNow();

    return `${this.optOutAdminName}, ${time}`;
  }

  get todayInAppTimezone() {
    return moment.tz(this.appService.app.timezone);
  }

  @action disableReminders() {
    this.settings.setProperties({
      reminderEnabled: false,
      reminderOptedOutAdminId: this.app.currentAdmin.id,
      reminderOptedOutAt: new Date(),
    });
    this.settings.save();
    this._disableReminder();
    this.showSkipModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'confirm_idv_skip_modal',
      place: 'idv_web_settings',
    });
  }

  @action enableReminders() {
    this.settings.setProperties({
      reminderEnabled: true,
      reminderOptedOutAdminId: null,
      reminderOptedOutAt: null,
    });
    this.settings.save();
  }

  @action closeSkipModal() {
    this.showSkipModal = false;
    this.selectedReminderOption = 'true';
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cancel_idv_skip_modal',
      place: 'idv_web_settings',
    });
  }

  @action async createOrUpdateReminder() {
    if (this.reminder) {
      this.reminder.set('send_at', this.selectedDate);
      await this.reminder.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'update_reminder',
        place: 'idv_web_settings',
      });
    } else {
      this.reminder = this.store.createRecord('onboarding/home/identity_verification_reminder', {});
      this.reminder.set('send_at', this.selectedDate);
      await this.reminder.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_reminder',
        place: 'idv_web_settings',
      });
    }
  }

  @action onDateChange(date) {
    this.selectedDate = date;
  }

  _startOfNextHour() {
    return this.todayInAppTimezone.add(1, 'hour').startOf('hour');
  }

  _disableReminder() {
    if (this.reminder) {
      this.reminder.send_at = null;
      this.reminder.save();
    }
  }
}
