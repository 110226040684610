/* import __COLOCATED_TEMPLATE__ from './article-search-slot.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ArticleSearchSlotComponent extends Component {
  @service messengerSettingsTargetUserProvider;
  @service intl;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get userTypeLabel() {
    return this.messengerSettingsTargetUserProvider.isUserType ||
      this.messengerSettingsTargetUserProvider.isMobile
      ? this.intl.t('messenger.target-types.users-label')
      : this.intl.t('messenger.target-types.visitors-label');
  }

  get canAddArticleSearch() {
    return this.args.spaces.hasHelp || this.app.canSearchBrowseWithoutHelpSpace;
  }

  @action
  enableHelp() {
    this.args.spaces.enableHelp();
  }
}
