/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, not, readOnly } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { schedule, throttle } from '@ember/runloop';
import { inject as service } from '@ember/service';
import DidChangeAttrs from 'ember-did-change-attrs';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import ComposerUploadCheck from 'embercom/components/message-editor/utility/composer-upload-check';
import ENV from 'embercom/config/environment';

export default Component.extend(ComposerUploadCheck, DidChangeAttrs, {
  intercomEventService: service(),
  store: service(),
  attributeService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  attributeBindings: ['data-test-message-editor'],
  'data-test-message-editor': true,

  serializedBlocks: A(),

  isEditable: and('isEditing', 'isMaximized'),
  isReadOnly: not('isEditable'),
  preset: computed(
    'variation.{emailTemplate,messageStyle}',
    'isReadOnly',
    'isMobileOrIosOrAndroid',
    function () {
      return this.variation.composerPreset(this.isReadOnly, this.isMobileOrIosOrAndroid);
    },
  ),

  attributes: ternaryToProperty(
    'variation.message.isVisitorAutoMessage',
    'attributeService.vamEditorAttributes',
    'attributeService.userEditorAttributes',
  ),

  initializeSerializedBlocks: on('init', function () {
    this.set('serializedBlocks', this.get('variation.blocks').serialize());
  }),

  didChangeAttrsConfig: {
    attrs: ['variation', 'isEditing'],
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes.variation || changes.isEditing) {
      this._loadBlocksAfterComposerRender();
    }
  },

  didUpdateAttrs() {
    this._super(...arguments);
    this.initializeSerializedBlocks();
  },

  _loadBlocksAfterComposerRender() {
    schedule('afterRender', () => {
      if (this.composer) {
        this.composer.send('load', this.get('variation.blocks').serialize());
      }
    });
  },

  //@pat - The reason we do this is because the Composer fires `performBlocksUpdate` too many times even when there are no differences between the blocks. This is a temporary solution as ideally the composer should not fire the `updateBlocks` event as often as it does.
  blocksAreDifferent(blockFragments, blocks) {
    let serializedFragments = blockFragments.map((fragment) => fragment.serialize());
    return JSON.stringify(serializedFragments) !== JSON.stringify(blocks.serialize());
  },

  isValidForBlocksUpdate() {
    return (
      !this.isDestroying &&
      this.composer &&
      this.isEditable &&
      !this.get('variation.isHtmlMode') &&
      !this.get('variation.isVideo')
    );
  },

  performBlocksUpdate() {
    // noop
  },

  actions: {
    updateBlocks() {
      throttle(this, this.performBlocksUpdate, ENV.APP._50MS, false);
    },
    changePreviewDevice(device) {
      this.withComposerUploadCheck(() => {
        this.set('currentDevice', device);
        this.initializeSerializedBlocks();
      });

      this.intercomEventService.trackAnalyticsEvent({
        action: 'preview_device',
        place: 'message_editor',
        object: 'message',
        device,
      });
    },
    onComposerUploadStart() {
      this.set('variation.message._isUploading', true);
    },
    onComposerUploadFinish() {
      this.set('variation.message._isUploading', false);
      this.performBlocksUpdate();
    },
    onComposerUploadError(error) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'upload_failed',
        place: 'message_editor',
        object: 'message',
        file_size: error.fileSize,
        file_type: error.fileType,
        message: error.message,
      });
    },
  },
});
