/* import __COLOCATED_TEMPLATE__ from './welcome-message.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Intl from 'embercom/services/intl';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

interface Signature {
  Args: {
    languageSettings: any;
  };
}

export default class WelcomeMessage extends Component<Signature> {
  @service declare appService: any;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare intl: Intl;

  get languageSettings() {
    return this.args.languageSettings;
  }

  get permittedLocales() {
    let defaultLocale = this.languageSettings.supportedLocales.findBy(
      'localeId',
      this.languageSettings.defaultLocale,
    );

    let otherPermittedLocales = this.languageSettings.supportedLocales.filter(
      (locale: any) => locale.isPermitted,
    );
    return [defaultLocale, ...otherPermittedLocales];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Greeting::WelcomeMessage': typeof WelcomeMessage;
    'messenger-settings/conversational/greeting/welcome-message': typeof WelcomeMessage;
  }
}
