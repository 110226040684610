/* import __COLOCATED_TEMPLATE__ from './disable-messenger-banner.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';

export default class DisableMessengerBanner extends Component {
  @service intl;
  @service appService;
  @service notificationsService;

  get platform() {
    return this.args.platform?.toLowerCase();
  }

  get appModel() {
    switch (this.platform) {
      case 'ios':
        return this.args.sdkApp;
      case 'android':
        return this.args.sdkApp;
      case 'web':
        return this.args.app;
    }
  }

  get fieldName() {
    switch (this.platform) {
      case 'ios':
        return 'is_disabled';
      case 'android':
        return 'is_disabled';
      case 'web':
        return 'is_web_messenger_disabled';
    }
  }

  get isDisabled() {
    return this.appModel?.get(this.fieldName);
  }

  @action
  onSwitchClick(e) {
    e.preventDefault();
    let wasDisabled = this.appModel.get(this.fieldName);
    if (!wasDisabled) {
      let confirmationMessage = this.intl.t(
        'apps.app.settings.installation.disable-banner.confirmation',
        { platform: this.args.platform },
      );
      if (!confirm(confirmationMessage)) {
        return;
      }
    }
    this.togglePlatformApiAvailability.perform();
  }

  @task({ drop: true })
  *togglePlatformApiAvailability() {
    let wasDisabled = this.appModel.get(this.fieldName);
    try {
      this.appModel.toggleProperty(this.fieldName);
      yield ajax({
        url: `/ember/messenger_settings/all/${this.appService.app.id}/set_api_availability`,
        type: 'POST',
        data: JSON.stringify({
          platform: this.platform,
          disable: this.appModel.get(this.fieldName),
        }),
      });
    } catch (e) {
      this.appModel.set(this.fieldName, wasDisabled);
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.installation.disable-banner.error', {
          platform: this.args.platform,
        }),
      );
    }
  }
}
