/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { observer } from '@ember/object';
import { on } from '@ember/object/evented';
import { bind, schedule } from '@ember/runloop';
import $ from 'jquery';
import { not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { BROWSER_SUPPORTS_TOUCH } from 'embercom/lib/screen-awareness';

export default Mixin.create({
  isOpen: false,
  isClosed: not('isOpen'),
  toggleWidth: 0,
  closeOnLeave: false,
  hasContext: false,
  bubbles: true,

  _ignoreClick(e) {
    if (!this.isDestroyed) {
      let $target = $(this.element).find(e.target);
      return $target.length > 0 || $(this.element).is(e.target);
    }
  },
  _preventClosing(e) {
    if ($(e.target).hasClass('force_close')) {
      return false;
    } else if ($(e.target).hasClass('no_toggle')) {
      return true;
    } else if (this.preventDropdownToggleFromWithin) {
      return true;
    } else {
      return false;
    }
  },
  _handleMouseDown(e) {
    if (this.isOpen && !this._ignoreClick(e)) {
      this.send('close');
    }
  },
  mouseLeave() {
    if (this.closeOnLeave && !this.hasContext) {
      this.send('close');
    }
  },
  didInsertElement() {
    this._super(...arguments);
    let contextElement;
    this.set('toggleWidth', $(this.element).outerWidth());
    if (this.hasContext && this.closeOnLeave) {
      contextElement = $(this.element).parents(this.contextElementSelector).eq(0);
      if (contextElement.length === 0) {
        this.set('hasContext', false);
      } else {
        this.set('contextElement', contextElement);
        let toggleElementDisplay =
          this.showOnlyWhenHoveringInsideContext && !BROWSER_SUPPORTS_TOUCH;
        contextElement.on(
          `mouseleave.${this.elementId}`,
          bind(this, function () {
            if (this.isOpen) {
              this.send('close');
            }
            if (toggleElementDisplay) {
              $(this.element).addClass('u__hidden');
            }
          }),
        );
        if (toggleElementDisplay) {
          contextElement.on(
            `mouseenter.${this.elementId}`,
            bind(this, function () {
              $(this.element).removeClass('u__hidden');
            }),
          );
        }
      }
    }
  },
  willDestroyElement() {
    $('body').off(`mousedown.${this.elementId}`);
    if (this.hasContext && this.contextElement) {
      this.contextElement.off(`mouseleave.${this.elementId}`);
      this.contextElement.off(`mouseenter.${this.elementId}`);
    }
    this.set('contextElement', null);
    this._super(...arguments);
  },

  _click: on('click', function (e) {
    if (!this.isOpen && !this.disabled) {
      this.send('open');
    } else if (!this._preventClosing(e)) {
      this.send('close');
    }
    if (this.clearInputOnSelect) {
      $('input', this.element).val('');
      this.set('adminFilterQuery', '');
    }
    if (!this.bubbles) {
      e.stopPropagation();
      e.preventDefault();
    }
  }),

  _closeDropdown() {
    if (!this.isDestroyed && !this.isOpening) {
      this.set('isOpen', false);
      $('body').off(`mousedown.${this.elementId}`);
    }
  },

  setupScrollParent: on('didInsertElement', function () {
    if (this.scrollParentSelector) {
      this.set('$scrollParent', $(this.scrollParentSelector));
    }
  }),
  onDropdownOpen: observer({
    dependentKeys: ['isOpen'],

    fn() {
      if (this.$scrollParent) {
        this.$scrollParent.toggleClass('overflow-hidden', this.isOpen);
      }
    },

    sync: false,
  }),
  teardownScrollParent: on('willDestroyElement', function () {
    if (this.$scrollParent) {
      this.$scrollParent.removeClass('overflow-hidden');
      this.set('$scrollParent', null);
    }
  }),
  actions: {
    closeDropdown() {
      this._closeDropdown();
    },
    open() {
      if (!this.isDestroyed) {
        this.set('isOpen', true);
        this.set('isOpening', true);
        $('body').on(`mousedown.${this.elementId}`, bind(this, this._handleMouseDown));
        schedule('afterRender', this, function () {
          let overlay = $('.overlay.o__dropdown', this.element);
          if (overlay !== undefined && !overlay.hasClass('no-overwrite')) {
            overlay.css({
              minWidth: this.toggleWidth,
            });
          }
          if (this.hasOpened) {
            this.hasOpened();
          }
          this.set('isOpening', false);
        });
      }
    },
    close() {
      this._closeDropdown();
      schedule('afterRender', this, function () {
        if (this.hasClosed) {
          this.hasClosed();
        }
      });
    },
  },
  keyDown(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    switch (keyCode) {
      case 27:
        $('input', this.element).blur();
        this.send('close');
        e.preventDefault();
        break;
      default:
        this._super(e);
    }
  },
});
