/* import __COLOCATED_TEMPLATE__ from './space.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { states, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { isPresent } from '@ember/utils';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';

export default class Space extends Component {
  @service helpCenterService;
  @service outboundHomeService;
  @service appService;

  checklistSearchResult = trackedTask(this, taskFor(this.searchForLiveChecklists), () => []);

  get draggableHandleColor() {
    if (this.args.space.isHub) {
      return 'gray-light';
    } else {
      return 'black;';
    }
  }

  get isHelpCenterEnabled() {
    return this.helpCenterService.site?.turnedOn;
  }

  get liveChecklistCount() {
    return this.checklistSearchResult.value?.contentWrappers.length ?? 0;
  }

  get hasLiveChecklists() {
    return this.liveChecklistCount && this.liveChecklistCount > 0;
  }

  get isDisabledForNews() {
    return this.args.space.isHelp && !this.isHelpCenterEnabled;
  }

  get hasChangedEnabledState() {
    return isPresent(this.args.space.changedAttributes().enabled);
  }

  get isDisabledForChecklists() {
    // Only disable the toggle when the space is currently enabled and set live
    // If they're just flipping the toggle without saving changes then we don't want to disable it yet.
    return (
      this.args.space.isTasks &&
      this.args.space.currentlyEnabledAndSetLive &&
      (this.hasLiveChecklists || this.checklistSearchResult.isRunning)
    );
  }

  get isDisabled() {
    return this.isDisabledForNews || this.isDisabledForChecklists;
  }

  @dropTask
  *searchForLiveChecklists() {
    return yield this.outboundHomeService.contentSearch({
      app_id: this.appService.app.id,
      object_types: [objectTypes.checklist],
      states: [states.live],
      per_page: 5,
    });
  }
}
