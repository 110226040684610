/* import __COLOCATED_TEMPLATE__ from './greeting.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

type Args = {
  model: $TSFixMe;
};

export default class Greeting extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare messengerSettingsService: MessengerSettingsService;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::Greeting': typeof Greeting;
    'messenger-settings/conversational/greeting': typeof Greeting;
  }
}
