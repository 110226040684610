/* import __COLOCATED_TEMPLATE__ from './email-verification-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { and, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  notificationsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  emailApprovalRequiredOrPendingVerificationOrRestricted: or(
    'app.awaiting_email_approval',
    'admin.current_email_pending_verification',
    'app.restricted_to_engaged_only',
    'app.email_rate_limited',
  ),
  shouldShowBanner: and(
    'emailApprovalRequiredOrPendingVerificationOrRestricted',
    'app.isNotDeveloperWorkspace',
  ),

  isEmailResending: false,

  resendValidationEmail: task(function* () {
    try {
      yield this.admin.resendValidationEmail();
      this.notificationsService.notifyConfirmation(
        'The email has been resent, please check your inbox.',
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: `We couldn't resend the verification email, please try again.`,
      });
    }
  }),
});
