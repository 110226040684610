/* import __COLOCATED_TEMPLATE__ from './preview-device-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
export default Component.extend({
  webSelected: equal('currentDevice', 'web'),
  iosSelected: equal('currentDevice', 'ios'),
  androidSelected: equal('currentDevice', 'android'),
  desktopSelected: equal('currentDevice', 'desktop'),
  mobileSelected: equal('currentDevice', 'mobile'),
});
