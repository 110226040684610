/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  intercomEventService: service(),
  eventName: 'educate-event',
  owner: 'educate',
  trackCollectionEvent(eventName, sectionOrCollection, metadata = {}) {
    metadata.object = 'series';
    metadata.owner = 'educate';
    // Using seriesId here to maintain consistency
    metadata.seriesId = sectionOrCollection.get('id');
    this.intercomEventService.trackEvent(eventName, metadata);
  },
  trackEducateEvent(metadata = {}) {
    metadata.owner = this.owner;
    this.intercomEventService.trackEvent(this.eventName, metadata);
  },
  trackEducateArticleEvent(article, metadata = {}) {
    metadata.owner = this.owner;
    metadata.object = 'article';
    metadata.article_id = article.get('id');
    if (!metadata.collection_id) {
      metadata.collection_id = article.get('collection.id');
    }
    if (!metadata.section_id) {
      metadata.section_id = article.get('section.id');
    }

    let eventName = metadata.eventName || this.eventName;
    delete metadata['eventName'];

    this.intercomEventService.trackEvent(eventName, metadata);
  },
  trackEducateArticleContentEvent(article, content, metadata = {}) {
    metadata.owner = this.owner;
    metadata.object = 'article-content';
    metadata.action = 'saved';
    metadata.article_id = article.get('id');
    metadata.locale = content.locale;
    metadata.is_default_locale = article.defaultLocaleId === content.locale;
    this.intercomEventService.trackEvent(this.eventName, metadata);
  },
  trackEducateCollectionOrSectionEvent(sectionOrCollection, metadata = {}) {
    metadata.owner = this.owner;
    metadata.object = sectionOrCollection.get('type');
    if (sectionOrCollection.get('isCollection')) {
      metadata.collection_id = sectionOrCollection.get('id');
    } else {
      metadata.section_id = sectionOrCollection.get('id');
      metadata.collection_id = sectionOrCollection.get('parent.id');
    }
    this.intercomEventService.trackEvent(this.eventName, metadata);
  },
});
