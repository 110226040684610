/* import __COLOCATED_TEMPLATE__ from './event-target.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  defaultRulesetSpacingPeriod,
  matchBehaviors,
  recurringScheduleTypes,
  recurringScheduleFrequencies,
  triggerEventTypes,
  objectTypes,
  objectNotificationPreferences,
} from 'embercom/models/data/matching-system/matching-constants';
import { isPresent } from '@ember/utils';

export default class EventTarget extends Component {
  @service appService;
  @service attributeService;
  @service intercomEventService;
  @service store;
  @service contentEditorService;
  @tracked isEditingDateOffset = false;

  get app() {
    return this.appService.app;
  }

  get activeEvents() {
    return this.attributeService.attributes.filter((attr) => attr.isUserEvent);
  }

  get activeEventsNames() {
    return this.activeEvents.map((event) => {
      return {
        text: event.human_friendly_name,
        value: event,
        icon: 'trigger',
      };
    });
  }

  get attributeSelectItems() {
    let items = this.activeEventsNames;
    if (this.app.canUseScheduleBasedResending) {
      items = [];

      if (this.args.showDateEvents) {
        items.push(
          {
            heading: 'Person date rules',
            items: this._dateAttributesNames(this.datePersonAttributes),
          },
          {
            heading: 'Company date rules',
            items: this._dateAttributesNames(this.dateCompanyAttributes),
          },
        );
      }

      items.push({
        heading: 'Triggered events',
        items: this.activeEventsNames,
      });
    }

    return items;
  }

  get datePersonAttributes() {
    return this.attributeService.attributes.filter((attr) => attr.isDateFilter && !attr.isCompany);
  }

  get dateCompanyAttributes() {
    return this.attributeService.attributes.filter((attr) => attr.isDateFilter && attr.isCompany);
  }

  _dateAttributesNames(attributes) {
    return attributes.map((attribute) => {
      return {
        text: attribute.human_friendly_name,
        value: attribute,
        icon: 'calendar',
      };
    });
  }

  get trigger() {
    if (this.rulesetTrigger) {
      return this.rulesetTrigger;
    } else if (this.recurringSchedule) {
      return this.recurringSchedule;
    }
  }

  get isWhenRuleDisabled() {
    return this.trigger || this.ruleset.isMatchBehaviorStatic || this.hasClientPredicates;
  }

  get isWhenRuleTooltipDisabled() {
    if (
      this.rulesetTrigger &&
      (this.ruleset.isMatchBehaviorMulti || this.ruleset.isMatchBehaviorSingle)
    ) {
      return false;
    } else if (!this.rulesetTrigger && this.ruleset.isMatchBehaviorStatic) {
      return false;
    } else if (this.hasClientPredicates) {
      return false;
    } else if (this.recurringSchedule) {
      return false;
    }
    return true;
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get recurringSchedule() {
    return this.args.ruleset.recurringSchedule;
  }

  get rulesetTrigger() {
    return this.args.ruleset.rulesetTriggers.firstObject;
  }

  get rulesetTriggerPredicates() {
    return this.rulesetTrigger.eventPredicateGroup.predicates;
  }

  get whenRuleTooltipContent() {
    if (this.ruleset.isMatchBehaviorStatic) {
      return '"When" rules are only available with a Dynamic audience';
    }

    if (this.recurringSchedule?.calendarBased) {
      return "You can't add event rules when a fixed schedule is selected";
    }

    return 'Currently you can add only one rule like this';
  }

  get hasEventMetadata() {
    let selectedEvent = this.activeEvents.find(
      (event) => event.event_id === this.rulesetTrigger.eventId,
    );

    if (!selectedEvent) {
      return false;
    }

    return selectedEvent.metadata.length > 0;
  }

  get hasSelectedEventMetadata() {
    if (this.rulesetTrigger && this.rulesetTrigger.eventPredicateGroup) {
      return this.rulesetTrigger.eventPredicateGroup.predicates.length > 0;
    }

    return false;
  }

  get attributeBasedRecurringSchedule() {
    return (
      isPresent(this.recurringSchedule) &&
      this.recurringSchedule.scheduleType === recurringScheduleTypes.attributeBased
    );
  }

  get sendEveryTime() {
    return this.trigger && this.ruleset.isMatchBehaviorMulti;
  }

  @action addTrigger(event) {
    if (event.inferredType === 'user_event') {
      this.addRulesetTrigger(event);
    } else if (event.inferredType === 'date') {
      this.addRecurringSchedule(event);
    }
    this._resetCustomBotShowUntil();
  }

  @action addRecurringSchedule(attribute) {
    this.deleteRecurringSchedule();
    this.ruleset.recurringSchedule = this.store.createFragment(
      'matching-system/recurring-schedule',
      {
        dateAttribute: attribute.identifier,
        frequency: recurringScheduleFrequencies.basedOnUserData,
        rulesetId: this.ruleset.id,
        scheduleType: recurringScheduleTypes.attributeBased,
      },
    );

    this.isEditingDateOffset = true;
  }

  @action deleteRecurringSchedule() {
    this.ruleset.recurringSchedule = null;
    this._resetRulesetMatchBehaviorAndSpacingPeriod();
  }

  @action addRulesetTrigger(event) {
    this.deleteRulesetTrigger();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'event_trigger',
      event_id: event.event_id,
    });
    let rulesetTrigger = this.store.createRecord('matching-system/ruleset-trigger', {
      eventName: event.human_friendly_name,
      eventId: event.event_id,
      eventType: triggerEventTypes.userEvent,
      rulesetId: this.ruleset.id,
      eventPredicateGroup: { predicates: [] },
    });
    this.ruleset.rulesetTriggers.pushObject(rulesetTrigger);
  }

  @action deleteRulesetTrigger() {
    if (this.rulesetTrigger) {
      this.ruleset.rulesetTriggers.removeObject(this.rulesetTrigger);
      this._resetRulesetMatchBehaviorAndSpacingPeriod();
    }
  }

  @action setSpacingPeriod(periodInSeconds) {
    this.ruleset.matchSpacingPeriod = periodInSeconds;
  }

  @action setMaxMatchCount(maxMatchCount) {
    this.ruleset.maxMatchCount = maxMatchCount;
  }

  @action toggleMatchBehavior() {
    if (!this.ruleset.isMatchBehaviorMulti) {
      this.ruleset.matchBehavior = matchBehaviors.multi;

      let analyticsEvent = {
        action: 'turned_on',
        object: 'multi_match',
      };
      if (this.contentEditorService.configRulesetLink.objectType === objectTypes.email) {
        let email = this.contentEditorService.configRulesetLink.object;
        let localizedEmailContent = email.localizedEmailContents.firstObject;
        analyticsEvent.custom_domain = localizedEmailContent.isSenderUsingCustomDomain;
        analyticsEvent.dkim_authenticated = localizedEmailContent.isDomainDkimAuthenticated;
        analyticsEvent.return_path_authenticated =
          localizedEmailContent.isDomainReturnPathAuthenticated;
      }
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);

      if (this.rulesetTrigger) {
        this.setSpacingPeriod(defaultRulesetSpacingPeriod);
      }
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'turned_off',
        object: 'multi_match',
      });
      this._resetRulesetMatchBehaviorAndSpacingPeriod();
    }
  }

  _resetRulesetMatchBehaviorAndSpacingPeriod() {
    if (!this.contentEditorService.configRulesetLink.isBanner) {
      this.ruleset.matchBehavior = matchBehaviors.single;
    }
    this.setSpacingPeriod(-1);
    this.setMaxMatchCount(-1);
  }

  _resetCustomBotShowUntil() {
    if (this.contentEditorService.configRulesetLink?.isOutboundCustomBot) {
      this.contentEditorService.activeObject.showUntil = objectNotificationPreferences.seen;
      this.ruleset.matchBehavior = matchBehaviors.single;
    }
  }
}
