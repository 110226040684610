/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import { rejectBy, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

const EXTRA_USER_ATTRIBUTES = ['Current page URL'];

export default Mixin.create({
  attributeService: service(),
  targetTypeIsUsers: equal('targetUserType', 'users'),
  attributeGroupList: ternaryToProperty(
    'targetTypeIsUsers',
    'attributeGroupListForUsers',
    'attributeGroupListForAnonymous',
  ),

  userAttributes: rejectBy(
    'attributeService.filterableUserAttributesMaybeWithSegmentsAndTags',
    'isUntrackedCda',
    true,
  ),
  companyAttributes: readOnly(
    'attributeService.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags',
  ),
  messengerConditionalDisplayAttributes: readOnly(
    'attributeService.messengerConditionalDisplayAttributes',
  ),

  attributeGroupListForAnonymous: computed('messengerConditionalDisplayAttributes.[]', function () {
    return [
      {
        heading: 'Person Data',
        attributes: this.messengerConditionalDisplayAttributes,
      },
    ];
  }),

  attributeGroupListForUsers: computed(
    'userAttributes.[]',
    'companyAttributes.[]',
    'messengerConditionalDisplayAttributes.[]',
    function () {
      return [
        {
          heading: 'User Data',
          attributes: [
            ...this.messengerConditionalDisplayAttributes.filter((el) => {
              return EXTRA_USER_ATTRIBUTES.includes(el.get('name'));
            }),
            ...this.userAttributes,
          ],
        },
        {
          heading: 'Company Data',
          attributes: this.companyAttributes,
        },
      ];
    },
  ),
});
