/* import __COLOCATED_TEMPLATE__ from './conversational-preview.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import compareVersions from 'embercom/lib/compare-versions';
import EmberObject from '@ember/object';

const ticketsSpaceSDKVersion = '15.2.0';
const newMessengerIosSDKVersion = '17.2.0';
const newMessengerAndroidSDKVersion = '15.10.0';

export default class ConversationalPreview extends Component {
  @service messengerSettingsTargetUserProvider;
  @service MessengerSettingsService;
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get botIdentity() {
    let identity = this.args.identity.getProperties(
      'name',
      'isOperator',
      'avatarData',
      'avatarType',
    );
    identity = EmberObject.create(identity);
    identity.set('avatarData', {
      url: this.args.identity.avatar.image_urls.square_128,
      avatarShape: this.args.identity.avatarData.avatarShape,
    });
    return identity;
  }

  get mobilePreviewConfiguration() {
    return this.args.model.mobilePreviewConfiguration;
  }

  get showAndroidPreview() {
    return (
      (this.messengerSettingsTargetUserProvider.isUserType ||
        this.messengerSettingsTargetUserProvider.isMobile) &&
      this.MessengerSettingsService.preview.selectedPlatform === 'android'
    );
  }

  get showIosPreview() {
    return (
      (this.messengerSettingsTargetUserProvider.isUserType ||
        this.messengerSettingsTargetUserProvider.isMobile) &&
      this.MessengerSettingsService.preview.selectedPlatform === 'ios'
    );
  }

  get showWebInstallationBanner() {
    return !this.app.hasAnyInstalledAtDate;
  }

  get showMobileInstallationBanner() {
    return (
      (this.showIosPreview && !this.app.iosSdkApiKeyIsActivated) ||
      (this.showAndroidPreview && !this.app.androidSdkApiKeyIsActivated)
    );
  }

  get showMobileUpdateBanner() {
    if (this.app.isOptedIntoConversationalMessengerBeta) {
      return false;
    }
    return (
      (this.showIosPreview &&
        (!this.app.ios_sdk_version ||
          compareVersions(this.app.ios_sdk_version, ticketsSpaceSDKVersion))) ||
      (this.showAndroidPreview &&
        (!this.app.android_sdk_version ||
          compareVersions(this.app.android_sdk_version, ticketsSpaceSDKVersion)))
    );
  }

  get showOpenBetaUpgradeBanner() {
    return (
      this.app.isOptedIntoConversationalMessengerBeta &&
      (this.showIosPreviewUpgradeBanner || this.showAndroidPreviewUpgradeBanner)
    );
  }

  get showIosPreviewUpgradeBanner() {
    return (
      this.showIosPreview &&
      (!this.app.ios_sdk_version ||
        compareVersions(this.app.ios_sdk_version, newMessengerIosSDKVersion))
    );
  }

  get showAndroidPreviewUpgradeBanner() {
    return (
      this.showAndroidPreview &&
      (!this.app.android_sdk_version ||
        compareVersions(this.app.android_sdk_version, newMessengerAndroidSDKVersion))
    );
  }

  get showOpenBetaUpgradeBannerDescription() {
    if (this.showIosPreview) {
      return this.intl.t('messenger.conversational.messenger-preview.upgrade-sdk-ios');
    }
    return this.intl.t('messenger.conversational.messenger-preview.upgrade-sdk-android');
  }

  get mobileSDKVersionsUrl() {
    if (this.showAndroidPreview) {
      return 'https://github.com/intercom/intercom-android/releases';
    }
    return 'https://github.com/intercom/intercom-ios/releases';
  }

  get lookAndFeel() {
    return this.args.model.lookAndFeel;
  }

  get activeBrandName() {
    return this.lookAndFeel?.activeBrand?.brandName;
  }

  get defaultBrandName() {
    return this.lookAndFeel?.defaultBrand?.brandName;
  }
}
