/* import __COLOCATED_TEMPLATE__ from './assign-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AssignConversation extends Component {
  @service appService;

  get assignee() {
    let assigneeId = this.args.action.actionData.assignee_id;
    if (assigneeId) {
      return this._getAssigneeById(`${assigneeId}`);
    }
  }

  _getAssigneeById(assigneeId) {
    let assignee = this.appService.app.teams.findBy('id', assigneeId);

    if (assignee === undefined) {
      assignee = this.appService.app.admins.findBy('id', assigneeId);
    }

    return assignee;
  }
}
