/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { equal, or, not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  currentDevice: 'android',
  isWeb: equal('currentDevice', 'web'),
  isDesktop: equal('currentDevice', 'desktop'),
  isMobile: equal('currentDevice', 'mobile'),
  isWebOrMobile: or('isWeb', 'isMobile'),
  isIos: equal('currentDevice', 'ios'),
  isAndroid: equal('currentDevice', 'android'),
  isIosOrAndroid: or('isIos', 'isAndroid'),
  isNotIosOrAndroid: not('isIosOrAndroid'),
  isMobileOrIosOrAndroid: or('isIosOrAndroid', 'isMobile'),
});
