/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import PredicateGroup from 'predicates/models/predicate-group';
import { tracked } from '@glimmer/tracking';
import {
  hasKnownEmailPredicate,
  notHardBouncedPredicate,
  notMarkedAsSpamPredicate,
} from 'embercom/models/emails/email';
import { hasPhoneNumberPredicate, hardUnsubscribedFromSms } from 'embercom/models/sms/sms';
import { action } from '@ember/object';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class Summary extends Component {
  @service attributeService;
  @service store;

  @tracked implicitPredicateGroup;

  constructor() {
    super(...arguments);
    this.initImplicitPredicateGroup();
  }

  @action
  initImplicitPredicateGroup() {
    let predicatesToHide = [
      hasKnownEmailPredicate,
      notMarkedAsSpamPredicate,
      notHardBouncedPredicate,
      hasPhoneNumberPredicate,
      hardUnsubscribedFromSms,
    ];
    let predicates = this.args.ruleset.implicitPredicates.filter(
      (predicate) =>
        !predicatesToHide.any((predicateToHide) => _.isEqual(predicateToHide, predicate)),
    );
    if (
      this.args.ruleset.rulesetLinks.firstObject.objectType === objectTypes.email &&
      !JSON.stringify(this.args.ruleset.implicitPredicates).includes('unsubscribed_from_emails')
    ) {
      predicates.unshift({
        attribute: 'unsubscribed_from_emails',
        type: 'boolean',
        comparison: 'known',
        value: '',
      });
    }
    this.implicitPredicateGroup = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, predicates),
    });
  }

  get attributeGroupList() {
    return [
      {
        heading: 'Person Data',
        attributes: this.attributeService.filterableUserAttributesForMessaging,
      },
    ];
  }
}
