/* import __COLOCATED_TEMPLATE__ from './add-apps.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export const USER_TARGET_TYPE = 'users';

export default class AddAppsComponent extends Component {
  @service appService;
  @service messengerSettingsTargetUserProvider;
  @service MessengerSettingsService;
  @service helpCenterService;

  preferredPositionNameOrder = ['right', 'top', 'bottom', 'left'];

  get slotGroup() {
    return this.args.settings.slotGroup;
  }

  get slotsForDisplay() {
    let slots = this.slotGroup?.slots;

    if (!slots) {
      return [];
    }

    if (this.args.isMobilePlatform) {
      slots = slots.filter((slot) => !slot.isNewsSlot && !slot.isTasksSlot);
    } else if (this.compactEnabled) {
      slots = slots.filter(
        (slot) =>
          slot.isExternalLinksSlot || slot.isNewConversationCardSlot || slot.isTicketLinksSlot,
      );
    }

    return slots;
  }
  constructor(owner, args) {
    super(owner, args);
  }

  get compactEnabled() {
    return this.args.selectedLayout === 'compact' && !this.args.isMobilePlatform;
  }

  get canUseChecklists() {
    return this.appService.app.canUseFeature('checklists');
  }

  get isHelpCenterEnabled() {
    return this.helpCenterService.site.turnedOn;
  }

  get showUserTypeToggle() {
    if (this.messengerSettingsTargetUserProvider.isMobile) {
      return false;
    } else {
      let usersHub = this.args.spaces.spacesForUsers.find((space) => space.isHub);
      let visitorsHub = this.args.spaces.spacesForVisitors.find((space) => space.isHub);
      return usersHub.enabled && visitorsHub.enabled;
    }
  }

  get canRemoveSlot() {
    return this.compactEnabled || this.slotsForDisplay.length > 1;
  }

  @action
  addConversationSlot() {
    let slot = this.slotGroup.addNewConversationCard();
    this.trackAddSlot(slot);
  }

  @action
  addArticleSearchSlot() {
    if (!this.slotGroup.hasSlot('help_center')) {
      this.args.settings.searchBrowseEnabled = true;
      let slot = this.slotGroup.addHelpCenterSlot();
      this.trackAddSlot(slot);
    }
  }

  @action
  addMessengerAppSlot(canvasJSON, messengerApp) {
    let canvas = canvasJSON;
    let slot = this.slotGroup.addSlot({ slotType: 'messenger_app', canvas, messengerApp });
    this.trackAddSlot(slot);
  }

  @action
  addNewsSlot() {
    let slot = this.slotGroup.addNewsSlot();

    this.trackAddSlot(slot);
  }

  @action
  addTasksSlot() {
    let slot = this.slotGroup.addTasksSlot();

    this.trackAddSlot(slot);
  }

  @action
  addExternalLinksSlot() {
    let slot = this.slotGroup.addExternalLinksSlot();

    this.trackAddSlot(slot);
  }

  @action
  addTicketLinksSlot() {
    let slot = this.slotGroup.addTicketLinksSlot();

    this.trackAddSlot(slot);
  }

  @action
  removeSlotFromGroup(slot) {
    if (slot.slotType === 'help_center') {
      this.args.settings.searchBrowseEnabled = false;
    }
    this.slotGroup.removeSlot(slot);
    this.trackRemoveSlot(slot);
  }

  trackAddSlot(slot) {
    this.trackSlotChange(slot);
  }

  trackRemoveSlot(slot) {
    this.trackSlotChange(slot, true);
  }

  trackSlotChange(slot, removed) {
    if (slot) {
      this.MessengerSettingsService.trackAnalyticsEvent({
        action: removed ? 'removed' : 'added',
        object: 'app',
        app_name: slot.slotType,
        messenger_app_id: slot.messengerAppId,
      });
    }
  }

  @action
  resetSlotDisplayOrder(slotGroup, reorderedSlots) {
    this.slotGroup.resetSlotDisplayOrder(slotGroup, reorderedSlots);
  }
}
