/* import __COLOCATED_TEMPLATE__ from './conversational-opt-in.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import ajax from 'embercom/lib/ajax';

type ConversationalOptInArgs = {
  model: $TSFixMe;
  newSettingsDesign: boolean;
};

export default class ConversationalOptIn extends Component<ConversationalOptInArgs> {
  @service notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  @tracked showOptInConfirmModal = false;
  @tracked showOptOutConfirmModal = false;
  @tracked optOutFeedback = '';

  get model() {
    return this.args.model;
  }

  get app() {
    return this.appService.app;
  }

  get optedIn() {
    return this.app.isOptedIntoConversationalMessengerBeta;
  }

  get showStartConversationWarning() {
    return (
      !this.model.visibility.inboundConversationsEnabledForVisitors ||
      !this.model.visibility.inboundConversationsEnabledForUsers
    );
  }

  get showIgnoreSearchWarning() {
    return (
      this.model.home.requireSearchBrowseForVisitors || this.model.home.requireSearchBrowseForUsers
    );
  }

  get learnMoreArticle() {
    return 9319961;
  }

  @dropTask
  *optInMessenger() {
    try {
      yield ajax({
        url: `/ember/messenger_settings/all/${this.appService.app.id}/conversational_opt_in`,
        type: 'POST',
        data: JSON.stringify({
          opt_in: true,
        }),
      });
      this.showOptInConfirmModal = false;
      this.app.isOptedIntoConversationalMessengerBeta = true;
      this.appService.refresh();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.conversational.opt-in-success-notification'),
      );
      if (this.args.newSettingsDesign) {
        this.router.replaceWith('apps.app.settings.channels.messenger.conversational.web', {
          queryParams: { tab: 'web', section: 'brand' },
        });
      } else {
        this.router.transitionTo('apps.app.new-messenger.opt-in');
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('messenger.conversational.default-error'),
      });
    }
  }

  @dropTask
  *optOutMessenger() {
    try {
      if (this.optOutFeedback.trim() === '') {
        this.notificationsService.notifyError(
          this.intl.t('messenger.conversational.opt-out.confirm-modal.feedback-required'),
        );
        return;
      }
      yield ajax({
        url: `/ember/messenger_settings/all/${this.appService.app.id}/conversational_opt_in`,
        type: 'POST',
        data: JSON.stringify({
          opt_in: false,
          feedback: this.optOutFeedback,
        }),
      });
      this.app.isOptedIntoConversationalMessengerBeta = false;
      this.appService.refresh();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.conversational.opt-out.success-notification'),
      );
      if (this.args.newSettingsDesign) {
        this.router.replaceWith('apps.app.settings.channels.messenger.web', {
          queryParams: { tab: 'content', section: 'layout-and-spaces' },
        });
      } else {
        this.router.transitionTo('apps.app.messenger.web', {
          queryParams: { section: 'layout-and-spaces', tab: 'content' },
        });
      }
    } catch (error) {
      this.model.form.refresh();
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('messenger.conversational.default-error'),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::ConversationalOptIn': typeof ConversationalOptIn;
    'messenger-settings/conversational-opt-in': typeof ConversationalOptIn;
  }
}
