/* import __COLOCATED_TEMPLATE__ from './audience-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import MatchingSystemAudienceRulesEditor from 'embercom/components/matching-system/audience-selector/audience-rules-editor';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class AudienceRulesEditor extends MatchingSystemAudienceRulesEditor {
  @service seriesEditorService;

  get node() {
    return this.seriesEditorService.activeSeriesNode;
  }

  get activeObject() {
    return this.args.ruleset.rulesetLinks.firstObject.object;
  }

  get hasPreconditions() {
    return !isEmpty(this.preconditionBlocks);
  }

  get attributeGroupList() {
    let groups = super.attributeGroupList;

    return groups.filter((group) => group.heading !== 'Articles');
  }

  get preconditionBlocks() {
    let blocks = [];
    if (this.seriesEditorService.series?.hasExitRules) {
      blocks = blocks.concat(this.exitRulesBlock);
    }
    if (this.node.isInternal) {
      blocks = blocks.concat(this.predecessorBlocks);
    }
    return blocks;
  }

  get exitRulesBlock() {
    return {
      description: 'Did not match Series exit rules',
      type: 'and',
    };
  }

  get predecessorBlocks() {
    let graphNode = this.seriesEditorService.graph.nodeForDataObject(this.node);

    return graphNode.inwardEdges.map((edge) => {
      let description;
      if (edge.dataObject.isPrimary) {
        description = edge.predecessor.dataObject.graphConfiguration.primaryPredecessorLabel;
      } else {
        description = edge.predecessor.dataObject.graphConfiguration.alternativePredecessorLabel;
      }
      return {
        description,
        type: 'or',
      };
    });
  }
}
