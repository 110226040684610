/* import __COLOCATED_TEMPLATE__ from './text-area.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import CodeTextArea from 'embercom/components/common/code-text-area';
import { action } from '@ember/object';
import AttributeInputInserter from 'embercom/components/mixins/attribute-input-inserter';

const PLACEHOLDER = '_REPLACE_THIS_';

export default CodeTextArea.extend(AttributeInputInserter, {
  showAttributeSelector: true,

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.previousContentObject && this.previousContentObject !== this.contentObject) {
      this._setCodeMirrorValue(this.value);
    }
    this.set('previousContentObject', this.contentObject);
  },

  _setCodeMirrorValue(value) {
    this.codeMirrorEditor.setValue(value);
  },

  _getSplicedAttribute(attribute) {
    let cursorPosition = this.codeMirrorEditor.getCursor();
    let splitValue = this.value.split('\n');
    let lineValue = splitValue[cursorPosition.line];
    let renderedAttribute = this.createAttributeTemplate(attribute, PLACEHOLDER);
    splitValue[cursorPosition.line] =
      lineValue.substr(0, cursorPosition.ch) +
      renderedAttribute +
      lineValue.substr(cursorPosition.ch, lineValue.length - 1);
    return splitValue.join('\n');
  },

  addAttribute: action(function (attribute) {
    this._setCodeMirrorValue(this._getSplicedAttribute(attribute));
  }),
});
