/* import __COLOCATED_TEMPLATE__ from './audience-rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { USER_ROLE_PREDICATE } from 'predicates/models/data/roles';
import PredicateGroup from 'predicates/models/predicate-group';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import ENV from 'embercom/config/environment';
import { debounce, throttle } from '@ember/runloop';

const ROLE_VALUE = {
  user: 'user_role',
  lead: 'contact_role',
  visitor: 'visitor_role',
};

export default class AudienceRulesEditor extends Component {
  @service appService;
  @service attributeService;
  @service realTimeEventService;
  @service store;
  @service intl;

  @tracked predicateGroup = this.args.ruleset.predicateGroup;
  @tracked rolePredicateGroup = this.args.ruleset.rolePredicateGroup;

  get app() {
    return this.appService.app;
  }

  allUserRoles = ['user', 'lead', 'visitor'];

  get previewTaskIsRunning() {
    return this.args.ruleset.fetchAudiencePreviewTask.isRunning;
  }

  get userSelectedValue() {
    return (
      this.args.ruleset.predicateGroup.get('userPredicate.userType') ||
      this.predicateGroup.userPredicate.userType
    );
  }

  get tagPredicates() {
    return this.predicateGroup.predicates.filter((predicate) => predicate?.type === 'manual_tag');
  }

  get attributePickerLabel() {
    return this.args.attributePickerLabel
      ? this.args.attributePickerLabel
      : this.intl.t('matching-system.audience-selector.audience-rule-editor.add-audience-rule');
  }

  constructor() {
    super(...arguments);

    this._initPredicates();
    this.realTimeEventService.subscribeTopics([
      'csv-import-user-tag-progress',
      'tagging-status-updated',
    ]);
    this.realTimeEventService.on(
      'CsvImportUserTagProgress',
      this,
      'handleCSVImportUserTagProgressEvent',
    );
    this.realTimeEventService.on('TaggingStatusUpdated', this, 'handleTaggingStatusUpdatedEvent');
  }

  @action
  reinitPredicates() {
    this.predicateGroup = copy(this.args.ruleset.predicateGroup);
    this.rolePredicateGroup = copy(this.args.ruleset.rolePredicateGroup);
    this._initPredicates();
  }

  _initPredicates() {
    if (this.args.useRolePredicateGroup) {
      this._initRolePredicateGroup();
    } else {
      this._initPredicateGroup();
    }
  }

  _initRolePredicateGroup() {
    if (isEmpty(this.rolePredicateGroup?.predicates)) {
      // If you change the default role predicate below, you must also change in the Ruleset model in the backend.
      this.rolePredicateGroup = this._createRolePredicateGroup(['user']);
    }
  }

  _initPredicateGroup() {
    let predicates = this.predicateGroup.predicates.serialize();
    if (isEmpty(predicates)) {
      predicates.pushObject(USER_ROLE_PREDICATE);
      this.predicateGroup = this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(this.store, predicates),
      });
    }
  }

  _createRolePredicateGroup(userRoles) {
    if (this.args.allowedUserRoles) {
      userRoles = userRoles.filter((userRole) => this.args.allowedUserRoles.includes(userRole));
    }
    let roleValues = userRoles.map((role) => {
      if (!(role in ROLE_VALUE)) {
        throw new Error(`Unknown role ${role}`);
      }
      return ROLE_VALUE[role];
    });
    let predicates = [
      {
        attribute: 'role',
        type: 'role',
        comparison: 'in',
        value: roleValues,
      },
    ];
    return this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, predicates),
    });
  }

  get _validAttributeList() {
    let validAttributes;
    if (this.targetingVisitorsOnly) {
      validAttributes = this.attributeService.visitorMessagingAttributesNoPageTargetting;
    } else {
      validAttributes = this.app.companiesActive
        ? this.attributeService.filterableUserAttributesForMessaging.concat(
            this.attributeService.filterableCompanyAttributesForMessaging,
          )
        : this.attributeService.filterableUserAttributesForMessaging;
    }
    return validAttributes.map((x) => x.identifier).concat('role');
  }

  get attributeGroupList() {
    let attributes = this.additionalAttributes;

    if (this.targetingVisitorsOnly) {
      attributes = attributes.concat(
        this.attributeService.visitorMessagingAttributesNoPageTargetting,
      );
    } else {
      attributes = attributes.concat(this.attributeService.filterableUserAttributesForMessaging);

      if (this.app.companiesActive) {
        attributes = attributes.concat(
          this.attributeService.filterableCompanyAttributesForMessaging,
        );
      }

      if (!this.targetingUserOnly) {
        attributes = attributes.concat(
          this.attributeService.additionalAttributesForLeadsAndVisitors,
        );
      }
    }

    let groups = this.attributeService.attributesToGroupList([...new Set(attributes)]);

    if (!this.targetingVisitorsOnly && this.args.showSeriesContentEvents) {
      groups = [...groups, ...this.attributeService.attributeGroupListForContentEvents];
    }

    if (this.includeTicketAttributeGroups) {
      groups = [...groups, ...this.attributeService.ticketAttributesByType];
    }

    if (this.includeConversationAttributes) {
      groups = [...groups, ...this.attributeService.audienceConversationAttributes];
    }

    return groups;
  }

  get supportedPredicateAttributes() {
    return this.attributeGroupList
      .map((group) => group.attributes)
      .flat()
      .map((attribute) => attribute.identifier)
      .flatMap((attribute) =>
        attribute.startsWith('user_event_summaries.')
          ? [`${attribute}.count`, `${attribute}.first`, `${attribute}.last`]
          : attribute,
      );
  }

  get audienceSearchPlaceholder() {
    if (this.includeConversationAttributes) {
      return this.intl.t('matching-system.audience-selector.audience-rule-editor.search');
    }

    return this.intl.t('matching-system.audience-selector.audience-rule-editor.search-verbose');
  }

  get additionalAttributes() {
    return this.args.additionalAttributes || [];
  }

  get includeTicketAttributeGroups() {
    return this.args.includeTicketAttributeGroups || false;
  }

  get includeConversationAttributes() {
    return this.args.includeConversationAttributes || false;
  }

  get useSupportedPredicateAttributes() {
    return this.args.useSupportedPredicateAttributes || false;
  }

  get targetingVisitorsOnly() {
    if (this.predicateGroup.userType) {
      return this.predicateGroup.userType === 'visitor';
    }
    let userTypes = this.rolePredicateGroup.userPredicate.userTypes;
    return userTypes.length === 1 && userTypes[0] === 'visitor';
  }

  get targetingUserOnly() {
    if (this.predicateGroup.userType) {
      return this.predicateGroup.userType === 'user';
    }
    let userTypes = this.rolePredicateGroup.userPredicate.userTypes;
    return userTypes.length === 1 && userTypes[0] === 'user';
  }

  get selectedUserTypes() {
    return (
      this.args.ruleset.rolePredicateGroup.get('userPredicate.userTypes') ||
      this.rolePredicateGroup.userPredicate?.userTypes
    );
  }

  get groupAttributePickerDisabledIdentifiers() {
    let identifierToReasonMapping = {};

    if (this.args.contentEventsDisabled) {
      this.attributeService.attributeGroupListForContentEvents
        .filter((group) => group.heading !== 'Series')
        .forEach((group) => {
          group.attributes.forEach((attribute) => {
            let reason = `Currently you can only add ${group.heading} data to non-starting rules`;
            identifierToReasonMapping[attribute.id] = reason;
          });
        });
    }

    return identifierToReasonMapping;
  }

  _isValid(p) {
    return this._validAttributeList.includes(p.attributeIdentifier) || p.isLogicalType;
  }

  _removeInvalidPredicates() {
    this.predicateGroup.removeAll((p) => !this._isValid(p));
  }

  @action
  setDefaultRolePredicateIfNecessary() {
    if (this.args.isDisabled) {
      return;
    }
    if (this.args.useRolePredicateGroup) {
      if (isEmpty(this.args.ruleset.rolePredicateGroup.predicates)) {
        this.args.onUpdateRolePredicates(this.rolePredicateGroup.predicates);
      }
    } else if (isEmpty(this.args.ruleset.predicateGroup.predicates)) {
      this.args.onUpdatePredicates(this.predicateGroup.predicates);
    }
  }

  @action
  switchUserTypeOnPredicateGroup(userType) {
    this.predicateGroup.switchUserType(userType);
    this.predicateGroup = copy(this.predicateGroup);
    this._removeInvalidPredicates();
    this.args.onUpdatePredicates(this.predicateGroup.predicates);
  }

  @action
  switchUserRoleOnRolePredicateGroup(userRoles) {
    let rolePredicateGroup = this._createRolePredicateGroup(userRoles);
    this.rolePredicateGroup = rolePredicateGroup;
    this._removeInvalidPredicates();
    this.args.onUpdateRolePredicates(rolePredicateGroup.predicates);
  }

  activeTagPredicate(tagId) {
    return this.tagPredicates.find((predicate) => predicate.value === tagId);
  }

  handleCSVImportUserTagProgressEvent(event) {
    let tagId = String(event.manual_tag_id);

    if (!this.activeTagPredicate(tagId)) {
      return;
    }

    if (event.progress > 20 && !event.complete) {
      throttle(this, this.refreshAudiencePreviewForCSVImportStatus, ENV.APP._500MS);
    }

    if (event.complete) {
      debounce(this, this.refreshAudiencePreviewForCSVImportStatus, ENV.APP._50MS);
    }
  }

  handleTaggingStatusUpdatedEvent(event) {
    let tagId = String(event.id);
    if (!this.activeTagPredicate(tagId)) {
      return;
    }

    let eventData = { tagId };
    throttle(this, this.refreshAudiencePreviewForTagStatus, eventData, ENV.APP._750MS);
  }

  refreshAudiencePreviewForCSVImportStatus() {
    if (!this.previewTaskIsRunning) {
      this.args.ruleset.fetchAudiencePreviewTask.perform();
    }
  }

  async refreshAudiencePreviewForTagStatus(event) {
    let tag = this.app.tags.findBy('id', event.tagId);
    await tag.reload();

    if (!this.previewTaskIsRunning) {
      this.args.ruleset.fetchAudiencePreviewTask.perform();
    }
  }
}
