/* import __COLOCATED_TEMPLATE__ from './identity-verification-section.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class IdentityVerificationSection extends Component {
  @service appService;

  @tracked showUserHashCheckerModal = false;

  get app() {
    return this.appService.app;
  }

  get pingDomains() {
    return this.args.pingDomains;
  }

  get isWeb() {
    return this.args.platform === 'web';
  }

  get isMobile() {
    return this.args.platform === 'ios' || this.args.platform === 'android';
  }

  get showErrorsPart() {
    if (this.isWeb) {
      return (
        this.pingDomains.length === 0 || this.pingDomains.any((pingDomain) => pingDomain.hasError)
      );
    }
    return true;
  }

  @action openUserHashChecker() {
    this.showUserHashCheckerModal = true;
  }

  @action closeUserHashChecker() {
    this.showUserHashCheckerModal = false;
  }
}
