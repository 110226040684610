/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-new-mixins */
import { A } from '@ember/array';
import Mixin from '@ember/object/mixin';
import ajax from 'embercom/lib/ajax';

let AvatarAndMapSearch = Mixin.create({
  avatarAndMapSearch(app, predicates, page) {
    let self = this;
    if (this.xhr && this.xhr.readyState !== 4) {
      this.xhr.abort();
    }
    return ajax({
      beforeSend(xhr) {
        self.xhr = xhr;
      },
      url: '/ember/users/map_search.json',
      type: 'POST',
      data: JSON.stringify({
        app_id: app.get('id'),
        predicates,
        page: page || 1,
        per_page: 250,
      }),
    }).then(function (response) {
      let array = A();
      if (response.users) {
        response.users.forEach(function (user) {
          array.push(user);
        });
      }
      return {
        users: array,
        nextPage: response.next_page,
        totalCount: response.total_count,
      };
    });
  },
});
export default AvatarAndMapSearch;
