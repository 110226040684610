/* import __COLOCATED_TEMPLATE__ from './locale-textarea-with-count.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { gte } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import defaultTo from '@intercom/pulse/lib/default-to';
import { action } from '@ember/object';

const CHAR_LIMIT = 160;

export default Component.extend({
  tagName: '',
  showLocale: true,
  charLimit: defaultTo(CHAR_LIMIT),
  charactersUsed: computed('locale.userWelcomeMessage.length', function () {
    return this.get('locale.userWelcomeMessage.length') || 0;
  }),
  charactersLeft: computed('charactersUsed', 'charLimit', function () {
    return this.charLimit - this.charactersUsed;
  }),
  hasCharactersLeft: gte('charactersLeft', 0),
  setUserWelcomeMessage: action(function (event) {
    this.set('locale.userWelcomeMessage', event.currentTarget.value.trim());
  }),
});
