/* import __COLOCATED_TEMPLATE__ from './preview-panel.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type MessengerInstallation from 'embercom/services/messenger-installation';

export default class PreviewPanel extends Component {
  @service declare appService: $TSFixMe;
  @service declare messengerInstallation: MessengerInstallation;

  get hasActiveMessenger() {
    return (
      this.messengerInstallation.isInstalledForWeb &&
      !this.appService.app.is_web_messenger_disabled &&
      this.appService.app.canUseMessenger
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Conversational::PreviewPanel': typeof PreviewPanel;
    'messenger-settings/conversational/preview-panel': typeof PreviewPanel;
  }
}
