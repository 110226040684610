/* import __COLOCATED_TEMPLATE__ from './match-check.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */

import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { get } from 'embercom/lib/ajax';
import {
  MatchCheck,
  type MatchCheckWireFormat,
} from 'embercom/objects/matching-system/match-check';
import ENV from 'embercom/config/environment';

interface Args {
  ruleset: any;
  userId?: string;
  onSelectUser?: (id: string | undefined) => void;
}

export default class MatchCheckComponent extends Component<Args> {
  @service declare regionService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (args.userId) {
      this.selectedUserId = args.userId;
      taskFor(this.loadMatchData).perform();
    }
  }

  @service declare appService: any;

  @tracked selectedUserId: any = undefined;

  @tracked matchCheck?: MatchCheck;

  @action openFeedback() {
    if (ENV.environment === 'production') {
      window.Intercom('startSurvey', 32178212);
    } else {
      alert('Intercom feedback is only available in production');
    }
  }

  @action unsetUser() {
    this.matchCheck = undefined;
    this.selectedUserId = undefined;
    if (this.args.onSelectUser) {
      this.args.onSelectUser(undefined);
    }
  }

  @action onSelectUser(user: any) {
    this.selectedUserId = user.id;

    if (this.args.onSelectUser) {
      this.args.onSelectUser(user.id);
    }

    taskFor(this.loadMatchData).perform();
  }

  @task *loadMatchData(): TaskGenerator<void> {
    let response = yield get(
      `/ember/matching_system/rulesets/${this.args.ruleset.id}/match_check`,
      {
        user_id: this.selectedUserId,
        app_id: this.appService.app.id,
      },
    ) as MatchCheckWireFormat;

    this.matchCheck = new MatchCheck(response, getOwner(this));

    console.info(response, this.matchCheck);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MatchingSystem::MatchCheck': typeof MatchCheckComponent;
  }
}
