/* import __COLOCATED_TEMPLATE__ from './languages.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class GeneralLanguagesSettingsComponent extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get languageSettings() {
    return this.args.languageSettings;
  }

  get permittedLocales() {
    return this.languageSettings.supportedLocales.filter((locale) =>
      this.localeIsPermitted(locale),
    );
  }

  get notPermittedLocales() {
    return this.languageSettings.supportedLocales.filter(
      (locale) => !this.localeIsPermitted(locale),
    );
  }

  get permittedLocalesWithoutDefault() {
    return this.permittedLocales.filter(
      (locale) => locale.localeId !== this.languageSettings.defaultLocale,
    );
  }

  get defaultLocaleObject() {
    return this.languageSettings.supportedLocales.find(
      (locale) => locale.localeId === this.languageSettings.defaultLocale,
    );
  }

  get showPaywallForLanguages() {
    return !this.app.hasMultipleLanguages;
  }

  get localeDropdownItems() {
    return this.languageSettings.supportedLocales.map((locale) => ({
      value: locale.localeId,
      text: locale.name,
    }));
  }

  get addLocaleDropdownItems() {
    return this.notPermittedLocales.map((locale) => ({
      value: locale.localeId,
      text: locale.name,
    }));
  }

  localeIsPermitted(locale) {
    let defaultLocale = this.languageSettings.defaultLocale;
    return locale.isPermitted || locale.localeId === defaultLocale;
  }

  @action
  permitLanguage(localeId) {
    let locale = this.languageSettings.supportedLocales.findBy('localeId', localeId);
    locale.isPermitted = true;
  }

  @action
  setDefaultLanguage(localeId) {
    let permitted = this.permittedLocales;

    if (permitted.findBy('localeId', localeId)) {
      let oldDefaultLocale = this.defaultLocaleObject;
      oldDefaultLocale.isPermitted = true;
    }

    this.languageSettings.defaultLocale = localeId;
    this.defaultLocaleObject.isPermitted = false;
  }

  @action
  removeLanguage(localeId) {
    let localeToRemove = this.languageSettings.supportedLocales.findBy('localeId', localeId);
    localeToRemove.isPermitted = false;
  }
}
