/* import __COLOCATED_TEMPLATE__ from './logo-upload-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { ref } from 'ember-ref-bucket';

export default class LogoUploadComponent extends Component {
  @service notificationsService;
  @service intercomEventService;
  @service intl;
  @tracked isDragging = false;
  fileExtension = 'png,gif,jpeg,jpg';
  file;
  @ref('inputElement') inputElement;

  get activeBrand() {
    return this.args.lookAndFeelSettings.activeBrand;
  }
  get logoUrlProperty() {
    if (this.args.launcherVariant) {
      return 'launcherLogoUrl';
    }
    return 'logoUrl';
  }
  get logoIdProperty() {
    if (this.args.launcherVariant) {
      return 'launcherLogoId';
    }
    return 'logoId';
  }
  get brandLogoUrlProperty() {
    if (this.args.launcherVariant) {
      return 'launcherLogoUrl';
    }
    return 'messengerLogoUrl';
  }
  get brandLogoIdProperty() {
    if (this.args.launcherVariant) {
      return 'launcherLogoId';
    }
    return 'messengerLogoId';
  }
  get queryParams() {
    if (this.args.launcherVariant) {
      return '?restricted_dimensions=true';
    }
    return '';
  }
  get hasLogo() {
    return !!this.logoUrl;
  }
  get logoUrl() {
    return this.activeBrand && this.activeBrand[this.brandLogoUrlProperty];
  }
  get logoId() {
    return this.activeBrand && this.activeBrand[this.brandLogoIdProperty];
  }

  @dropTask *uploadLogo(fileData) {
    yield ajax({
      url: `/ember/messenger_settings/upload_logo/${this.args.app.id}${this.queryParams}`,
      type: 'POST',
      data: this.filePayload(fileData),
    })
      .then((response) => {
        this.handleUploadSuccess(response);
        this.trackImageUpload(response.url);
      })
      .catch((error) => {
        this.handleUploadError(error);
      });
  }

  filePayload(fileData) {
    return JSON.stringify({
      file_data: fileData.split(',')[1],
      filename: this.file.name,
      content_type: this.file.type,
    });
  }

  handleUploadError(error) {
    this.clearFileInput();
    this.notificationsService.notifyResponseError(error, {
      default: this.intl.t('messenger.look-and-feel-settings.customize.launcher.logo.upload-error'),
    });
  }

  handleUploadSuccess(response) {
    this.activeBrand[this.brandLogoUrlProperty] = response.url;
    this.activeBrand[this.brandLogoIdProperty] = response.id;

    if (this.activeBrand.isDefault) {
      this.args.lookAndFeelSettings[this.logoUrlProperty] = response.url;
      this.args.lookAndFeelSettings[this.logoIdProperty] = response.id;
    }
  }

  trackImageUpload(imageUrl) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'uploaded',
      object: 'logo',
      image_url: imageUrl,
    });
  }

  pickFile() {
    if (typeof this.args.pickFile === 'function') {
      // this is here for testing 🤮
      return this.args.pickFile.call(this);
    }
    this.clearFileInput();
    this.inputElement.click();
  }

  setFile(file) {
    this.file = file;
    let reader = new FileReader();
    reader.onload = (e) => {
      this.uploadLogo.perform(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  clearFileInput() {
    this.inputElement.value = '';
  }

  @action
  dragOver(e) {
    e.preventDefault();
    this.isDragging = true;
  }

  @action
  dragLeave(e) {
    e.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(e) {
    e.preventDefault();
    this.isDragging = false;
    this.setFile(e.dataTransfer.files[0]);
  }

  @action
  change(e) {
    if (e.target.files.length) {
      this.setFile(e.target.files[0]);
    }
  }

  @action
  removeLogo() {
    this.clearFileInput();
    this.file = null;
    this.activeBrand[this.brandLogoIdProperty] = null;
    this.activeBrand[this.brandLogoUrlProperty] = null;

    if (this.activeBrand.isDefault) {
      this.args.lookAndFeelSettings[this.logoUrlProperty] = null;
      this.args.lookAndFeelSettings[this.logoIdProperty] = null;
    }
  }

  @action
  showFilePicker() {
    if (this.uploadLogo.isRunning || this.args.disabled) {
      return;
    }
    this.pickFile();
  }
}
