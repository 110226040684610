/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { A } from '@ember/array';
import Mixin from '@ember/object/mixin';
import VALIDATIONS from 'embercom/models/data/messages/message-validation-map';

export default Mixin.create({
  getValidationResults(panelKey) {
    let success = true;
    let errors = A([]);

    VALIDATIONS[panelKey].forEach((validation) => {
      if (!this._validate(validation)) {
        success = false;
        errors.pushObject(this._errorMessage(validation));
      }
    });

    return {
      success,
      errors: errors.uniq(),
    };
  },

  _validate(validation) {
    if (validation.type === 'custom') {
      return this.get(`message.${validation.validationKey}`);
    }

    return this.get(`message.${validation.validationKey}.isValid`);
  },

  _errorMessage(validation) {
    if (validation.type === 'custom') {
      return validation.message;
    }

    return this.get(`message.${validation.validationKey}.message`);
  },
});
