/* import __COLOCATED_TEMPLATE__ from './user-type-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import {
  USER_TARGET_TYPE,
  LEADS_AND_VISITORS_TARGET_TYPE,
  MOBILE_TARGET_TYPE,
} from 'embercom/lib/messenger-settings/target-types';

const MOBILE_PLATFORMS = ['ios', 'android'];

export default class UserTypeToggle extends Component {
  @service intl;
  @service appService;
  @service messengerSettingsTargetUserProvider;
  @service MessengerSettingsService;

  get app() {
    return this.appService.app;
  }

  get selectedValue() {
    return this.messengerSettingsTargetUserProvider.targetUserType;
  }

  get targetUserTypeOptions() {
    return [
      {
        value: LEADS_AND_VISITORS_TARGET_TYPE,
        label: this.intl.t('messenger.target-types.visitors-label'),
      },
      {
        value: this.userTargetTypeValue,
        label: this.intl.t('messenger.target-types.users-label'),
      },
    ];
  }

  get selectedPlatform() {
    return this.MessengerSettingsService.preview.selectedPlatform;
  }

  get userTargetTypeValue() {
    if (MOBILE_PLATFORMS.includes(this.selectedPlatform)) {
      return MOBILE_TARGET_TYPE;
    }
    return USER_TARGET_TYPE;
  }

  @action
  setTargetUserType(newTargetUserType) {
    this.messengerSettingsTargetUserProvider.targetUserType = newTargetUserType;
    this.MessengerSettingsService.resetPreviewScreen({ fullReset: true });
    this.trackUserTypeToggle(newTargetUserType);
  }

  trackUserTypeToggle(enabled) {
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'user_type',
      toggled_to: enabled,
    });
  }
}
