/* import __COLOCATED_TEMPLATE__ from './slots.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { and, empty, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { isValidEmail } from '@intercom/pulse/lib/computed-properties';
import { post } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import keycodes from '@intercom/pulse/lib/keynames';

export default Component.extend({
  classNames: ['flex-auto', 'flex', 'flex-col', 'meeting-scheduler__content'],

  didInsertElement() {
    this._super(...arguments);
    this.set('guestEmail', this.get('calendar.emailFromConversation'));
  },

  store: service(),
  guestEmail: null,
  selectedSlot: null,
  error: null,
  invalidEmail: false,
  calendarPage: 1,
  media: service(),
  guestEmailIsValid: isValidEmail('guestEmail'),
  showConfirmation: and('guestEmail', 'selectedSlot.booked'),
  timezone: alias('calendar.timezone'),

  timezoneDisplay: computed('endUserTimezone.{timezone,offset}', function () {
    let { timezone, offset } = this.endUserTimezone;
    let cityName = timezone.split('/').lastObject.replace('_', ' ');
    return `(GMT${offset}) ${cityName} Time`;
  }),

  bookableSlots: computed('calendar.slots', function () {
    return this.get('calendar.slots').rejectBy('notBookable', true);
  }),

  hasNoBookableSlots: empty('bookableSlots'),

  bookableSlotsByDate: computed('bookableSlots', function () {
    return this.bookableSlots.reduce((map, value) => {
      if (map[value.get('date')]) {
        map[value.get('date')].push(value);
      } else {
        map[value.get('date')] = [value];
      }
      return map;
    }, {});
  }),

  bookSlotTask: task(function* () {
    let guestEmail = this.guestEmail;
    let selectedSlot = this.selectedSlot;

    if (guestEmail && this.guestEmailIsValid) {
      try {
        selectedSlot.set('booked', true);
        yield post('/meeting_scheduler/calendar/book_slot', {
          link: this.get('calendar.id'),
          guest_email: guestEmail,
          user_id: this.get('calendar.userIdFromConversation'),
          start_time: selectedSlot.get('start').toString(),
          duration: selectedSlot.get('duration'),
          slot_id: selectedSlot.get('id'),
        });
      } catch (error) {
        let store = this.store;
        let slots = error.jqXHR.responseJSON.slots.map((slotData) => {
          return store.createRecord('meeting-scheduler/slot', slotData);
        });
        this.set('calendar.slots', slots);
        this.setProperties({
          selectedSlot: null,
          error: error.jqXHR.responseJSON.errors[0].message,
        });
      }
    } else {
      this.set('invalidEmail', true);
    }
  }),

  bookSlot: action(function () {
    this.bookSlotTask.perform();
  }),

  emailKeydown: action(function (event) {
    this.set('invalidEmail', false);
    if (event.keyCode === keycodes.enter) {
      this.bookSlot();
    }
  }),

  back: action(function () {
    this.set('selectedSlot', null);
  }),

  selectSlot: action(function (slot) {
    this.set('error', null);
    if (slot.get('notBookable')) {
      return;
    }
    this.set('selectedSlot', slot);
    if (this.guestEmail) {
      this.bookSlotTask.perform();
    }
  }),
});
