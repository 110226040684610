/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class Header extends Component {
  get isBackgroundImageAGif() {
    return /(\.gif$|image\/gif)/.test(this.args.configuration.backgroundImageUrl);
  }

  get headerBackgroundImageStyle() {
    return `
      background-image: url(${this.args.configuration.backgroundImageUrl});
      background-position: ${this.isBackgroundImageAGif ? 'center' : 'top left'};
      background-repeat: no-repeat;
      background-size: ${this.isBackgroundImageAGif ? 'cover' : '400px 329px'};
      height: ${this.args.configuration.fadeToWhite ? '328' : '300'}px;
    `;
  }

  get headerStyle() {
    if (this.args.configuration.showBackgroundImage) {
      return sanitizeHtml(this.headerBackgroundImageStyle);
    } else {
      return '';
    }
  }
}
