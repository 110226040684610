/* import __COLOCATED_TEMPLATE__ from './javascript-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseInstallMethodComponent from 'embercom/components/messenger-installation/install-methods/base-install-method-component';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default BaseInstallMethodComponent.extend({
  intercomEventService: service(),
  codeOptions: [
    { text: 'Node.js', value: 'node' },
    { text: 'Rails (Ruby)', value: 'rails' },
    { text: 'Django (Python 3)', value: 'django' },
    { text: 'PHP', value: 'php' },
  ],

  serverSideLanguage: reads('codeOptions.firstObject.value'),
  hmacServerSideLanguage: reads('codeOptions.firstObject.value'),
  setServerSideLanguage: action(function (value) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'select',
      object: 'server_side_language',
      place: 'messenger_installation_settings',
      context: value,
    });
    this.set('serverSideLanguage', value);
    this.set('hmacServerSideLanguage', value);
  }),

  identificationHMACGenerator: 'user_id',

  changeHMACGenerator: action(function (identifier) {
    this.set('identificationHMACGenerator', identifier);
  }),
});
