/* import __COLOCATED_TEMPLATE__ from './identity-verification-banner.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';

export default class IdentityVerificationBanner extends Component {
  @service intl;
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomConfirmService;
  @service intercomEventService;
  @service identityVerificationRiskStateService;

  get app() {
    return this.appService.app;
  }

  get platform() {
    return this.args.platform?.toLowerCase();
  }

  get isWeb() {
    return this.platform === 'web';
  }

  get sdkApp() {
    return this.args.sdkApp;
  }

  get appModel() {
    switch (this.platform) {
      case 'ios':
        return this.sdkApp;
      case 'android':
        return this.sdkApp;
      case 'web':
        return this.app;
    }
  }

  get identityVerified() {
    return this.appModel?.get('identityVerified');
  }

  get domainsHaveErrors() {
    return this.args.pingDomains.isEvery('errorCode', null);
  }

  @action
  onSwitchClick(e) {
    e.preventDefault();
    this.confirmAndToggleIdentityVerification.perform();
  }

  _identityVerificationWebEnableConfirmationOptions() {
    return {
      title: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.domain-with-error',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.verification-errors-description',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.ignore-and-enforce',
      ),
      cancelButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.go-back-review',
      ),
      secondaryButtonType: 'primary-destructive',
      invertButtonTypes: true,
    };
  }

  _identityVerificationDisableConfirmationOptions() {
    return {
      title: this.intl.t(
        'components.settings.identity-verification.identity-verification-installation-setup-component.disable-idv-modal-title',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.identity-verification-installation-setup-component.disable-idv-modal-content-text',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.identity-verification-installation-setup-component.disable-idv-modal-button-text',
      ),
      cancelButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.go-back-review',
      ),
      secondaryButtonType: 'primary-destructive',
      invertButtonTypes: true,
    };
  }

  _identityVerificationMobileConfirmationOptions() {
    return {
      title: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.before-enforce',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.after-enforce',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.enforce-and-disable',
      ),
      cancelButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.go-back',
      ),
      secondaryButtonType: 'primary-destructive',
      invertButtonTypes: true,
    };
  }

  @task({ drop: true })
  *confirmAndToggleIdentityVerification() {
    let confirmed = yield this.confirmIdentityVerificationTask.perform();

    this.toggleIdentityVerificationTask.perform(this.identityVerified, confirmed);
  }

  @task({ drop: true })
  *confirmIdentityVerificationTask() {
    if (this.identityVerified) {
      return yield this.intercomConfirmService.confirm(
        this._identityVerificationDisableConfirmationOptions(),
      );
    } else if (!this.isWeb) {
      return yield this.intercomConfirmService.confirm(
        this._identityVerificationMobileConfirmationOptions(),
      );
    } else if (this.domainsHaveErrors) {
      return yield this.intercomConfirmService.confirm(
        this._identityVerificationWebEnableConfirmationOptions(),
      );
    } else {
      return true;
    }
  }

  @task({ drop: true })
  *toggleIdentityVerificationTask(originalState, confirmed) {
    if (confirmed) {
      let newState = this.appModel.toggleProperty('identityVerified');

      this._trackIdentityVerificationToggleEvent(newState);

      try {
        yield this._updateIdentityVerification(newState);
        yield this._refreshIdentityVerificationRiskState(newState);
      } catch (e) {
        this.appModel?.set('identityVerified', originalState);
        this.notificationsService.notifyError(
          this.intl.t(
            'components.settings.identity-verification.verify-user-identity-component.problem-updating',
          ),
        );
      }
    }
  }

  _updateIdentityVerification(enabled) {
    if (this.isWeb) {
      this._updateWebIdentityVerification(enabled);
    } else {
      this._updateMobileIdentityVerification(enabled);
    }
  }

  _updateWebIdentityVerification(enabled) {
    return ajax({
      url: `/ember/apps/${this.app.id}/update_identity_verification`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        identity_verified: enabled,
      }),
    });
  }

  _updateMobileIdentityVerification(enabled) {
    this.sdkApp.set('identityVerified', enabled);
    this.sdkApp.save();
  }

  _trackIdentityVerificationToggleEvent(enabled) {
    let stateString = enabled ? 'enabled' : 'disabled';
    this.intercomEventService.trackEvent(`secure-mode-${stateString}`, {
      action: stateString,
      object: 'secure_mode',
      place: `${this.userSource}_settings`,
      owner: 'growth',
    });
  }

  _refreshIdentityVerificationRiskState() {
    return this.identityVerificationRiskStateService.start(this.app);
  }
}
