/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { observer, computed } from '@ember/object';
import { cancel, schedule, debounce } from '@ember/runloop';
import { assert } from '@ember/debug';
import { on } from '@ember/object/evented';
import { reads, gt } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { lessThanProperty, subtractFrom } from '@intercom/pulse/lib/computed-properties';

let SelectKeyboardActions = Mixin.create({
  isOpen: false,
  highlightedItemIndex: 0,
  viewportSelector: '.pl_admin_selector_list',
  listSelector: '.pl_admin_selector_list',
  listItemSelector: '.dropdown__list-item.o__hoverable',
  selectedListItemClass: '.js_keyboard_selected',
  highlightListItemClass: 'o__selected',
  filteredItems: computed('selectableItems', 'selectableItems.@each._filtered', function () {
    if (!this.selectableItems) {
      return [];
    }

    return this.selectableItems.filter((item) => {
      if (item.get === undefined || item.get('_filtered') === undefined) {
        return true;
      }
      return !item.get('_filtered');
    });
  }),
  filteredItemsLength: reads('filteredItems.length'),
  highlightedItemNotFirst: gt('highlightedItemIndex', 0),
  lastItemIndex: subtractFrom('filteredItemsLength', 1),
  highlightedItemNotLast: lessThanProperty('highlightedItemIndex', 'lastItemIndex'),

  initSelectKeyboardActions: on('init', function () {
    assert(
      'Must define selectableItems on component using this mixin.',
      this.selectableItems !== 'undefined',
    );
  }),

  willSelectKeyboardActions: on('willDestroyElement', function () {
    cancel(this.highlightDebounceTimer);
  }),

  _highlightItem(direction) {
    if (this.isDestroyed || direction === undefined) {
      return;
    }
    let newIndex = this.highlightedItemIndex + direction;
    this.set('highlightedItemIndex', newIndex);
    this._setHighlightedItemClass(newIndex);
    schedule('afterRender', this, function () {
      this.scrollToItem();
    });
  },

  openStateChanged: observer({
    dependentKeys: ['isOpen'],

    fn() {
      this.set('highlightedItemIndex', 0);
      if (this.isOpen) {
        schedule('afterRender', this, function () {
          this._setHoverListeners();
          this._setHighlightedItemClass(0);
        });
      } else {
        this._removeHoverListeners();
      }
    },

    sync: true,
  }),
  filteredItemsLengthChanged: observer({
    dependentKeys: ['filteredItemsLength'],

    fn() {
      schedule('afterRender', this, function () {
        this.set('highlightedItemIndex', 0);
        this._setHighlightedItemClass(0);
      });
    },

    sync: true,
  }),

  _setHoverListeners() {
    let self = this;
    let items = $(this.listItemSelector, this.element);
    $(this.element).on('mouseenter', this.listItemSelector, function () {
      items.removeClass(self.get('highlightListItemClass'));
      let index = $(this).index();
      self.set('highlightedItemIndex', index);
    });
    $(this.element).on('mouseleave', this.listItemSelector, function () {
      self._setHighlightedItemClass($(this).index());
    });
  },
  _removeHoverListeners() {
    $(this.element).off('mouseenter mouseleave', this.listItemSelector);
  },

  _setHighlightedItemClass(index) {
    let items = $(this.listItemSelector, this.element);
    items.removeClass(this.highlightListItemClass);
    $(items.get(index), this.element).addClass(this.highlightListItemClass);
  },

  scrollToItem() {
    let $viewport = $(this.viewportSelector, this.element);
    let $items = $(this.listItemSelector, this.element);
    let activeElement = $(`.${this.highlightListItemClass}`, this.element).position();
    if (!(activeElement && $items && $viewport)) {
      return;
    }
    let currentScrollTop = $viewport.scrollTop();
    if (activeElement.top > $viewport.height() - 50) {
      $viewport.scrollTop(currentScrollTop + activeElement.top - $viewport.height() + 50);
    } else if (currentScrollTop > 0 && activeElement.top < 50) {
      let scrollPosition = 0;
      if (this.highlightedItemNotFirst) {
        scrollPosition = currentScrollTop + activeElement.top - 50;
      }
      $viewport.scrollTop(scrollPosition);
    }
  },

  keyDown(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    switch (keyCode) {
      case 40:
        this.send('onDown');
        break;
      case 38:
        this.send('onUp');
        break;
      case 13:
        this.send('onEnter');
        break;
      case 27:
        this.send('onEsc');
        break;
      case 9:
        this.send('onTab', e);
        break;
      default:
        this._super(e);
    }
  },

  actions: {
    onDown() {
      if (this.isOpen) {
        if (this.highlightedItemNotLast) {
          this.send('highlightItem', 1);
        }
      } else {
        this.set('isOpen', true);
      }
    },
    onUp() {
      if (this.isOpen && this.highlightedItemNotFirst) {
        this.send('highlightItem', -1);
      }
    },
    onEnter() {
      if (this.isOpen) {
        let currentHighlightedItem = this.filteredItems.objectAt(this.highlightedItemIndex);
        if (currentHighlightedItem !== undefined) {
          this.send('selectItem', currentHighlightedItem, $('.js_keyboard_selected', this.element));
          this.set('isOpen', false);
        }
      }
    },
    onEsc() {
      if (this.isOpen) {
        this.set('isOpen', false);
      }
    },
    onTab(e) {
      if (this.isOpen) {
        e.preventDefault();
      }
    },
    highlightItem(direction) {
      if (!this.isDestroying) {
        let highlightDebounceTimer = debounce(this, this._highlightItem, direction, 10);
        this.set('highlightDebounceTimer', highlightDebounceTimer);
      }
    },
    selectItem() {
      throw new Error(
        'Assertion Failed: Must define selectItem action on component using this mixin.',
      );
    },
  },
});

export default SelectKeyboardActions;
