/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { setDiff, filterBy } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  validTaggings: computed('taggings.[]', function () {
    if (!this.get('taggings.length')) {
      return [];
    }
    return this.taggings.filter(function (tagging) {
      return tagging.get('tag.name');
    });
  }),

  visibleTaggings: computed('validTaggings.[]', 'admin.visible_tag_ids.[]', function () {
    let visibleTagIds = this.get('admin.visible_tag_ids');
    if (!visibleTagIds) {
      return [];
    }
    return this.validTaggings.filter(function (tagging) {
      return visibleTagIds.includes(tagging.get('tag.id').toString());
    });
  }),

  hiddenTaggings: computed('validTaggings.[]', 'admin.visible_tag_ids.[]', function () {
    let visibleTagIds = this.get('admin.visible_tag_ids');
    if (!visibleTagIds) {
      return this.validTaggings;
    }
    return this.validTaggings.reject(function (tagging) {
      return visibleTagIds.includes(tagging.get('tag.id').toString());
    });
  }),

  hidableSegments: filterBy('segments', 'is_editable', true),

  visibleSegments: computed('hidableSegments', 'admin.visible_segment_ids.[]', function () {
    let visibleSegmentIds = this.get('admin.visible_segment_ids');
    if (!visibleSegmentIds) {
      return [];
    }
    return this.hidableSegments.filter((segment) => visibleSegmentIds.includes(segment.get('id')));
  }),

  hiddenSegments: setDiff('hidableSegments', 'visibleSegments'),

  addTagIdsToAdminsVisibleTagIds(tagIds) {
    this.admin.addTagIdsToAdminsVisibleTagIds(tagIds);
  },

  removeTagIdsFromAdminsVisibleTagIds(tagIds) {
    this.admin.removeTagIdsFromAdminsVisibleTagIds(tagIds);
  },

  addSegmentIdsToAdminsVisibleSegmentIds(segmentIds) {
    this.admin.addSegmentIdsToAdminsVisibleSegmentIds(segmentIds);
  },

  removeSegmentIdsFromAdminsVisibleSegmentIds(segmentIds) {
    this.admin.removeSegmentIdsFromAdminsVisibleSegmentIds(segmentIds);
  },
});
