/* import __COLOCATED_TEMPLATE__ from './spaces-tab-bar.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

export default class SpacesTabBar extends Component {
  get spacesItems() {
    return this.args.spaces?.spacesForPreview?.map((space) => {
      let isActive = space.type === this.args.activeSpaceType;
      return {
        iconPath: space.previewIconPath(isActive),
        title: space.messengerTitleKey,
        isActive,
      };
    });
  }

  get showTitles() {
    return this.args.spaces?.spacesForPreview?.length < 6;
  }
}
