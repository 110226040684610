/* import __COLOCATED_TEMPLATE__ from './predicate-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),

  tagName: 'span',
  classNames: ['message__editor__audience__predicate'],
  attributeBindings: ['data-test-predicate-summary'],
  'data-test-predicate-summary': true,

  message: notImplemented(),
  predicate: notImplemented(),

  predicateDescription: computed(
    'predicate',
    'message',
    'predicate.attribute',
    'predicate.comparison',
    'predicate.type',
    'predicate.value',
    function () {
      // predicateDescription needs to observe everything that predicate.description observes. We can't observe predicate.description itself as it's not used in this component so the observer doesn't work correctly -LC
      return this.predicate.getDescription({});
    },
  ),
});
