/* import __COLOCATED_TEMPLATE__ from './install-verification-component-with-tech-bundle.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';

export default class InstallVerificationComponentWithTechBundle extends Component {
  @tracked errorMessage = null;
  @tracked verificationSuccessful = false;

  @action
  performInstallVerification() {
    this.verifyInstallation.perform();
  }

  @dropTask
  *verifyInstallation() {
    let result = yield this.verifyInstall();

    if (result.verified) {
      this.verificationSuccessful = true;
    } else {
      this.errorMessage =
        'We havenʼt received any data from your installation. Please check the instructions and try again.';
    }
  }

  @dropTask
  *verifyInstall() {
    return yield ajax({
      url: `/ember/tech_setup_invite_validations/${this.args.authToken}`,
      type: 'GET',
      contentType: 'application/json',
      data: {
        email: this.args.authEmail,
        valid_until: this.args.authValidUntil,
        method: 'check_messenger_connection',
        source: this.args.sourceKey,
      },
    });
  }
}
