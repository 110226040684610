/* import __COLOCATED_TEMPLATE__ from './article-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

export default class ArticleSuggestions extends Component {
  @service messengerSettingsTargetUserProvider;
  @service appService;
  @service store;
  @tracked articleSuggestionsPreview;
  @tracked userSuggestions = [];
  @tracked visitorSuggestions = [];

  @task
  *loadSuggestionsPreview() {
    let preview = yield this.store.findRecord(
      'messenger-settings/article-suggestion-preview',
      this.appService.app.id,
      { reload: false, backgroundReload: false },
    );
    this.userSuggestions = preview.userSuggestions.map(({ title }) => title);
    this.visitorSuggestions = preview.visitorSuggestions.map(({ title }) => title);
  }

  get articleSuggestions() {
    return this.messengerSettingsTargetUserProvider.isUserType
      ? this.userSuggestions
      : this.visitorSuggestions;
  }

  getSettings() {
    let appId = this.appService.app.id;
    return this.store.peekRecord('messenger-settings/home', appId);
  }

  get searchRequired() {
    let settings = this.getSettings();
    return this.messengerSettingsTargetUserProvider.isUserType
      ? settings.requireSearchBrowseForUsers
      : settings.requireSearchBrowseForVisitors;
  }
}
