/* import __COLOCATED_TEMPLATE__ from './messenger-app-slot.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MessengerAppSlotComponent extends Component {
  @service store;

  get messengerApp() {
    return this.store.peekRecord('messenger-app', this.args.slot.messengerAppId);
  }
}
