/* import __COLOCATED_TEMPLATE__ from './toggle-with-predicates.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  isOn: boolean;
  onClick: () => void;
  predicatesGroup?: any;
  label: string;
  property: string;
  targetingPaywall: string;
  togglePaywall: string;
  disabled?: boolean;
  disabledToggleTooltipText?: boolean;
  tooltipText?: string;
  usePredicateGroupEditor?: boolean;
}

export default class ToggleWithPredicates extends Component<Args> {
  usePredicateGroupEditor: boolean;
  @service declare messengerSettingsTargetUserProvider: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.usePredicateGroupEditor = this.args.usePredicateGroupEditor || false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Common::ToggleWithPredicates': typeof ToggleWithPredicates;
  }
}
