/* import __COLOCATED_TEMPLATE__ from './messenger.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';

export default class Messenger extends Component {
  @service appService;

  colorOptions = DEFAULT_MESSENGER_COLORS;

  get showTextColor() {
    return this.args.activeBrand.showBackgroundImage || !!this.args.activeBrand.hasGradient;
  }

  get compactEnabled() {
    return this.args.selectedLayout === 'compact' && !this.args.isMobilePlatform;
  }
}
