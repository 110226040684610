/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import MatchingSystemCollapsiblePreview from 'embercom/components/matching-system/audience-selector/collapsible-preview';
import { targetUserGroup } from 'embercom/models/data/carousel/target-user-group';
import { inject as service } from '@ember/service';
export default class AudienceRulesEditor extends MatchingSystemCollapsiblePreview {
  @service contentEditorService;

  get activeObject() {
    return this.contentEditorService.activeObject;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  get emptyStateMessageForUsers() {
    if (this.activeObject.targetUserGroup === targetUserGroup.new.value) {
      return `New users who match the above rules will receive this ${this.activeRulesetLink.humanReadableObjectName}.`;
    } else {
      return super.emptyStateMessageForUsers;
    }
  }

  get isEmptyState() {
    if (this.activeObject.targetUserGroup === targetUserGroup.new.value) {
      return true;
    } else {
      return super.isEmptyState;
    }
  }
}
