/* import __COLOCATED_TEMPLATE__ from './gradient.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  tinycolor,
  getAccessibleForegroundColorForBackground,
} from '@intercom/intercom-messenger-colors';
import { tracked } from '@glimmer/tracking';
import {
  BACKGROUND_IMAGE_WIDTH,
  BACKGROUND_IMAGE_HEIGHT,
} from 'embercom/lib/messenger-settings/conversational-preview';
import { modifier } from 'ember-modifier';

interface Configuration {
  conversationalBackgroundType: 'colors' | 'solid';
  backgroundSolidColor: string;
  gradientFirstColor: string;
  gradientSecondColor: string;
  gradientThirdColor: string;
  showConversationBackground: boolean;
  locale: string;
  showWeRunOnIntercom: boolean;
}

const DEFAULT_FOREGROUND_COLOR = '#737373';

const buildGradientSvg = (
  left: string,
  right: string,
  middle?: string,
) => `<svg width="${BACKGROUND_IMAGE_WIDTH}" preserveAspectRatio="none"  height="${BACKGROUND_IMAGE_HEIGHT}" viewBox="0 0 ${BACKGROUND_IMAGE_WIDTH} ${BACKGROUND_IMAGE_HEIGHT}" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0" y="100" width="${BACKGROUND_IMAGE_WIDTH}" height="${BACKGROUND_IMAGE_HEIGHT}" fill="url(#paint0_linear_6654_53386)"/>
<g filter="url(#filter0_f_6654_53386)">
<ellipse cx="200" cy="137" rx="375" ry="200" fill="white"/>
</g>
<defs>
<filter id="filter0_f_6654_53386" x="-274" y="-71.5186" width="950" height="${BACKGROUND_IMAGE_HEIGHT}" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_6654_53386"/>
</filter>
<linearGradient id="paint0_linear_6654_53386" x1="0" y1="267.481" x2="483.554" y2="737.826" gradientUnits="userSpaceOnUse">
<stop offset="0.2" stop-color="${left}" />
${middle && `<stop offset="0.6" stop-color="${middle}" />`}
<stop offset="1" stop-color="${right}" />
</linearGradient>
</defs>
</svg>`;

const rgbToHex = (r: number, g: number, b: number) => {
  return tinycolor(`rgb(${r}, ${g}, ${b}`).toHexString();
};

interface Signature {
  Args: {
    configuration: Configuration;
    showGradient: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class GradientBackgroundComponent extends Component<Signature> {
  @tracked gradientRect: DOMRect | undefined;
  @tracked pixelData?: ImageData | undefined;

  get firstColor() {
    return this.args.configuration.conversationalBackgroundType === 'colors'
      ? this.args.configuration.gradientFirstColor
      : this.args.configuration.backgroundSolidColor;
  }

  get secondColor() {
    return this.args.configuration.conversationalBackgroundType === 'colors'
      ? this.args.configuration.gradientSecondColor
      : this.args.configuration.backgroundSolidColor;
  }

  get thirdColor() {
    return this.args.configuration.conversationalBackgroundType === 'colors'
      ? this.args.configuration.gradientThirdColor
      : this.args.configuration.backgroundSolidColor;
  }

  get svgSrc() {
    let svg = this.thirdColor
      ? buildGradientSvg(this.firstColor, this.thirdColor, this.secondColor)
      : buildGradientSvg(this.firstColor, this.secondColor);

    return `data:image/svg+xml;base64,${btoa(svg)}`;
  }

  setForegroundColor = modifier<HTMLElement, unknown[], { greyscale?: boolean }>(
    (element, _, { greyscale = false }) => {
      let foregroundColor = this.getForegroundColor(element, greyscale ? 100 : undefined);
      element.style.color = foregroundColor || DEFAULT_FOREGROUND_COLOR;
    },
    { eager: false },
  );

  getForegroundColor(element: HTMLElement, desaturateAmount?: number) {
    if (!this.args.showGradient) {
      return null;
    }

    if (!this.pixelData || !this.gradientRect) {
      return null;
    }

    let scaleX = BACKGROUND_IMAGE_WIDTH / this.gradientRect.width;
    let scaleY = BACKGROUND_IMAGE_HEIGHT / this.gradientRect.height;

    let elementRect = element.getBoundingClientRect();
    let centerX = Math.floor(
      (elementRect.left - this.gradientRect.left + elementRect.width / 2) * scaleX,
    );
    let centerY = Math.floor(
      (elementRect.top - this.gradientRect.top + elementRect.height / 2) * scaleY,
    );

    if (centerX < 0) {
      return null;
    }

    if (centerY < 0) {
      return null;
    }

    let backgroundColor = this.getBackgroundColor(this.pixelData, centerX, centerY);
    if (!backgroundColor) {
      return null;
    }

    let { foregroundColor } = getAccessibleForegroundColorForBackground(
      backgroundColor,
      desaturateAmount,
    );

    return foregroundColor;
  }

  getBackgroundColor(pixelData: ImageData, x: number, y: number) {
    if (!pixelData) {
      return null;
    }

    if (y < 0 || y >= pixelData.height) {
      return null;
    }

    let offset = (y * pixelData.width + x) * 4;
    let red = pixelData.data[offset];
    let green = pixelData.data[offset + 1];
    let blue = pixelData.data[offset + 2];

    if (offset < 0 || offset >= pixelData.data.length + 2) {
      return null;
    }

    return rgbToHex(red, green, blue);
  }

  @action handleImageLoad(event: Event) {
    if (event.target === null) {
      return;
    }

    let canvas = document.createElement('canvas');
    canvas.width = BACKGROUND_IMAGE_WIDTH;
    canvas.height = BACKGROUND_IMAGE_HEIGHT;

    let ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    let target = event.target as HTMLImageElement;
    ctx?.drawImage(target, 0, 0);
    this.pixelData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
    this.gradientRect = target.getBoundingClientRect();
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::Conversational::Gradient': typeof GradientBackgroundComponent;
    'messenger-preview/conversational/gradient': typeof GradientBackgroundComponent;
  }
}
