/* import __COLOCATED_TEMPLATE__ from './predicate-group-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    predicates: $TSFixMe[];
    ruleMode: string;
    mode?: 'goal' | undefined;
    hideIcons?: boolean;
    isInbox2?: boolean;
    predicateClassNames?: string;
  };
  Element: HTMLSpanElement;
}

const PredicateGroupSummary = templateOnlyComponent<Signature>();
export default PredicateGroupSummary;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'matching-system/audience-selector/summary/predicate-group-summary': typeof PredicateGroupSummary;
    'MatchingSystem::AudienceSelector::Summary::PredicateGroupSummary': typeof PredicateGroupSummary;
  }
}
