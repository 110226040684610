/* import __COLOCATED_TEMPLATE__ from './main-application-loading-screen.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { runTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';

export default class MainApplicationLoadingScreen extends Component {
  @service intl;
  @service session;
  @tracked showFollowUp = false;

  constructor() {
    super(...arguments);
    runTask(this, () => (this.showFollowUp = true), ENV.APP._5000MS);
  }

  get isFinStandaloneApp() {
    return window?.__embercom_boot_data?.is_fin_standalone_app ?? false;
  }

  get texts() {
    let baseTexts = [
      {
        initial: this.intl.t('components.main-application-loading-screen.getting-everything-ready'),
        followUp: this.intl.t('components.main-application-loading-screen.just-a-few-more-seconds'),
      },
      {
        initial: this.intl.t(
          'components.main-application-loading-screen.putting-everything-together',
        ),
        followUp: this.intl.t('components.main-application-loading-screen.ready-in-a-second'),
      },
      {
        initial: this.intl.t('components.main-application-loading-screen.right-with-you'),
        followUp: this.intl.t(
          'components.main-application-loading-screen.thanks-for-your-patience',
        ),
      },
    ];

    let embercomTexts = [
      {
        initial: this.intl.t('components.main-application-loading-screen.loading-your-info'),
        followUp: this.intl.t(
          'components.main-application-loading-screen.just-a-second-or-two-more',
        ),
      },
      {
        initial: this.intl.t('components.main-application-loading-screen.loading-your-data'),
        followUp: this.intl.t(
          'components.main-application-loading-screen.this-might-take-a-second',
        ),
      },
    ];
    let isEmbercom = !EmbercomBootDataModule.isBootedInPublicPage();
    return isEmbercom ? baseTexts.concat(embercomTexts) : baseTexts;
  }

  get chosenText() {
    let index = Date.now() % this.texts.length;
    return this.texts[index];
  }

  get initialText() {
    if (!this.intl.hasLoadedCurrentLocale) {
      return;
    }

    return `${this.chosenText.initial}…`;
  }

  get followUp() {
    if (!this.intl.hasLoadedCurrentLocale) {
      return;
    }

    if (this.chosenText.noSurroundingDots) {
      return this.chosenText.followUp;
    } else {
      return `…${this.chosenText.followUp}…`;
    }
  }

  get shouldShowFollowUp() {
    return this.showFollowUp;
  }

  get followUpClass() {
    if (!this.intl.hasLoadedCurrentLocale) {
      return;
    }

    return this.chosenText.followUpClass;
  }
}
