/* import __COLOCATED_TEMPLATE__ from './rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const TIME_ON_PAGE_PREDICATE = {
  identifier: 'client_attributes.time_on_page',
  name: 'Time on current page',
  type: 'duration_integer',
};

const URL_PREDICATE = {
  identifier: 'client_attributes.last_visited_url',
  name: 'Current page URL',
  type: 'string',
};

export default class RulesEditor extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  attributeGroupList = [
    {
      attributes: [URL_PREDICATE, TIME_ON_PAGE_PREDICATE],
    },
  ];

  get hasTimeOnPagePredicate() {
    return this._checkForTimeOnPagePredicate(this.args.predicateGroup.predicates);
  }

  get showPageTargetingDropdown() {
    return this.args.predicateGroup.isValid;
  }

  _checkForTimeOnPagePredicate(predicates) {
    return predicates.any(
      (predicate) => predicate?.attribute === TIME_ON_PAGE_PREDICATE.identifier,
    );
  }

  get disabledGroupPredicates() {
    let disabledGroupPredicates = [];
    let predicates = this.args.predicateGroup?.predicates;
    let nestedPredicates = predicates?.firstObject?.predicates;
    let predicateGroups = nestedPredicates || predicates || [];

    predicateGroups.forEach((predicateGroup, index) => {
      if (this._checkForTimeOnPagePredicate(predicateGroup?.predicates || [predicateGroup])) {
        disabledGroupPredicates[index] = [TIME_ON_PAGE_PREDICATE.identifier];
      } else {
        disabledGroupPredicates[index] = [];
      }
    });

    return disabledGroupPredicates;
  }

  @action
  proxiedOnUpdate(predicates) {
    this.args.predicateGroup.predicates = copy(predicates);
    if (this.args.onUpdate) {
      this.args.onUpdate(...arguments);
    }
  }

  @action
  addPageTargetPredicate(predicatesEditor) {
    predicatesEditor.addPredicate(URL_PREDICATE);
  }

  @action
  addTimeOnPagePredicate(predicatesEditor) {
    if (this.hasTimeOnPagePredicate) {
      return;
    }
    predicatesEditor.addPredicate(TIME_ON_PAGE_PREDICATE);
  }
}
