/* import __COLOCATED_TEMPLATE__ from './identity-verification.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),

  init() {
    this._super(...arguments);
    this.fetchPingDomains.perform();
  },

  webIdentityVerified: readOnly('app.identityVerified'),

  iosSdkApp: readOnly('app.iosSdkApps.firstObject'),
  iosIdentityVerified: readOnly('iosSdkApp.identityVerified'),

  androidSdkApp: readOnly('app.androidSdkApps.firstObject'),
  androidIdentityVerified: readOnly('androidSdkApp.identityVerified'),

  pingDomains: [],
  fetchPingDomains: task(function* () {
    let pingDomains = yield this.store.query('ping-domain', { ignore_anonymous: true });
    this.set('pingDomains', pingDomains);
  }),
  noDomainsHaveErrors: computed('pingDomains.@each.errorCode', function () {
    return this.pingDomains.isEvery('errorCode', null);
  }),
});
