/* import __COLOCATED_TEMPLATE__ from './screens-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class MessengerSettingsScreensSwitcher extends Component {
  @service MessengerSettingsService;
  @service intl;

  get model() {
    return this.args.model;
  }

  get spaces() {
    return this.model.spaces.selectedSpaces;
  }

  get availableScreens() {
    let screensFromSpaces = this.spaces
      .filter((space) => space.enabled)
      .map((space) => {
        return {
          text: space.name,
          icon: space.icon,
          isDisabled: this.disabledOnMobile(space),
          value: space.type,
        };
      });

    return [
      ...screensFromSpaces,
      {
        text: this.intl.t('messenger.preview.screen-switcher.conversation'),
        icon: 'send',
        isDisabled: false,
        value: 'conversation',
      },
    ];
  }

  get previewScreensItems() {
    return this.availableScreens;
  }

  disabledOnMobile(space) {
    return this.args.selectedPlatform !== 'web' && ['news', 'tasks'].includes(space.type);
  }

  get firstActiveSpace() {
    return this.spaces.find((space) => space.enabled);
  }

  get selectedSpace() {
    let selectedSpace = this.spaces.find(
      (space) => space.enabled && space.type === this.MessengerSettingsService.selectedScreen,
    );
    return selectedSpace || this.firstActiveSpace;
  }

  get selectedScreen() {
    let selectedSpace = this.availableScreens.find(
      (screen) => screen.value === this.MessengerSettingsService.selectedScreen,
    );
    return selectedSpace || this.availableScreens[0];
  }

  @action
  onItemSelect(screen) {
    this.MessengerSettingsService.trackAnalyticsEvent({
      action: 'changed',
      object: 'preview_screen',
      value: screen,
    });
    this.MessengerSettingsService.onPreviewScreenChange(screen);
  }
}
