/* import __COLOCATED_TEMPLATE__ from './tag.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { captureException } from 'embercom/lib/sentry';

export const tagStates = {
  editing: 0,
  saved: 1,
  failure: 2,
};

export default class Tag extends Component {
  @service appService;
  @service intl;
  @service store;
  @service intercomEventService;
  @service modelDataCacheService;

  @tracked tagError;
  @tracked tag;
  @tracked tagState = tagStates.editing;

  constructor() {
    super(...arguments);
    let newTagStats = this.store.createRecord('tag-stats');
    this.tag = this.store.createRecord('tag', {
      stats: newTagStats,
    });
  }

  @task
  *getExistingTags() {
    let existingTags = yield this.app.loadAndUpdateTags();
    return existingTags.filterBy('hasDirtyAttributes', false);
  }

  @task
  *saveTag() {
    if (!this.tag.hasDirtyAttributes) {
      return this.onTagEditAbort();
    }
    if (yield this.tagExists()) {
      this.onTagError(this.intl.t('modal.create-tag-from-csv.tagger.errors.tag-exists-error'));
      return;
    }
    this.tag.set('createdBy', this.app.currentAdmin);
    try {
      yield this.tag.save();
      this.onTagSaved(this.tag);
    } catch (error) {
      captureException(error);
      this.onTagError(
        error.messsage || this.intl.t('modal.create-tag-from-csv.tagger.errors.unknown-error'),
      );
    }
  }

  @action
  onTagEdit() {
    this.stateChange(tagStates.editing);
  }

  @action
  onTagEditAbort() {
    if (this.tag.isNew) {
      return;
    }
    this.tag.rollbackAttributes();
    this.stateChange(tagStates.saved);
  }

  get app() {
    return this.appService.app;
  }

  get isEditing() {
    // On failure it needs to continue editing as well.
    return this.tagState !== tagStates.saved;
  }

  stateChange(newState) {
    this.tagState = newState;
    if (this.args.onTagStateChange) {
      this.args.onTagStateChange(newState);
    }
  }

  onTagSaved(tag) {
    this.intercomEventService.trackEvent('csv_tag_event', {
      action: 'save',
      place: 'csv_tag_modal',
      object: 'tag',
      tag_id: tag.id,
    });
    this.app.tags.addObject(this.tag);
    this.app.updateLocalCache(this.modelDataCacheService);
    this.tagError = null;
    this.stateChange(tagStates.saved);
    if (this.args.onTagSaved) {
      this.args.onTagSaved(tag);
    }
  }

  onTagError(errorMessage) {
    this.tagError = errorMessage;
    this.stateChange(tagStates.failure);
  }

  async tagExists() {
    let existingTags = await this.getExistingTags.perform();
    return existingTags.some((tag) => {
      return tag.name.localeCompare(this.tag.name, undefined, { sensitivity: 'accent' }) === 0;
    });
  }
}
