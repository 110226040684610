/* import __COLOCATED_TEMPLATE__ from './stats-tab-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { not, readOnly } from '@ember/object/computed';
import { containsByProperty } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Component.extend({
  attributeBindings: ['data-test-statistic-tab'],
  'data-test-statistic-tab': readOnly('tab.label'),
  classNames: ['message__editor__stats__tab', 'js__content-stats-tabs__tab-button'],
  classNameBindings: ['isActive:o__active', 'clickDisabled:o__disabled'],

  intercomEventService: service(),

  isActive: containsByProperty('tab.views', 'activeView'),
  clickDisabled: not('tab.isClickable'),

  click() {
    if (!this.isActive && this.get('tab.isClickable')) {
      let analyticsEvent = this.get('tab.analyticsEvent') || {
        action: 'open_stat_tab',
        object: this.get('tab.views.firstObject'),
        context: 'message-stats',
        links_count: this.get('variation.clickTrackingLinks.length'),
      };
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
      this.changeView(this.get('tab.views.firstObject'));
    }
  },
});
