/* import __COLOCATED_TEMPLATE__ from './bulk-message.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { matchBehaviors } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import buildCompanyPredicates from 'embercom/lib/users/user-company-list/company-predicate-builder';

export default class BulkMessage extends Component {
  @service intercomEventService;
  @service permissionsService;
  @service router;
  @service store;

  get templates() {
    let templates = this.store.peekAll('template');
    if (templates.length > 0) {
      return templates;
    }
    return this.store.findAll('template');
  }

  @action
  async createContent(objectType, _deliveryChannel, _audienceType, templateId) {
    await this.permissionsService.checkPermission('can_send_messages');

    let predicates = this.args.predicates || [];
    if (this.args.company) {
      predicates = buildCompanyPredicates([this.args.company]);
    } else if (this.args.selected) {
      predicates = buildCompanyPredicates(this.args.selected);
    }

    let rolePredicates = predicates
      .filter((predicate) => predicate.attribute === 'role')
      .map((predicate) => {
        if (predicate.comparison === 'eq') {
          predicate.comparison = 'in';
          predicate.value = [predicate.value];
        }
        return predicate;
      });

    let nonRolePredicates = predicates.filter((predicate) => predicate.attribute !== 'role');

    let matchBehavior = matchBehaviors.static;
    if (!this.args.company && !this.args.selected) {
      matchBehavior = matchBehaviors.single;
      this.intercomEventService.trackEvent('user-list-auto-message-created');
    }

    let rulesetParams = {
      object_type: objectType,
      predicate_group: { predicates: nonRolePredicates },
      role_predicate_group: { predicates: rolePredicates },
    };
    if (templateId) {
      rulesetParams.template_id = templateId;
    } else {
      rulesetParams.match_behavior = matchBehavior;
    }

    this.router.transitionTo('apps.app.content.new', rulesetParams);
  }
}
