/* import __COLOCATED_TEMPLATE__ from './cardless-conversion-modal.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import {
  CORE_STARTER_BASE_ID,
  CORE_SUPPORT_PRO_ID,
  PLAN_DATA,
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
  findLatestVBP2PlanData,
} from 'embercom/lib/billing';
import ConversionModalPlanGroups from 'embercom/lib/conversion-modal-plan-groups';
import RouteRegexes from 'embercom/lib/route-regexes';
import type CardlessConversionModalService from 'embercom/services/cardless-conversion-modal-service';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntlService from 'embercom/services/intl';

export default class CardlessConversionModal extends Component {
  @service declare cardlessConversionModalService: CardlessConversionModalService;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intl: IntlService;
  @service declare purchaseAnalyticsService: any;
  @service declare router: RouterService;

  customerLogosRow1 = [
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/amazon.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/meta.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/atlassian.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/amplitude.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/microsoft.png') },
  ];

  customerLogosRow2 = [
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/coda.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/etoro.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/hrblock.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/inter.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/monzo.png') },
    { path: assetUrl('/assets/images/modal/cardless-conversion-modal/new_relic.png') },
  ];

  get trialPlanName() {
    return this.intl.t(this.trialPlanData.nameTranslationKey);
  }

  get trialPlanData() {
    return findLatestVBP2PlanData(this.activeOrExpiredPlanTrialIds);
  }

  get activeOrExpiredPlanTrialIds() {
    return this.cardlessTrialService.activeOrExpiredPlanTrialIds;
  }

  get shouldShowConversionModal() {
    let showModal =
      !this.isOnExcludeRoutesForConversionModal &&
      this.cardlessTrialService.isInCardlessTrial &&
      (this.cardlessConversionModalService.shouldShowModalForAppWithExpiredTrial ||
        this.cardlessConversionModalService.showModal) &&
      !this.cardlessTrialService.isPricing5;
    if (showModal) {
      this.purchaseAnalyticsService.trackEvent({
        action: 'viewed',
        context: 'non_pricing_5',
        place: 'conversion',
        object: 'conversion_modal',
        pricing_5: this.cardlessTrialService.isPricing5,
        app_id: this.cardlessTrialService.appId,
      });
    }
    return showModal;
  }

  get showTrialExpiredView() {
    return this.cardlessTrialService.trialHasEnded;
  }

  get isOnExcludeRoutesForConversionModal() {
    return this.router.currentRouteName.match(RouteRegexes.excludedForCardlessConversionModal);
  }

  get starterPlanPricePerMonth() {
    return PLAN_DATA[CORE_STARTER_BASE_ID].fromPricePerMonth;
  }

  get starterPlanPricePerSeat() {
    return PLAN_DATA[CORE_STARTER_BASE_ID].pricePerSeat;
  }

  get starterPlanPricePerMonthFormatted() {
    return this.intl.formatNumber(this.starterPlanPricePerMonth, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get starterPlanPricePerSeatFormatted() {
    return this.intl.formatNumber(this.starterPlanPricePerSeat, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get supportProPlanPricePerMonth() {
    return PLAN_DATA[CORE_SUPPORT_PRO_ID].pricePerMonth;
  }

  get supportProPlanPricePerSeat() {
    return PLAN_DATA[CORE_SUPPORT_PRO_ID].pricePerSeat;
  }

  get supportProPlanPricePerMonthFormatted() {
    return this.intl.formatNumber(this.supportProPlanPricePerMonth, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get supportProPlanPricePerSeatFormatted() {
    return this.intl.formatNumber(this.supportProPlanPricePerSeat, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get supportFeatureGroups() {
    let conversionModalPlanGroups = new ConversionModalPlanGroups(getOwner(this));
    return conversionModalPlanGroups.planGroups;
  }

  get showFinCost() {
    return true;
  }

  @action
  upgradePlan(object = 'upgrade_now_button') {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'conversion_modal',
      object,
    });
  }

  @action
  downgradePlan() {
    this.cardlessConversionModalService.hide();
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'conversion_modal',
      object: 'downgrade_button',
    });
    let appId = this.cardlessTrialService.isTestApp
      ? this.cardlessTrialService.parentAppId!
      : this.cardlessTrialService.appId!;
    this.router.transitionTo('apps.app.teams-checkout.plan', appId, {
      queryParams: {
        solution_id: VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
        cardless_trial: true,
      },
    });
  }

  @action
  requestExtension() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'conversion_modal',
      object: 'request_extension_button',
    });
  }

  @action
  onModalClose() {
    this.cardlessConversionModalService.hide();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::CardlessConversionModal': typeof CardlessConversionModal;
    'modal/cardless-conversion-modal': typeof CardlessConversionModal;
  }
}
