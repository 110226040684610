/* import __COLOCATED_TEMPLATE__ from './set-conversation-data-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
export default class SetConversationDataAttribute extends Component {
  @service attributeService;

  get action() {
    return this.args.action;
  }

  get value() {
    return this.action.actionData.value;
  }

  get conversationDataAttribute() {
    return this.attributeService.conversationCustomAttributes.find(
      (attribute) => attribute.identifier === this.action.actionData.attribute_identifier,
    );
  }

  get formatName() {
    return `${this.conversationDataAttribute.name}:`;
  }

  get formatValue() {
    switch (this.conversationDataAttribute.type) {
      case 'conversation_attribute_list':
        return this.conversationDataAttribute.options.find(
          (option) => option.identifier === this.value,
        )?.value;
      case 'boolean':
        return this.value ? 'True' : 'False';
      default:
        return this.value;
    }
  }
}
